import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../../service/helper.service";
import {BsModalService} from "ngx-bootstrap";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse, HttpApiResponse} from "../../../../../model/DataTablesResponse";
import {environment} from "../../../../../../../environments/environment";
import * as $ from "jquery";
import {valuesys} from "../../../../../../../options";
import {Translatable} from "../../../../../../Translatable";
import {DataTableDirective} from "angular-datatables";
import {action, Auth} from "../../../../../model/db";
import {Subject} from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css']
})
export class ActionComponent extends Translatable implements OnInit {
  /*REFRESH*/
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  // @ts-ignore
  dtTrigger: Subject = new Subject();
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  /*REFRESH*/
  title:string ;
  actions:action [] =[];
  dtOptions: DataTables.Settings = {};
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private httpClient: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
  ) {
    super();
  }
  user: Auth;

  async ngOnInit() {
    this.user =<Auth> this.auth.account();
    this.initDatatable()
  }

  initDatatable() {
    this.title = this.__("action.action");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "name"
      },
      {
        "data": "code"
      },
      {
        "data": "method"
      },
      {
        "data": "url"
      },
      {
        "data": "typeAction",
        "name": "typeAction.name",
      },
      {
        "data": "sousModule",
        "name": "sousModule.name",
      }
    ];
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.action +"?child=sousModule,typeAction&"  + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
        this.actions = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data: []
        });
      });
    }
  }


 async genererAction(btn:any){
   btn.disabled = true;
   Swal.fire({
     title: this.__('swal.confirmTitle'),
     text: this.__('swal.confirmMsg'),
     icon: 'warning',
     showCancelButton: true,
     confirmButtonColor: '#3085d6',
     cancelButtonColor: '#d33',
     cancelButtonText: this.__('swal.no'),
     confirmButtonText: this.__('swal.yes')
   }).then(async (result) => {

     if (result.value) {
       let {code,msg} = await this.http.get<HttpApiResponse>(environment.genererAction,valuesys.httpAuthOptions()).toPromise();
       if(code === 200){
         this.toastr.success(msg,this.__("msg.success"))
         this.rerender();
       }else {
         this.toastr.success(msg,this.__("msg.error"))
       }
       btn.disabled = false;
     }
     btn.disabled = false;
   })

  }
 async addNewAction(btn:any){
   btn.disabled = true;
   Swal.fire({
     title: this.__('swal.confirmTitle'),
     text: this.__('swal.confirmMsg'),
     icon: 'warning',
     showCancelButton: true,
     confirmButtonColor: '#3085d6',
     cancelButtonColor: '#d33',
     cancelButtonText: this.__('swal.no'),
     confirmButtonText: this.__('swal.yes')
   }).then(async (result) => {

     if (result.value) {
       let {code,msg} = await this.http.get<HttpApiResponse>(environment.updateAction,valuesys.httpAuthOptions()).toPromise();
       if(code === 200){
         this.toastr.success(msg,this.__("msg.success"))
         this.rerender();
       }else {
         this.toastr.success(msg,this.__("msg.error"))
       }
       btn.disabled = false;
     }
     btn.disabled = false;
   })

  }


}
