
import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {abonnement, client, facture, type_client, type_piece} from "../../../../model/db";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {environment} from "../../../../../../environments/environment";
import * as $ from "jquery";
import {valuesys} from "../../../../../../options";
import Swal from "sweetalert2";
import {Translatable} from "../../../../../Translatable";

@Component({
  selector: 'app-client-p',
  templateUrl: './client-p.component.html',
  styleUrls: ['./client-p.component.css']
})
export class ClientPComponent extends Translatable implements OnInit {

  //DATATABLE
  //
  abonnements: abonnement[] =[ ]
  factures: facture[] =[ ]
  dtOptions2 : DataTables.Settings = {};
  dtOptions3 : DataTables.Settings = {};
  //

  title:string ;
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  public clients :client[] = [] ;
  public client:client = new client();
  public type_clients:type_client [] = [];
  public type_pieces: type_piece [] = [];
  idtypeClientAdministration : number;
  idtypeClientEntreprise : number;
  public client_details: client;
  public client_error : client ;

  searchInput : any= "";
  filtreSelect : any= 10;
  where : any= "";
  tabExportEtud : any= "";
  search : any= "";
  endPOint : any= "";
  child : any= "";
  tri : any= "";
  order : any= "";
 
    //--paginate
    public paginateListe : any = [];
    public nextPage : any = [];
    public prevPage : any = [];
    public lastPage : any = [];
    public firstPage : any = [];
    public currentPage : any = [];
    public total : any = [];
    public path : any = [];
    public lastNumPage : any = [];
    public infoData : any = [];
    public cPage_less_2 : number;
    public path_cPage_less_2 : any;
    public cPage_less_1 : number;
    public path_cPage_less_1 : any;
    public cPage_more_1 : number;
    public path_cPage_more_1 : any;
    public cPage_more_2 : number;  
    public path_cPage_more_2 : any;
    public searchColumn: string;
    public columnTemp;
    public childTemp;
   //--end-paginate
    
 
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private httpClient: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService,
              private route: Router
  ) {
    super();
  }

  async ngOnInit() {
    this.auth.initAutority("GCC");
    this.title = this.__("client.client");
    // this.initDatatable();
    this.child= "child=typeClient,Client";
    this.endPOint = environment.client+"?"+this.child+"&page=1";



    this.getUrlDatatable(this.endPOint)

  }
  initDatatable(){
    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__("client.client");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "num_client"
      },{
        "data": "prenom"
      },
      {
        "data": "nom"
      },
      {
        "data": "telephone"
      },
      {
        "data": "email"
      },
      {
        "data": "typeClient",
        "name" : "typeClient.name",
      }
    ];
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.client+"?child=typeClient&" + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
        this.clients = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    }
  }
  initDatatableInfo(){
    this.dtOptions2 = this.helper.dataTableOption();
    this.dtOptions2.columns = [
      {
        "data": "num_compteur"
      },
      {
        "data": "date_abonnement"
      },
      {
      "data" : "state"
      }
      /*,
      {
        "data": "date_resiliation"
      }*/
    ];
    this.dtOptions2.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
            environment.abonnement+ "?child=typeAbonnement&where=client_id|e|"+ this.client_details.id+"&"+
            $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
         this.abonnements = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    };

    this.dtOptions3 = this.helper.dataTableOption();
    this.dtOptions3.columns = [
      {
        "data": "periode"
      },
      {
        "data": "num_facture"
      },
      {
        "data": "consommation"
      },

      {
        "data": "montant"
      },
      {
        "data": "date_paiement"
      },

      {
        "data": "abonnement",
        "name" : "abonnement.num_compteur",
      },
      {
        "data": "numero_transaction"
      },
    ];
    this.dtOptions3.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
            environment.facture+ "?child=abonnement&child_where=abonnement.client|id|e|"+ this.client_details.id+"&"+
            $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
         this.factures = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    }
  }
  async toggleState(client:client,state:number,btn:any,type='state'){

    btn.disabled = true;

    Swal.fire({
      title: this.__('swal.confirmTitle'),
      text: this.__('swal.confirmMsg'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.__('swal.no'),
      confirmButtonText: this.__('swal.yes')
    }).then(async (result) => {
      /*if (result.value) {
        Swal.fire(
          'swal.successTitle',
          'swal.success',
          'success'
        )
      }*/
      if (result.value) {
        let toogle = await this.http.get<any>(environment.client + `/${client.id}/${state}/${type}`, valuesys.httpAuthOptions()).toPromise();
        if (toogle.code == 201) {
          this.toastr.success(toogle.msg, this.__("msg.success"));
          client[type] = toogle.data[type]

        } else {
          this.toastr.error(toogle.msg, this.__("msg.error"));
        }
        btn.disabled = false;
      }
      btn.disabled = false;
    })



  }
  async toggleStateAbonnement(abonnement:abonnement,state:number,btn:any,type='state'){

    btn.disabled = true;

    Swal.fire({
      title: this.__('swal.confirmTitle'),
      text: this.__('swal.confirmMsg'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.__('swal.no'),
      confirmButtonText: this.__('swal.yes')
    }).then(async (result) => {
      /*if (result.value) {
        Swal.fire(
          'swal.successTitle',
          'swal.success',
          'success'
        )
      }*/
      if (result.value) {
        let toogle = await this.http.get<any>(environment.abonnement + `/${abonnement.id}/${state}/${type}`, valuesys.httpAuthOptions()).toPromise();
        if (toogle.code == 201) {
          this.toastr.success(toogle.msg, this.__("msg.success"));
          abonnement[type] = toogle.data[type]

        } else {
          this.toastr.error(toogle.msg, this.__("msg.error"));
        }
        btn.disabled = false;
      }
      btn.disabled = false;
    })



  }
  delete(deletedClient:client,elm){
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.client+"/" + deletedClient.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.clients= this.clients.filter(function (item,index) {
            return item.id != deletedClient.id
          })
          Swal.fire(
            this.__('msg.deleted'),
            sup.msg,
            'success'
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          this.__('msg.canceled'),
          this.__('msg.canceled_delete'),
          'error'
        )
      }
    })
  }
  async refreshPassword(client: client, elm) {
    elm.disabled = true
    console.log(client)
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_regener_password'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed__regener_password'),
      cancelButtonText: this.__('msg.cancel_regener_password')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.get<any>(environment.clientRefreshPwd + '/' + client.id ,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 201){
          Swal.fire(
            this.__('msg.regered_password'),
            sup.msg,
            'success'
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          this.__('msg.canceled'),
          this.__('msg.canceled_regered_password'),
          'error'
        )
        elm.disabled = false
      }
    })

  }
  async openModal(template:TemplateRef<any>,currenClient:client =null,elem) {
    
    this.type_pieces= await this.helper.getSelectList(environment.type_piece,['name']);
    this.idtypeClientEntreprise=environment.ID_TYPE_CLIENT_ENTREPRISE;
    this.idtypeClientAdministration=environment.ID_TYPE_CLIENT_ADMINISTRATEUR;
    this.client_error = new client()
    elem.disabled = true ;
    this.type_clients = await this.helper.getSelectList(environment.type_client,['name']);
    if(currenClient == null){
      this.isAdd = true ;
      this.client = new client() ;
      this.titleModal = this.__('client.add') ;
    }else {
      /*TEL*/
      console.log("current client :",currenClient.telephone.substring(0,3));
      if(currenClient.telephone.substring(0,3) == "261")
      {
        this.currenCode = this.helper.getCodePaysByNum(currenClient.telephone.substr(0,3));
      }else if(currenClient.telephone.substring(0,3) == "00261"){
        this.currenCode = "mg";
      }else{
        this.currenCode = "mg";
      }
      
      /*TEL*/
      this.isAdd = false ;
      this.titleModal = this.__('client.edit') ;
      this.client = currenClient ;

    }
    elem.disabled = false ;
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});

  }
  close(){
    this.modalRef.hide()
    setTimeout(()=>{
      this.isValid = false ;
      this.client = new client()
      this.titleModal = this.__('client.edit') ;
      this.client_error = new client();
    },500)
    
  }
  async addClientForm(){
    this.client_error = new client()
    this.isValid = true ;
    if(this.isAdd ){
      let res:any =  await this.http.post<any>(environment.client,this.client,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        res.data.typeClient = this.type_clients.find(_ => _.id == this.client.type_client_id) ;
        this.clients.push( res.data);
        this.close()
      }else if(res.code === 400){
        for (let item in res.data){
          this.client_error[item] = res.data[item][0] ;
        }
        this.client_error = <client> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else {
      console.log(this.client);
      let res =  await this.http.put<any>( environment.client + '/'+this.client.id,this.client,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.client.typeClient = this.type_clients.find((_) =>_.id == this.client.type_client_id);
        this.toastr.success(res.msg,this.__("msg.success")) ;
        this.close()
      }else if(res.code === 400){
        this.client_error = <client> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    this.isValid = false ;


  }
  async openModalDetails(template: TemplateRef<any>,currentClient:client =null,elm) {
    this.initDatatableInfo()
    elm.disabled = true
    this.titleModal = this.__('label_details') ;
    this.client_details = currentClient
    console.log(this.client_details)
    this.modalRef = this.modalService.show(template,{class:"modal-lg",ignoreBackdropClick:true});
    elm.disabled = false
  }
  /*telephone */
  objetPhone : any ;
  onCountryChange(m){
    let lastDiall =  this.objetPhone .dialCode ;
    let newDiall = m.dialCode
    console.log(`LAST = ${lastDiall} ; NEW ${newDiall}`,m);
    this.element.value = this.element.value.replace(lastDiall+'',newDiall+'');
    this.objetPhone = m ;
    /*Changed*/
    this.client.telephone = this.element.value
    /*Changed*/
  }
  telInputObject(m){
    this.objetPhone = m.s ;
  }
  element : any ;
  currenCode :string =environment.CURREN_CODE_COUNTRY;
  controle(element){
    this.element = element ;
    element.value = element.value.replace(/[^0-9]/gi,'');
    if((element.value || "").substr(0,this.objetPhone.dialCode.length).indexOf(this.objetPhone.dialCode.toString()) == -1){
      if(element.value.length <=  this.objetPhone.dialCode.length  && (element.value || "").substr( this.objetPhone.dialCode.length ).length < 1)
      {
        element.value =  this.objetPhone.dialCode;
      }
      else
      {
        if((element.value || "").substr(0,this.objetPhone.dialCode.length ).indexOf( this.objetPhone.dialCode.toString()) !== -1){
          element.value =  element.value;
        }
        else {
          element.value = this.objetPhone.dialCode +  element.value;
        }
      }
    }
    /*Changed*/
    this.client.telephone = element.value;
    /*Changed*/
  }
  /*telephone */

  loadItem(btninfo: HTMLAnchorElement, info: string) {
    $(".marker a").removeClass('activeTab')
    btninfo.classList.add('activeTab')


  }
  changer(reftype){
    this.client.type_client_id=  +reftype.element.val();
    // console.log(typeof this.client.type_client_id,typeof this.idtypeClientEntreprise, typeof this.idtypeClientAdministration);
  }

  async exportExcel(btnExcel) {
    btnExcel.disabled = true
    await this.helper.exportExcel(this.print(this.clients),this.__("print.list") + this.__("client.clients"));
    btnExcel.disabled = false
  }

  print(client:client[]){
    return  client.map((client)=>{
      let t = {}
      t[this.__('client.no_client')] = client.num_client 
      t[this.__('client.prenom')] = client.prenom
      t[this.__('client.nom')] = client.nom
      t[this.__('client.telephone')] = client.telephone
      t[this.__('client.email')] = client.email
      t[this.__('type_client.type_client')] = client.typeClient.name 
      t[this.__('label_state')] =  (client.state == 0 ? this.__("tooltip.desactiver") : this.__("tooltip.active"))
      return t ;
    })  || [];

  }

  // paginnation

  async getUrlDatatable(url,col = "",refElement?: any,child?:string){

   if(refElement){
    this.searchColumn = refElement.value
    this.columnTemp = col;
    this.childTemp = child;
   }

   let name = [
     'search_ref',
     'num_client_ref',
     'prenom_ref',
     'nom_ref',
     'mail_ref',
     'telephone_ref',
     'typeclient_ref',
   ]
    
    if(col != "" || this.searchColumn){
     console.log($("prenom_ref").is(':focus'),"aaaaaaaaaaa")
      for(let i = 0;i<name.length;i++){
        let elCurrent = $(name[i]).is(':focus');
        console.log(elCurrent,":curret",name[i]);
      }

      if(this.searchColumn !="" && this.searchColumn !="undefined"){
        this.search = "&child_where="+this.childTemp+"|"+this.columnTemp+"|l|"+this.searchColumn+"";
      }else if(this.searchColumn == ""){
        this.search = "";
       }
    }else{
      if(this.searchInput !="" && this.searchInput !="undefined"){
        this.search = "&child_where_or=Client|nom|l|"+this.searchInput+",Client|prenom|l|"+this.searchInput+",Client|email|l|"+this.searchInput+",Client|num_client|l|"+this.searchInput+",typeClient|name|l|"+this.searchInput+"";
      }else if(this.searchInput == ""){
        this.search = "";
       }
    }
    

    let filtre : any= "";
    if(this.filtreSelect !="" && this.filtreSelect !="undefined"){
      filtre = "&per_page=" + this.filtreSelect;
    }
    let triage : any= "";
    if(this.tri !="" && this.tri !="undefined"){
      triage = "&__order__="+ this.order+","+ this.tri;
    }

    let toogle = await this.http.get<any>(url +"&"+ this.child + this.where + this.search  + filtre + triage  ,valuesys.httpAuthOptions()).toPromise();
    this.clients = toogle.data.data;
    console.log("url",this.clients)

   

    
    this.firstPage = toogle.data.first_page_url;
    this.prevPage = toogle.data.prev_page_url ;
    this.nextPage = toogle.data.next_page_url ;
    this.lastPage = toogle.data.last_page_url ;
    this.lastNumPage = toogle.data.last_page;
    this.path = toogle.data.path;
    this.currentPage = toogle.data.current_page;
    this.total = toogle.data.total;

    

    this.cPage_less_2 = 0
    this.cPage_less_1 = 0
    if(this.currentPage > 1 ){
      
      if(this.currentPage > 2 ){
        this.cPage_less_2 = this.currentPage - 2
        this.path_cPage_less_2 = this.path +"?page="+this.cPage_less_2;
      }
    
    this.cPage_less_1 = this.currentPage - 1 
    this.path_cPage_less_1 = this.path +"?page="+this.cPage_less_1;
    }

    
    this.cPage_more_1 = 0
    this.cPage_more_2 = 0
    if(this.currentPage < this.lastNumPage){
      this.cPage_more_1 = this.currentPage + 1 
      this.path_cPage_more_1 = this.path +"?page="+this.cPage_more_1;

      if(this.currentPage < this.lastNumPage - 1 ){
        this.cPage_more_2 = this.currentPage + 2 
        this.path_cPage_more_2 = this.path +"?page="+this.cPage_more_2;
      }

    }

    let info = this.__('datatable.info').replace('_START_', toogle.data.from)
    let info2 =info.replace('_END_', toogle.data.to)
    this.infoData = info2.replace('_TOTAL_', toogle.data.total)

  }
  async triTable(col, order){
    this.tri = col;
    this.order = order;
    this.getUrlDatatable(environment.client+"?page=1"+this.child)
  }
  
  

}
