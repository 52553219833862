import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {AuthServiceService} from "./auth-service.service";
import {HelperService} from "./helper.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor( private router: Router, private auth: AuthServiceService,private helper:HelperService) {}
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    let url = window.location.href ;
    if(url.indexOf('login') ==-1 && url.indexOf('logout') ==-1 )
    {
      window.localStorage.setItem("return",url);
    }
    return await this.auth.isConnected() === 200;
  }

}
