import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Translatable} from "../../../../../Translatable";
import {DataTableDirective} from "angular-datatables";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse, HttpApiResponse} from "../../../../model/DataTablesResponse";
import {environment} from "../../../../../../environments/environment";
import * as $ from "jquery";
import {valuesys} from "../../../../../../options";
import {document, incident, MapPoint} from "../../../../model/db";
import {Subject} from "rxjs";
import Swal from "sweetalert2";
import {Marker} from "@agm/core";
import {Location} from "@angular/common";
import { ThemeService } from 'ng2-charts';


@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.css']
})
export class IncidentComponent extends Translatable  implements  OnInit {
  /*REFRESH*/
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  // @ts-ignore
  dtTrigger: Subject = new Subject();
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  private now = new Date();
  private endTime = " 23:59:59" ;
  date_debut : Date ;
  date_debut_query : string = '';
  date_fin : Date ;
  date_fin_query : string="";

  formatQuery(){
    this.date_debut_query = this.dateFormating(this.date_debut);
    this.date_fin_query = this.dateFormating(this.date_fin);
  }

  dateFormating(date) : string
  {
    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return year+'-' + month + '-'+dt;
  }

  // rerender(): void {
  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     // Destroy the table first
  //     dtInstance.destroy();
  //     // Call the dtTrigger to rerender again
  //     this.dtTrigger.next();
  //   });
  // }
  /*REFRESH*/
  title:string ;
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;

  isSearch: boolean = false;
  documents : document [] = [];
  public incidents :incident[] = [] ;
  public incident:incident = new incident();
  public incident_details: incident;
  public message : string;
  public mapPoint:MapPoint =  new MapPoint();
  public status : string = "8";
  userConnected: any;

/**
   * datatble variable
   */


 searchInput : any= "";
 filtreSelect : any= 10;
 where : any= "";
 tabExportEtud : any= "";
 searchCustom : any= "";
 endPOint : any= "";
 child : any= "";
 tri : any= "";
 order : any= "";

   //--paginate
   public paginateListe : any = [];
   public nextPage : any = [];
   public prevPage : any = [];
   public lastPage : any = [];
   public firstPage : any = [];
   public currentPage : any = [];
   public total : any = [];
   public path : any = [];
   public lastNumPage : any = [];
   public infoData : any = [];
   public cPage_less_2 : number;
   public path_cPage_less_2 : any;
   public cPage_less_1 : number;
   public path_cPage_less_1 : any;
   public cPage_more_1 : number;
   public path_cPage_more_1 : any;
   public cPage_more_2 : number;  
   public path_cPage_more_2 : any;
   public searchColumn: string;
   public columnTemp;
   public childTemp;

  //--end-paginate
 


/**
 * 
    end datatable variable
 */

  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private httpClient: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService,
              private route: Router,
              private router: ActivatedRoute,
              private location : Location
  ) {
    super();

  }
  // @ts-ignore
  @ViewChild('showDetails') detailsModal : TemplateRef<any>
  async ngOnInit() {
    this.title = this.__("incident.incidents");
    this.userConnected = this.auth.account();
    this.auth.initAutority("INC");
    // this.initDatatable()
    let query = ""
    if (this.status != "8"){
      query = `,statut|e|${this.status}`
    }
    this.date_debut = new Date();
    this.date_fin = this.date_debut;
    this.formatQuery()
    let id = +this.router.snapshot.queryParams.id || null ;
    this.child= `child=conversation_message,incident,userStart,userValid,client`;
    this.endPOint = environment.incident+"?page=1";
    this.getUrlDatatable(this.endPOint)
    /*NOTIFICATION*/
    
    // let res:any = await this.httpClient.get( environment.incident + `/${id}?child=conversation_message,userStart,client,userValid,abonnement,typeReclamation`,valuesys.httpAuthOptions()).toPromise()
    // this.incident = res.data || new incident();
   
    if(this.incident.id){
      this.openModalDetails(this.detailsModal,this.incident,{}).then(_ =>{
        this.location.go('/app/dashbord/DEM/demande/incident');
      })


    }
    /*NOTIFICATION*/
  }
  initDatatable() {
    this.isAdd = true;
    this.isValid = false;
    this.dtOptions = this.helper.dataTableOption();

    this.dtOptions.columns = [

      {
        "data": "date_incident",
      },
      {
        "data": "name"
      },
      {
        "data": "client",
        "name": "client.prenom",
      },
      {
        "data": "client",
        "name": "client.nom"
      },
      {
        "data": "ville"
      },
      {
        "data": "quartier"
      },


      {data:"state",searchable:false},
      {data:"state",searchable:false},
    ];
    this.dtOptions.order=[[0,'desc']];
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      if(this.isSearch == true){
        if (this.date_debut == undefined){
          this.date_debut = this.date_fin
        }
        if (this.date_fin == undefined) {
          this.date_fin = this.date_debut
        }
        
        let that:any = this;
        new Promise((resolve, reject)=>{
          let query = ""
          if (this.status != "8"){
            query = `,statut|e|${this.status}`
          }
          this.formatQuery()
          this.http
              .get<DataTablesResponse>(
                  environment.incident + `?child=conversation_message,incident,userStart,userValid,client&where=date_incident|se|${this.date_debut_query },date_incident|ie|${this.date_fin_query  + this.endTime}${query}&` + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
              ).subscribe(resp => {
              
            this.incidents = resp.data;
            
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
        }).then((result)=>{
          that.incidents = result;
          that.incidents.forEach(incident => {
            if(incident.conversation_message[0] != null){
              incident.nbr_message_unread = that.countMessageUnread(incident.conversation_message[0].message);
            }
          });
        });

      }else {

        let that:any =  this;
        new Promise((resolve,reject)=>{
          let query = ""
          if (this.status != "8"){
            query = `&where=statut|e|${this.status}`
          }
          this.http
          .get<DataTablesResponse>(
              environment.incident + `?child=conversation_message,incident,userStart,userValid,client${query}&` + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
          ).subscribe(resp => {
            console.log("ameeeeeeeeeeennnnnnnn",resp.data)
            resolve(resp.data);
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
        }).then((result)=>{
            that.incidents = result;
            that.incidents.forEach(incident => {
              if(incident.conversation_message[0] != null){
                incident.nbr_message_unread = that.countMessageUnread(incident.conversation_message[0].message);
              }
            });
        })

      }
    }
  }

  countMessageUnread(message: any[]){
    console.log(message,'message in function')
    let t = 0;
    for(let i=0;i<message.length;i++){
      if(message[i].statut == 0 && message[i].envoyeur == 1){
        t += 1;
      }
 
    }
    return t;
  }

  async openModal(template:TemplateRef<any>,currenIncident:incident =null,elem) {
    elem.disabled = true ;
    this.titleModal=this.__('incident.infoscom')
    this.incident=currenIncident;
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});
    elem.disabled = false ;

  }

  saveStatutSave(incident:incident,state:any,btn:any){
    btn.disabled =true
    // @ts-ignore
    Swal.fire({
      title: this.__('swal.num_doss'),
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        // @ts-ignore
        required:true,
      },
      validationMessage :this.__("validation.required"),
      showCancelButton: true,
      cancelButtonText:  this.__('swal.no'),
      confirmButtonText:  this.__('swal.send'),
      showLoaderOnConfirm: true,
      preConfirm: (num_dossier) => {
        this.http.get<any>(environment.incident + `/${incident.id}/${state}/statut?num_dossier=${num_dossier}`, valuesys.httpAuthOptions()).toPromise()
          .then(response => {
            if (response.code === 201) {
              console.log("reponse",response)

              if(incident.conversation_message[0].length !== 0){
                const id_conv = incident.conversation_message[0].id;
                this.http.get(`${environment.conversation}/${id_conv}/${1}/statut`,valuesys.httpAuthOptions()).toPromise();
              }

              this.toastr.success(response.msg, this.__("msg.success"));
              incident.statut = response.data.statut;
              incident.num_dossier=num_dossier;
              // this.rerender();
            } else if (response.code === 500) {
                  this.toastr.warning("Le numéro de dossier doit être unique ", this.__("msg.warning"));
                } else {
              this.toastr.error(response.msg, this.__("msg.error"));
            }
          })
          .catch(error => {
            Swal.showValidationMessage(



              `${this.__('msg.error')} : ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    })/*.then((result) => {
      if (result.value) {
        Swal.fire({
          title: `${result.value.login}'s avatar`,
          imageUrl: result.value.avatar_url
        })
      }
    })*/
    btn.disabled =false
  }


  async toggleState(incident: incident, state: number, btn: any={},msg :  any ='') {

    btn.disabled = true;

    Swal.fire({
      title: this.__('swal.confirmTitle'),
      text: this.__('swal.confirmMsg'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.__('swal.no'),
      confirmButtonText: this.__('swal.yes')
    }).then(async (result) => {
      /*if (result.value) {
        Swal.fire(
          'swal.successTitle',
          'swal.success',
          'success'
        )
      }*/
      if (result.value) {
        let toogle = await this.http.get<any>(environment.incident + `/${incident.id}/${state}/statut?msg=${msg}`, valuesys.httpAuthOptions()).toPromise();
        if (toogle.code == 201) {
          this.toastr.success(toogle.msg, this.__("msg.success"));
          incident.statut = toogle.data.statut;
          // this.rerender();

        } else {
          this.toastr.error(toogle.msg, this.__("msg.error"));
        }
        btn.disabled = false;
      }
      btn.disabled = false;
    })


  }
  close(){
    this.modalRef.hide()
    this.isValid = false ;
    this.incident = new incident()
    this.message = '';
    this.documents = [] ;

  }
  async openModalDetails(template: TemplateRef<any>,currentincident:incident =null,elm) {
    elm.disabled = true
    this.titleModal = this.__('label_details') ;
   // console.log("currentincident.id",currentincident.id)
    let res1 = await this.http.get<any>(environment.document +"?where=incident_id|e|"+currentincident.id ,valuesys.httpAuthOptions()).toPromise();
    //console.log("res",res1)
    this.documents = res1.data ;
    this.incident_details = currentincident ;
   // console.log("incident detail",this.incident_details)
    //MAP
    this.mapPoint.lat = +this.incident_details.latitude || 14.7110218;
    this.mapPoint.lng = +this.incident_details.longitude || -17.5008444;
    this.mapPoint.texto = this.incident_details.name;

    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});
    //MAP
    elm.disabled = false
  }

  clickMarcker(amg: any) {
    alert(`Longitude  ${amg} ` )
  }




  async toggleStatut(incident: incident, statut: number, btn: any) {

    btn.disabled = true;

    Swal.fire({
      title: this.__('swal.confirmTitle'),
      text: this.__('swal.confirmMsg'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.__('swal.no'),
      confirmButtonText: this.__('swal.yes')
    }).then(async (result) => {

      if (result.value) {
        let toogle = await this.http.get<any>(environment.incident+`/${incident.id}/${statut}/statut`,valuesys.httpAuthOptions()).toPromise();
        if (toogle.code == 201) {
          this.toastr.success(toogle.msg, this.__("msg.success"));
          incident.statut = toogle.data.statut;
          // this.rerender();

        } else {
          this.toastr.error(toogle.msg, this.__("msg.error"));
        }
        btn.disabled = false;
      }
      btn.disabled = false;
    })


  }


  download(document: document,btn:any) {
    btn.disabled =true
    this.toDataURL(document.name,(dataUrl)=>{
      btn.disabled =false
      window['download'](dataUrl, document.name ,dataUrl.split(';')[0].split(":")[1]);
      return false

    })
  }
  loadItem(btninfo: HTMLAnchorElement) {
    $(".marker a").removeClass('activeTab')
    btninfo.classList.add('activeTab')
  }


  async infos_complementaires() {
    console.log(this.incident,"ifsdfsdfdsfdsfdsfssfsdfdsffvffvfgff")

    let {code,msg} = <HttpApiResponse> await this.http.put<any>(environment.incident+`/infoComp/${this.incident.id}`,{
      message:this.message,
      sujet: this.incident.name,
      acteur_jirama: this.userConnected.info.id
    },valuesys.httpAuthOptions()).toPromise();
    if (code == 200) {
      this.toastr.success(this.__('incident.succes_com'), this.__("msg.success"));

    } else {
      this.toastr.error(msg, this.__("msg.error"));
    }

  this.close()
  }

  async exportPdf(btnPdf) {
    btnPdf.disabled = true
    if(this.date_debut == undefined && this.date_fin == undefined){
      await this.helper.exportPdf(this.print(this.incidents),this.__("print.list") + this.__("incident.incidents"));
    }else {
      await this.helper.exportPdf(this.print(this.incidents),this.__("incident.incidents")  + this.__("print.from")  + this.date_debut_query.split('-').reverse().join('-') + this.__("print.to") + this.date_fin_query.split('-').reverse().join('-'));
    }
    btnPdf.disabled = false
  }
  async exportExcel(btnExcel) {
    btnExcel.disabled = true
    if(this.date_debut == undefined && this.date_fin == undefined){
      await this.helper.exportExcel(this.print(this.incidents),this.__("print.list") + this.__("incident.incidents"));
    }else {
      await this.helper.exportExcel(this.print(this.incidents),this.__("incident.incidents") + this.__("print.from")  + this.date_debut_query.split('-').reverse().join('-') + this.__("print.to") + this.date_fin_query.split('-').reverse().join('-'));
    }
    btnExcel.disabled = false
  }
  print(incident:incident[]){
    return  incident.map((incident)=>{
      let t = {}
      t[this.__('incident.date_incident')] = incident.date_incident
      t[this.__('incident.num_dossier')] = incident.num_dossier
      t[this.__('incident.name')] = incident.name
      t[this.__('client.prenom')] = incident.client.prenom
      t[this.__('client.nom')] = incident.client.nom
      t[this.__('print.statut')] =  (incident.statut == 0 ? this.__("tooltip.running_trated") : this.__('tooltip.state_save'))
      return t ;
    })  || [];

  }

  cancel() {
    this.isSearch = false;
    this.date_debut = undefined
    this.date_fin = undefined
    this.status= "8"
    // this.rerender();
  }

  search() {
    this.isSearch = true;
    if (this.date_debut || this.date_fin) {
      // this.rerender();
    }else {
      this.isSearch = false;
      this.date_debut = undefined
      this.date_fin = undefined
      // this.rerender();
    }
  }

  changerStatus(event){
    this.status=event;
  }

  async goToConversation(t:any){
 
    const conversation_id = t.conversation_message[0].id;
    const responseUp:any = await this.http.put(`${environment.message}/${conversation_id}/lireMessageClient`,{},valuesys.httpAuthOptions()).toPromise();
    console.log(responseUp)
    if(responseUp.code == 201){
      this.route.navigate(['app','dashbord','DEM','demande','reclamation','message'],{state: {
        reclamation: t
      }})
    }
   
  }


  async getUrlDatatable(url,col = "",refElement?: any,child?:string){



    let query = ""
    if (this.status != "8"){
      query = `,statut|e|${this.status}`
    }
    console.log("avant",this.date_debut);
    console.log("avant fin",this.date_fin);
    this.formatQuery();
    console.log("apres",this.date_debut_query);
    console.log("apres fin",this.date_fin_query);

    if(this.date_debut_query != ''){
    this.child = `child=conversation_message,userStart,client,userValid,abonnement,typeReclamation&where=date_incident|se|${this.date_debut_query },date_incident|ie|${this.date_fin_query  + this.endTime}${query}`;
  }






    if(refElement ){
     this.searchColumn = refElement.value
     this.columnTemp = col;
     this.childTemp = child;
    }
 
    let name = [
      'search_ref',
      'num_client_ref',
      'prenom_ref',
      'nom_ref',
      'mail_ref', 
      'telephone_ref',
      'typeclient_ref',
    ]
     
     if(col != "" || this.searchColumn){

       if(this.searchColumn !="" && this.searchColumn !="undefined"){
         this.searchCustom = "&child_where="+this.childTemp+"|"+this.columnTemp+"|l|"+this.searchColumn+"";
       }else if(this.searchColumn == ""){
        this.searchCustom = "";
       }
     }else{
       if(this.searchInput !="" && this.searchInput !="undefined"){
         this.searchCustom = "&child_where_or=incident|num_dossier|l|"+this.searchInput+",incident|date_incident|l|"+this.searchInput+",client|prenom|l|"+this.searchInput+",client|nom|l|"+this.searchInput+",incident|ville|l|"+this.searchInput+",incident|quartier|l|"+this.searchInput+"";
       }else if(this.searchInput == ""){
        this.searchCustom = "";
       }
     }
     
 
     let filtre : any= "";
     if(this.filtreSelect !="" && this.filtreSelect !="undefined"){
       filtre = "&per_page=" + this.filtreSelect;
       console.log("filter",this.filtreSelect)
     }
     let triage : any= "";
     if(this.tri !="" && this.tri !="undefined"){
       triage = "&__order__="+ this.order+","+ this.tri;
     }

 
     let toogle = await this.http.get<any>(url +"&"+ this.child + this.searchCustom  + filtre + triage  ,valuesys.httpAuthOptions()).toPromise();
     this.incidents = toogle.data.data;
     this.incidents.forEach(incident => {
      if(incident.conversation_message[0] != null){
        incident.nbr_message_unread = this.countMessageUnread(incident.conversation_message[0].message);
      }
    }); 
    
 
     
     this.firstPage = toogle.data.first_page_url;
     this.prevPage = toogle.data.prev_page_url ;
     this.nextPage = toogle.data.next_page_url ;
     this.lastPage = toogle.data.last_page_url ;
     this.lastNumPage = toogle.data.last_page;
     this.path = toogle.data.path;
     this.currentPage = toogle.data.current_page;
     this.total = toogle.data.total;
 
     
 
     this.cPage_less_2 = 0
     this.cPage_less_1 = 0
     if(this.currentPage > 1 ){
       
       if(this.currentPage > 2 ){
         this.cPage_less_2 = this.currentPage - 2
         this.path_cPage_less_2 = this.path +"?page="+this.cPage_less_2;
       }
     
     this.cPage_less_1 = this.currentPage - 1 
     this.path_cPage_less_1 = this.path +"?page="+this.cPage_less_1;
     }
 
     
     this.cPage_more_1 = 0
     this.cPage_more_2 = 0
     if(this.currentPage < this.lastNumPage){
       this.cPage_more_1 = this.currentPage + 1 
       this.path_cPage_more_1 = this.path +"?page="+this.cPage_more_1;
 
       if(this.currentPage < this.lastNumPage - 1 ){
         this.cPage_more_2 = this.currentPage + 2 
         this.path_cPage_more_2 = this.path +"?page="+this.cPage_more_2;
       }
 
     }
 
     let info = this.__('datatable.info').replace('_START_', toogle.data.from)
     let info2 =info.replace('_END_', toogle.data.to)
     this.infoData = info2.replace('_TOTAL_', toogle.data.total)
 
   }
   async triTable(col, order){
     this.tri = col;
     this.order = order;
     this.getUrlDatatable(environment.reclamation+"?page=1")
   }



}
