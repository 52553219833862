import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Translatable} from "../../../../../Translatable";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {BsDatepickerConfig, BsModalRef, BsModalService} from "ngx-bootstrap";
import {document, transaction} from "../../../../model/db";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {environment} from "../../../../../../environments/environment";
import * as $ from "jquery";
import {valuesys} from "../../../../../../options";
import Swal from "sweetalert2";

@Component({
  selector: 'app-paiement',
  templateUrl: './paiement.component.html',
  styleUrls: ['./paiement.component.css']
})
export class PaiementComponent extends Translatable  implements  OnInit, AfterViewInit,OnDestroy {
  //DATATABLE
  /*REFRESH*/

  /**
   * datatble variable
   */


   searchInput : any= "";
   filtreSelect : any= 10;
   where : any= "";
   tabExportEtud : any= "";
   searchCustom : any= "";
   endPOint : any= "";
   child : any= "";
   tri : any= "";
   order : any= "";
  
     //--paginate
     public paginateListe : any = [];
     public nextPage : any = [];
     public prevPage : any = [];
     public lastPage : any = [];
     public firstPage : any = [];
     public currentPage : any = [];
     public total : any = [];
     public path : any = [];
     public lastNumPage : any = [];
     public infoData : any = [];
     public cPage_less_2 : number;
     public path_cPage_less_2 : any;
     public cPage_less_1 : number;
     public path_cPage_less_1 : any;
     public cPage_more_1 : number;
     public path_cPage_more_1 : any;
     public cPage_more_2 : number;  
     public path_cPage_more_2 : any;
     public searchColumn: string;
     public columnTemp;
     public childTemp;
 
    //--end-paginate
   
 
 
  /**
   * 
      end datatable variable
   */
  
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  // @ts-ignore
  dtTrigger: Subject = new Subject();
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

//Date filter
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  private endTime = " 23:59:59" ;
  date_debut : Date = new Date();
  date_debut_query : string = '';
  date_fin : Date = new Date();
  date_fin_query : string="";

  formatQuery(){
    this.date_debut_query = this.dateFormating(this.date_debut);
    this.date_fin_query = this.dateFormating(this.date_fin);
  }

  dateFormating(date) : string
  {
    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return year+'-' + month + '-'+dt;
  }

  //Date filter
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  /*REFRESH*/

  title:string ;
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  public transactions :transaction[] = [] ;
  public transaction:transaction = new transaction();
  public transaction_details: transaction;
  public transaction_error : transaction ;
  statut: string = '';
  statut_list: any[] = [
    {id:0, text:'NON VALIDÉ'},{id: 1, text:'VALIDÉ'}
  ];


  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private httpClient: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService,
              private route: Router
  ) {
    super();
  }

  async ngOnInit() {
    this.title = this.__("transaction.transactions");
    this.auth.initAutority("SUP");
    // this.initDatatable()
    
    this.date_debut = new Date();
    this.date_fin = this.date_debut;
    console.log("tesstt");
    this.formatQuery()
    this.child= `child=client,partenaire,service,abonnement,userValid`;
    this.endPOint = environment.transaction+"?page=1";
    this.getUrlDatatable(this.endPOint)
  }

  initDatatable() {
    this.isAdd = true;
    this.isValid = false;
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "date_transaction"
      },
      {
        "data": "num_transaction"
      },
      {
        "data": "service",
        name : "service.name"
      },
      {
        "data": "num_facture"
      },
      {
        "data": "montant"
      },
      {
        "data": "abonnement",
        name : "abonnement.num_compteur"
      },
        {
            "data": "num_ticket"
        },
        /*


      {
        "data": "client",
        name : "client.prenom"
      },
      {
        "data": "client",
        name : "client.nom"
      }*/

       {
         "data": "partenaire",
         "name": "partenaire.name"
       }

       ,
      {data:"state",searchable:false},
      {data:"state",searchable:false},
    ];
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      console.log(this.statut)
      this.formatQuery()
      if (this.statut == '' || this.statut == null){
        this.http
          .get<DataTablesResponse>(
            environment.transaction + `?child=client,partenaire,service,abonnement,userValid&where=date_transaction|se|${this.date_debut_query },date_transaction|ie|${this.date_fin_query  + this.endTime}&` + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
          ).subscribe(resp => {
            console.log(resp);
          this.transactions = resp.data;
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });

      }else {
        this.http
          .get<DataTablesResponse>(
            environment.transaction + `?child=client,partenaire,service,abonnement,userValid&where=date_transaction|se|${this.date_debut_query },date_transaction|ie|${this.date_fin_query  + this.endTime},statut|e|${this.statut}&` + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
          ).subscribe(resp => {
          this.transactions = resp.data;
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });

      }
    }
  }

  close(){
    this.modalRef.hide()
    this.isValid = false ;
    this.transaction = new transaction()
    this.transaction_error = new transaction();


  }
  async openModalDetails(template: TemplateRef<any>,currenttransaction:transaction =null,elm) {
    elm.disabled = true
    this.titleModal = this.__('label_details') ;
   // let res1 = await this.http.get<any>(environment.document +"?where=transaction_id|e|"+currenttransaction.id ,valuesys.httpAuthOptions()).toPromise();
    this.transaction_details = currenttransaction ;
    console.log(this.transaction_details)
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});
    elm.disabled = false
  }


  async toggleState(transaction:transaction,statut:number,btn:any,type='statut'){

    btn.disabled = true;

    // ACTIVATION
    if(statut == 1)
    {
      Swal.fire({
        title: this.__("swal.confirmTitle"),
        text: this.__("swal.confirmValidateTransaction"),
        icon: 'warning',
        input: 'text',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.__('swal.no'),
        confirmButtonText: this.__('swal.yes'),
        preConfirm: (inputValue) => {
          
          if(inputValue == "")
          {
            Swal.showValidationMessage(this.__("swal.requiredConfirmPartenaire"));
          }
          
        }
      }).then(
        async (result) => {
          
          if(!result.dismiss)
          {
            let toogle = await this.http.post<any>(environment.regularise ,{ transaction_id: transaction.id, statut: statut ,transaction_partenaire : result.value}, valuesys.httpAuthOptions()).toPromise();
              if (toogle.data.Erreur == "False") {
                this.toastr.success(toogle.data.Message, this.__("msg.success"));
                transaction[type] = toogle.data[type]
                this.rerender();
  
              } else {
                this.toastr.error(toogle.data.Message, this.__("msg.error"));
                btn.disabled = false;
              }
          }else{
            btn.disabled = false;
          }
           
      });
    }

    // REJET
    if(statut == 2)
    {
      Swal.fire({
        title: this.__('swal.confirmTitle'),
        text: this.__('swal.confirmMsg'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.__('swal.no'),
        confirmButtonText: this.__('swal.yes')
      }).then(async (result) => {
        
        if(!result.dismiss)
        {
          if (result.value) {
            let toogle = await this.http.post<any>(environment.regularise ,{ transaction_id: transaction.id, statut: statut }, valuesys.httpAuthOptions()).toPromise();
            if (toogle.data.Erreur == "False") {
              this.toastr.success(toogle.data.Message, this.__("msg.success"));
              transaction[type] = toogle.data[type]
              this.rerender();
    
            } else {
              this.toastr.error(toogle.data.Message, this.__("msg.error"));
              btn.disabled = false;
            }
          
          }
        }else{
          btn.disabled = false;
        }
        
      });
    }
  }

  async exportPdf(btnPdf) {
    btnPdf.disabled = true
    await this.helper.exportPdf(this.print(this.transactions),this.__("transaction.tiltlePrint")  + this.__("print.from")  + this.date_debut_query.split('-').reverse().join('-') + this.__("print.to") + this.date_fin_query);
    btnPdf.disabled = false
  }
  async exportExcel(btnExcel) {
    btnExcel.disabled = true
    this.print(this.transactions);
    await this.helper.exportExcel(this.print(this.transactions),this.__("transaction.tiltlePrint") + this.__("print.from")  + this.date_debut_query.split('-').reverse().join('-') + this.__("print.to") + this.date_fin_query);
    btnExcel.disabled = false
  }
  print(transactions:transaction[]){
   
    
    return  transactions.map((transaction)=>{
      let t = {}
     
      t[this.__('print.date')] = transaction.date_transaction
      t[this.__('print.num_transact')] = transaction.num_transaction
      t[this.__('print.service')] = transaction.service.name
      t[this.__('print.montant')] = window['accounting'].formatNumber( transaction.montant, 2, "  ");
      t[this.__('print.numero_installation')] = (transaction.abonnement !== null) ? transaction.abonnement.num_compteur  : "";
      t[this.__('facture.num_facture')] = transaction.num_facture ;
      t[this.__('facture.num_ticket')] = transaction.num_ticket;
      /* t[this.__('print.prenom')] = transaction.client.prenom
      t[this.__('print.nom')] = transaction.client.nom*/
      t[this.__('print.partenaire')] = transaction.partenaire.name
      t[this.__('print.statut')] =  (transaction.statut == 0 ? this.__("tooltip.running") : (transaction.statut == 1) ? this.__('tooltip.validate') : this.__('tooltip.rejeter'))
      return t ;
    })  || [];

  }


  /**
   * datatable function
   */


   async getUrlDatatable(url,col = "",refElement?: any,child?:string){



    // let query = ""
    // if (this.status != "8"){
    //   query = `,statut|e|${this.status}`
    // }
    console.log("avant",this.date_debut);
    console.log("avant fin",this.date_fin);
    this.formatQuery();
    console.log("apres",this.date_debut_query);
    console.log("apres fin",this.date_fin_query);
    let query = ""
    if (this.statut){
      query = `,statut|e|${this.statut}`
    }
    if(this.date_debut_query != ''){
    this.child =`child=client,partenaire,service,abonnement,transaction,userValid&where=date_transaction|se|${this.date_debut_query },date_transaction|ie|${this.date_fin_query  + this.endTime},${query}`;
  }






    if(refElement ){
     this.searchColumn = refElement.value
     this.columnTemp = col;
     this.childTemp = child;
    }
 
    let name = [
      'search_ref',
      'num_client_ref',
      'prenom_ref',
      'nom_ref',
      'mail_ref',
      'telephone_ref',
      'typeclient_ref',
    ]
     
     if(col != "" || this.searchColumn){
       if(this.searchColumn !="" && this.searchColumn !="undefined"){
         this.searchCustom = "&child_where="+this.childTemp+"|"+this.columnTemp+"|l|"+this.searchColumn+"";
       }else if(this.searchColumn == ""){
        this.searchCustom = "";
       }
     }else{
       if(this.searchInput !="" && this.searchInput !="undefined"){
         this.searchCustom = "&child_where_or=transaction|date_transaction|l|"+this.searchInput+",transaction|num_transaction|l|"+this.searchInput+",transaction|num_facture|l|"+this.searchInput+",transaction|montant|l|"+this.searchInput+",transaction|num_ticket|l|"+this.searchInput+",service|name|l|"+this.searchInput+",partenaire|name|l|"+this.searchInput+"";
       }else if(this.searchInput == ""){
        this.searchCustom = "";
       }
     }
     
 
     let filtre : any= "";
     if(this.filtreSelect !="" && this.filtreSelect !="undefined"){
       filtre = "&per_page=" + this.filtreSelect;
       console.log("filter",this.filtreSelect)
     }
     let triage : any= "";
     if(this.tri !="" && this.tri !="undefined"){
       triage = "&__order__="+ this.order+","+ this.tri;
     }

 
     let toogle = await this.http.get<any>(url +"&"+ this.child + this.searchCustom  + filtre + triage  ,valuesys.httpAuthOptions()).toPromise();
     this.transactions = toogle.data.data;
     console.log("url",this.transactions)
 
    
 
     
     this.firstPage = toogle.data.first_page_url;
     this.prevPage = toogle.data.prev_page_url ;
     this.nextPage = toogle.data.next_page_url ;
     this.lastPage = toogle.data.last_page_url ;
     this.lastNumPage = toogle.data.last_page;
     this.path = toogle.data.path;
     this.currentPage = toogle.data.current_page;
     this.total = toogle.data.total;
 
     
 
     this.cPage_less_2 = 0
     this.cPage_less_1 = 0
     if(this.currentPage > 1 ){
       
       if(this.currentPage > 2 ){
         this.cPage_less_2 = this.currentPage - 2
         this.path_cPage_less_2 = this.path +"?page="+this.cPage_less_2;
       }
     
     this.cPage_less_1 = this.currentPage - 1 
     this.path_cPage_less_1 = this.path +"?page="+this.cPage_less_1;
     }
 
     
     this.cPage_more_1 = 0
     this.cPage_more_2 = 0
     if(this.currentPage < this.lastNumPage){
       this.cPage_more_1 = this.currentPage + 1 
       this.path_cPage_more_1 = this.path +"?page="+this.cPage_more_1;
 
       if(this.currentPage < this.lastNumPage - 1 ){
         this.cPage_more_2 = this.currentPage + 2 
         this.path_cPage_more_2 = this.path +"?page="+this.cPage_more_2;
       }
 
     }
 
     let info = this.__('datatable.info').replace('_START_', toogle.data.from)
     let info2 =info.replace('_END_', toogle.data.to)
     this.infoData = info2.replace('_TOTAL_', toogle.data.total)
 
   }
   async triTable(col, order){
     this.tri = col;
     this.order = order;
     this.getUrlDatatable(environment.transaction+"?page=1")
   }


  

   /**
    * end datatable funciton
    */




}

