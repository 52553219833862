import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {partenaire, profil, transaction} from "../../../../model/db";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {environment} from "../../../../../../environments/environment";
import * as $ from "jquery";
import {valuesys} from "../../../../../../options";
import {Translatable} from "../../../../../Translatable";
import Swal from "sweetalert2";

@Component({
  selector: 'app-partenaire',
  templateUrl: './partenaire.component.html',
  styleUrls: ['./partenaire.component.css']
})
export class PartenaireComponent extends Translatable  implements  OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  title:string ;
  dtOptions: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  public partenaires :partenaire[] = [] ;
  public partenaire:partenaire = new partenaire();
  public partenaire_details: partenaire;
  public partenaire_error : partenaire ;
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private httpClient: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService,
              private route: Router
  ) {
    super();
  }

  async ngOnInit() {
    this.auth.initAutority(["PAR","SUP"],['REP']);
    this.initDatatable()
  }
  initDatatable() {
    this.isAdd = true;
    this.isValid = false;
    this.title = this.__("partenaire.partenaires");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "name"
      },
      {
        "data": "pourcentage_commission"
      },

      {
        "data": "taux_de_base"
      },

      {
        "data": "num_caisse"
      }
    ];
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.partenaire + "?" + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
        this.partenaires = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data: []
        });
      });
    }
  }
  initDatatable2(partenaire:partenaire) {
    this.dtOptions2 = this.helper.dataTableOption();
    this.dtOptions2.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.transaction+"?child=service,client&where=partenaire_id|e|"+partenaire.id + "&" + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
        this.transactions = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data: []
        });
      });
    }
  }



async openModal(template:TemplateRef<any>,currenPartenaire:partenaire =null,elem) {

  this.partenaire_error = new partenaire()
  elem.disabled = true ;
  if(currenPartenaire == null){
    this.isAdd = true ;
    this.partenaire = new partenaire() ;
    this.titleModal = this.__('partenaire.add') ;
  }else {

    this.isAdd = false ;
    this.titleModal = this.__('partenaire.edit') ;
    this.partenaire = currenPartenaire ;

  }
  elem.disabled = false ;
  this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});

}
close(){
  this.modalRef.hide()
  this.isValid = false ;
  this.partenaire = new partenaire()
  this.titleModal = this.__('partenaire.edit') ;
  this.partenaire_error = new partenaire();
  this.transactions=[];
}
async addUserForm(){
  this.partenaire_error = new partenaire()
  this.isValid = true ;
  if(this.isAdd ){
    let res:any =  await this.http.post<any>(environment.partenaire,this.partenaire,valuesys.httpAuthOptions()).toPromise();
    if(res.code === 201){
      this.toastr.success(res.msg,this.__("msg.success")) ;
      this.partenaires.push( res.data);
      this.close()
    }else if(res.code === 400){
      for (let item in res.data){
        this.partenaire_error[item] = res.data[item][0] ;
      }
      this.partenaire_error = <partenaire> this.helper.errorForm(res.data);
      this.toastr.warning(res.msg,this.__("msg.warning")) ;
    }
    else {
      this.toastr.error(res.msg,this.__("msg.error")) ;
    }
  }else {
    console.log(this.partenaire);
    let res =  await this.http.put<any>( environment.partenaire + '/'+this.partenaire.id,this.partenaire,valuesys.httpAuthOptions()).toPromise();
    if(res.code === 201){
      this.toastr.success(res.msg,this.__("msg.success")) ;
      this.close()
    }else if(res.code === 400){
      this.partenaire_error = <partenaire> this.helper.errorForm(res.data);
      this.toastr.warning(res.msg,this.__("msg.warning")) ;
    }
    else {
      this.toastr.error(res.msg,this.__("msg.error")) ;
    }
  }
  this.isValid = false ;


}
async openModalDetails(template: TemplateRef<any>,currentpartenaire:partenaire =null,elm) {
  elm.disabled = true
  this.titleModal = this.__('label_details') ;
  let res1 = await this.http.get<any>(environment.partenaire + '/' + currentpartenaire.id ,valuesys.httpAuthOptions()).toPromise();
  this.partenaire_details = res1.data ;
  console.log(this.partenaire_details)
  this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});
  elm.disabled = false
}

  async toggleState(partenaire:partenaire,state:number,btn:any){

    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.partenaire+`/${partenaire.id}/${state}/state`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      partenaire.state = toogle.data.state

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }
  delete(deletedUser:partenaire,elm){
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.partenaire+"/" + deletedUser.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.partenaires= this.partenaires.filter(function (item,index) {
            return item.id != deletedUser.id
          })
          Swal.fire(
            this.__('msg.deleted'),
            sup.msg,
            'success'
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          this.__('msg.canceled'),
          this.__('msg.canceled_delete'),
          'error'
        )
      }
    })
  }


  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (!file) {
      return;
    }
    if (file.size > 1000000) {
      this.toastr.error("taille image trop grande", "Error")
      $("#logo").val("");
      return;
    }
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.error("invalid format", "Error")
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e) {
    let reader = e.target;
    this.partenaire.logo = reader.result;
  }
  transactions:transaction[]=[];

 async openModalTransaction(showTransaction: any, partenaire: partenaire, openModalBtnTransaction: HTMLButtonElement) {
  openModalBtnTransaction.disabled=true;
   this.initDatatable2(partenaire);
   this.modalRef = this.modalService.show(showTransaction,{class:"modal-lg",ignoreBackdropClick:true});
   this.titleModal=this.__("transaction.transactions");
   openModalBtnTransaction.disabled=false;
 }
}
