import { Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import * as $ from  'jquery';
import Swal from 'sweetalert2' ;
import {Translatable} from "../../../../../Translatable";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {plateforme, profil, user} from "../../../../model/db";
import {valuesys} from "../../../../../../options";
import {environment} from "../../../../../../environments/environment";
import {DataTableDirective} from "angular-datatables";
@Component({
  selector: 'app-utilisateur',
  templateUrl: './utilisateur.component.html',
  styleUrls: ['./utilisateur.component.css']
})
export class UtilisateurComponent extends Translatable  implements  OnInit {
  //DATATABLE
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  title:string ;
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  public users :user[] = [] ;
  public user:user = new user();
  public profils:profil [] = [];
  public user_details: user;
  public user_error : user ;
  //public plateforme : plateforme [] = [] ;
    constructor(private activatedRoute: ActivatedRoute,
                private auth : AuthServiceService,
                private http: HttpClient,
                private httpClient: HttpClient,
                private toastr: ToastrService,
                private helper: HelperService,
                private modalService: BsModalService,
                private ngxService: NgxUiLoaderService,
                private route: Router
    ) {
      super();
    }
  async ngOnInit() {
    this.auth.initAutority("USR");
    this.initDatatable()
  }
  initDatatable(){
    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__("lebel_users");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "f_name"
      },
      {
        "data": "l_name"
      },
      {
        "data": "phone"
      },
      {
        "data": "email"
      },
      {
        "data": "profil",
        "name" : "profil.name",
      }
    ];
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.user+"?child=profil&" + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
        this.users = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    }
  }
  async toggleState(user:user,state:number,btn:any){

    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.user+`/${user.id}/${state}/state`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      user.state = toogle.data.state

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }
  delete(deletedUser:user,elm){
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.user+"/" + deletedUser.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.users= this.users.filter(function (item,index) {
            return item.id != deletedUser.id
          })
          Swal.fire(
            this.__('msg.deleted'),
            sup.msg,
            'success'
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          this.__('msg.canceled'),
          this.__('msg.canceled_delete'),
          'error'
        )
      }
    })
  }
  async refreshPassword(user: user, elm) {
    elm.disabled = true
    console.log(user)
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_regener_password'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed__regener_password'),
      cancelButtonText: this.__('msg.cancel_regener_password')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.get<any>(environment.userRefreshPwd + '/' + user.id ,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 201){
          Swal.fire(
            this.__('msg.regered_password'),
            sup.msg,
            'success'
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          this.__('msg.canceled'),
          this.__('msg.canceled_regered_password'),
          'error'
        )
        elm.disabled = false
      }
    })

  }
  async openModal(template:TemplateRef<any>,currenUser:user =null,elem) {

    this.user_error = new user()
    elem.disabled = true ;
    this.profils = await this.helper.getSelectList(environment.profil,['name']);
   // this.plateforme = await this.helper.getSelectList( environment.plateforme,['name']);
    if(currenUser == null){
      this.isAdd = true ;
      this.user = new user() ;
      this.titleModal = this.__('label_user_new') ;
    }else {
      /*TEL*/
      this.currenCode = this.helper.getCodePaysByNum(currenUser.phone.substr(0,3));
      /*TEL*/
      this.isAdd = false ;
      this.titleModal = this.__('label_user_edit') ;
      this.user = currenUser ;

    }
    elem.disabled = false ;
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});

  }
  close(){
    this.modalRef.hide()
    this.isValid = false ;
    this.user = new user()
    this.titleModal = this.__('label_user_edit') ;
    this.user_error = new user();
  }
  async addUserForm(){
    this.user_error = new user()
    this.isValid = true ;
    if(this.isAdd ){
      let res:any =  await this.http.post<any>(environment.user,this.user,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
         res.data.profil = this.profils.find( _ => _.id == this.user.profil_id) ;
         this.users.push( res.data);
         this.close()
      }else if(res.code === 400){
        for (let item in res.data){
          this.user_error[item] = res.data[item][0] ;
        }
        this.user_error = <user> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else {
      console.log(this.user);
      let res =  await this.http.put<any>( environment.user + '/'+this.user.id,this.user,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.user.profil = this.profils.find((_) =>_.id == this.user.profil_id);
        this.toastr.success(res.msg,this.__("msg.success")) ;
        this.close()
      }else if(res.code === 400){
        this.user_error = <user> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    this.isValid = false ;


  }
  async openModalDetails(template: TemplateRef<any>,currentUser:user =null,elm) {
    elm.disabled = true
    this.titleModal = this.__('label_details') ;
    let res1 = await this.http.get<any>(environment.user + '/' + currentUser.id +"?child=profil,plateforme",valuesys.httpAuthOptions()).toPromise();
    this.user_details = res1.data ;
    console.log(this.user_details)
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});
    elm.disabled = false
  }
  /*telephone */
  objetPhone : any ;
  onCountryChange(m){
    let lastDiall =  this.objetPhone .dialCode ;
    let newDiall = m.dialCode
    console.log(`LAST = ${lastDiall} ; NEW ${newDiall}`,m);
     this.element.value = this.element.value.replace(lastDiall+'',newDiall+'');
    this.objetPhone = m ;
    /*Changed*/
    this.user.phone = this.element.value
    /*Changed*/
  }
  telInputObject(m){
    this.objetPhone = m.s ;
  }
  element : any ;
  currenCode :string =environment.CURREN_CODE_COUNTRY;
  controle(element){
    this.element = element ;
    element.value = element.value.replace(/[^0-9]/gi,'');
    if((element.value || "").substr(0,this.objetPhone.dialCode.length).indexOf(this.objetPhone.dialCode.toString()) == -1){
      if(element.value.length <=  this.objetPhone.dialCode.length  && (element.value || "").substr( this.objetPhone.dialCode.length ).length < 1)
      {
        element.value =  this.objetPhone.dialCode;
      }
      else
      {
        if((element.value || "").substr(0,this.objetPhone.dialCode.length ).indexOf( this.objetPhone.dialCode.toString()) !== -1){
          element.value =  element.value;
        }
        else {
          element.value = this.objetPhone.dialCode +  element.value;
        }
      }
    }
    /*Changed*/
    this.user.phone = element.value;
    /*Changed*/
  }
  /*telephone */


}
