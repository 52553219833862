import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {modules} from "../../../../model/db";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {environment} from "../../../../../../environments/environment";
import * as $ from "jquery";
import {valuesys} from "../../../../../../options";
import {Translatable} from "../../../../../Translatable";
import Swal from "sweetalert2";

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.css']
})
export class ModuleComponent extends Translatable  implements  OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  title:string ;
  dtOptions: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  public modules :modules[] = [] ;
  public module:modules = new modules();
  public module_details: modules;
  public module_error : modules ;
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private httpClient: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService,
              private route: Router
  ) {
    super();
  }

  async ngOnInit() {
    this.auth.initAutority("MDL");
    this.initDatatable()
  }
  initDatatable() {
    this.isAdd = true;
    this.isValid = false;
    this.title = this.__("module.modules");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "code"
      },
      {
        "data": "name"
      }

    ];
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
          .get<DataTablesResponse>(
              environment.module + "?" + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
          ).subscribe(resp => {
        this.modules = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data: []
        });
      });
    }
  }




  async openModal(template:TemplateRef<any>,currenModule:modules =null,elem) {

    this.module_error = new modules()
    elem.disabled = true ;
    if(currenModule == null){
      this.isAdd = true ;
      this.module = new modules() ;
      this.titleModal = this.__('module.add') ;
    }else {

      this.isAdd = false ;
      this.titleModal = this.__('module.edit') ;
      this.module = currenModule ;

    }
    elem.disabled = false ;
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});

  }
  close(){
    this.modalRef.hide()
    this.isValid = false ;
    this.module = new modules()
    this.titleModal = this.__('module.edit') ;
    this.module_error = new modules();
    //this.transactions=[];
  }
  async addUserForm(){
    this.module_error = new modules()
    this.isValid = true ;
    if(this.isAdd ){
      let res:any =  await this.http.post<any>(environment.module,this.module,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        this.modules.push( res.data);
        this.close()
      }else if(res.code === 400){
        for (let item in res.data){
          this.module_error[item] = res.data[item][0] ;
        }
        this.module_error = <modules> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else {
      console.log(this.module);
      let res =  await this.http.put<any>( environment.module + '/'+this.module.id,this.module,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        this.close()
      }else if(res.code === 400){
        this.module_error = <modules> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    this.isValid = false ;


  }
  async openModalDetails(template: TemplateRef<any>,currentmodule:modules =null,elm) {
    elm.disabled = true
    this.titleModal = this.__('label_details') ;
    let res1 = await this.http.get<any>(environment.module + '/' + currentmodule.id ,valuesys.httpAuthOptions()).toPromise();
    this.module_details = res1.data ;
    console.log(this.module_details)
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});
    elm.disabled = false
  }

  async toggleState(module:modules,state:number,btn:any){

    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.module+`/${module.id}/${state}/state`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      module.state = toogle.data.state

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }
  delete(deletedUser:modules,elm){
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.module+"/" + deletedUser.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.modules= this.modules.filter(function (item,index) {
            return item.id != deletedUser.id
          })
          Swal.fire(
              this.__('msg.deleted'),
              sup.msg,
              'success'
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
            this.__('msg.canceled'),
            this.__('msg.canceled_delete'),
            'error'
        )
      }
    })
  }
}
