import * as moment from "moment";
import 'moment/locale/fr-ca';
import { Component, OnInit } from '@angular/core';
import {HelperService} from "./administration/service/helper.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  title = 'Jirama Web App';

  constructor(
    private helper:HelperService
  ) {}

  ngOnInit(): void {
    //console.log = function () {};
  }



}


