import { Component, OnInit } from '@angular/core';
import {AuthServiceService} from "../../../service/auth-service.service";
import {Translatable} from "../../../../Translatable";
import {Auth, profilage_module} from "../../../model/db";

@Component({
  selector: 'app-homelayout',
  templateUrl: './homelayout.component.html',
  styleUrls: ['./homelayout.component.css']
})
export class HomelayoutComponent extends  Translatable  implements OnInit {
  titleHomeApp:string = this.__("label_welcom_phco") ;
  modules:profilage_module []= [] ;
  public user : Auth= new Auth();
  constructor(private auth : AuthServiceService) {super() }
  async ngOnInit() {
    this.user =<Auth> await this.auth.getLoginUser();
      this.modules = this.user.modules;
     this.modules =this.modules.filter(_=> _.hasOneSubModuleAction || this.user.info.admin === 1)
    console.log('aaazaa',this.modules)



  }


}
