import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {document, site} from "../../../../model/db";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {environment} from "../../../../../../environments/environment";
import * as $ from "jquery";
import {valuesys} from "../../../../../../options";
import {Translatable} from "../../../../../Translatable";
import Swal from "sweetalert2";

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.css']
})
export class SiteComponent extends Translatable   implements OnInit {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  title:string ;
  dtOptions: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  public sites :site[] = [] ;
  public site_ :site = new site();
  public site_details: site;
  public site_error : site ;
  documents: any[] = [];
  currentSignature: string;

  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private httpClient: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService,
              private route: Router
  ) {
    super();
  }

  async ngOnInit() {
    this.auth.initAutority("SIT");
    this.initDatatable()
  }

  initDatatable() {
    this.isAdd = true;
    this.isValid = false;
    this.title = this.__("site.sites");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "code"
      },
      {
        "data": "label"
      }

    ];
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
          .get<DataTablesResponse>(
              environment.site + "?" + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
          ).subscribe(resp => {
        this.sites = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data: []
        });
      });
    }
  }

  async openModal(template:TemplateRef<any>,currenModule:site =null,elem) {
    this.documents = [];
    console.log(currenModule,'sitttteeeee')
    // this.site_.signature = currenModule.signature;

    this.site_error = new site()
    elem.disabled = true ;
    if(currenModule == null){
      this.isAdd = true ;
      this.site_ = new site();
      this.titleModal = this.__('site.add') ;
    }else {

      this.isAdd = false ;
      this.titleModal = this.__('site.edit') ;
      this.site_ = currenModule ;
      if(currenModule.signature){
        this.documents = [{name: currenModule.signature}];
      }else{
        this.documents = []
      }
      console.log(this.documents,"eduuutt")


    }
    elem.disabled = false ;
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});

  }

  close(){
    this.modalRef.hide()
    this.isValid = false ;
    this.site_ = new site();
    this.site_error = new site();
    this.site_.signature = null;
  }

  async toggleState(site:site,state:number,btn:any){

    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.site+`/${site.id}/${state}/state`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      site.state = toogle.data.state

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }

  delete(deletedUser:site,elm){
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.site+"/" + deletedUser.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 205){
          this.sites= this.sites.filter(function (item,index) {
            return item.id != deletedUser.id
          })
          Swal.fire(
              this.__('msg.deleted'),
              sup.msg,
              'success'
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
            this.__('msg.canceled'),
            this.__('msg.canceled_delete'),
            'error'
        )
      }
    })
  }

  async addUserForm(){
    this.site_error = new site()
    // if(this.documents.length == 0){
    //   this.toastr.error("La signature est obligatoire",this.__("msg.error")) ;
    //   return;
    // }
    if(this.documents.length == 0){
      this.site_.signature = null;
    }else{
      this.site_.signature = this.documents[0].name;
    }
   
    this.isValid = true ;
    if(this.isAdd ){
      let res:any =  await this.http.post<any>(environment.site,this.site_,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        this.sites.push( res.data);
        this.close()
      }else if(res.code === 400){
        for (let item in res.data){
          this.site_error[item] = res.data[item][0] ;
        }
        this.site_error = <site> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else {
      let res =  await this.http.put<any>( environment.site + '/'+this.site_.id,this.site_,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        this.close()
      }else if(res.code === 400){
        this.site_error = <site> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    this.isValid = false ;


  }

  getsignature(e: any):void{
    console.log(e.target.files[0],'fileeee')
  }

  // signature
  addImage(documentFile: HTMLInputElement) {
    documentFile.click()
  }

  async deleteImg(document: document, img: HTMLElement) {
    img.classList.add('d-none')
    this.documents = [];
    // if (document.id) {
    //   // let {code,msg} = await this.http.delete<HttpApiResponse>(environment.document + "/" + document.id ,constante.headers()).toPromise();
    //   // this.toastr.success(msg,this.__('label.success'))
    //   this.documents = this.documents.filter(d => d !== document);
    // } else {
    //   this.documents = this.documents.filter(d => d !== document);
    // }
    img.classList.remove('d-none')

  }

  handleInputChange(e) {
    var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    if (!files) {
      return;
    }
    for (var i = 0; i < files.length; i++) {
      if (files[i].size > 1000*1000 * 100) {
        this.toastr.error("taille image trop grande", "Error");
        $("#document").val("");
        return;
      }
      var reader = new FileReader();
      var pattern = /image-*/;

      if (!files[i].type.match(pattern)) {
        this.toastr.error("invalid format", "Error");
        return;
      }
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(files[i]);
    }
  }

  _handleReaderLoaded(e) {
    let reader = e.target;
    let d = new document();
    d.name = reader.result
    if (this.documents.length < 5)
      this.documents.push(d)
    console.log(this.documents)
    //this.partenaire.logo = reader.result;
  }

  displayImageLarge(template: any,image){
  
    this.currentSignature = image;
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});
  }

}
