import {Component, OnInit, TemplateRef} from '@angular/core';
import {type_processus} from "../../../../model/db";
import {ActivatedRoute} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {valuesys} from "../../../../../../options";
import Swal from 'sweetalert2' ;
import {Translatable} from "../../../../../Translatable";
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {environment} from "../../../../../../environments/environment";
import * as $ from "jquery";
@Component({
  selector: 'app-type-processus',
  templateUrl: './type-processus.component.html',
  styleUrls: ['./type-processus.component.css']
})
export class TypeProcessusComponent extends Translatable implements OnInit {

  title:string ;
  dtOptions: DataTables.Settings = {};

  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  modalRef: BsModalRef;

  public types_processus:type_processus [] = [];
  public type_processus:type_processus  = new type_processus();
  type_processus_error: type_processus;
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService
  ) { 
    super();
  }

  async ngOnInit() {
    this.auth.initAutority("TCL");

    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__("lebel_users");
    this.initDatatable()
    //console.log(" liste des droits "+this.listeModules);


  }

  initDatatable(){
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {data:"code"},
      {data:"name"}
      //{data:"state"}
    ]  ;
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
          .get<DataTablesResponse>(
              environment.type_processus +"?"+  $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
          ).subscribe(resp => {
        this.types_processus = resp.data;
        console.log(resp)
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    }
  }

  async addUserForm(){
    this.isValid = true ;
    if(this.isAdd ){
      console.log(this.type_processus);
      let res =  await this.http.post<any>( environment.type_processus,this.type_processus,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        //this.rerender();
        this.types_processus.push(res.data);
        this.close()
      }else if(res.code === 400){
        this.type_processus_error = <type_processus> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else {

      console.log("FAL PROF",this.type_processus);
      let res =  await this.http.put<any>( environment.type_processus + '/' + this.type_processus.id,this.type_processus,valuesys.httpAuthOptions()).toPromise();

      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;

        this.close()
      }else if(res.code === 400){
        this.type_processus_error = <type_processus> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    this.isValid = false ;

  }

  async openModal(template: TemplateRef<any>,currenttype_processus:type_processus =null,btn:any) {
    btn.disabled= true
    this.type_processus_error = new type_processus();
    if(currenttype_processus == null){
      this.type_processus = new type_processus();
      this.isAdd = true ;
      this.titleModal = this.__('type_processus.add') ;
    }else {
      this.isAdd = false ;
      this.titleModal = this.__('type_processus.edit') ;
      let res = await this.http.get<any>(environment.type_processus + '/' + currenttype_processus.id,valuesys.httpAuthOptions()).toPromise();
      this.type_processus = currenttype_processus ;

    }
    btn.disabled= false

    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }

  close(){
    this.type_processus_error = new type_processus();
    this.type_processus = new type_processus();
    this.titleModal = "" ;
    this.modalRef.hide()
  }

  delete(deletetype_processus:type_processus,btn:any){
    btn.disabled= true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.type_processus+"/"+deletetype_processus.id,valuesys.httpAuthOptions()).toPromise();
        if(sup.code == 204){
          this.types_processus= this.types_processus.filter(function (item,index) {
            return item.id != deletetype_processus.id
          });
          btn.disabled= false
          Swal.fire(
              this.__('msg.deleted'),
              sup.msg,
              'success'
          )
        }else {
          btn.disabled= false
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
            this.__('msg.canceled'),
            this.__('msg.canceled_delete'),
            'error'
        )
        btn.disabled= false
      }
    })
  }





  async toggleState(type:type_processus,state:number,btn:any){
  //  console.log(btn.hidden = true)
    $(btn).attr('disabled');
    btn.disabled = true;
    let toogle = await this.http.get<any>( environment.type_piece+`/${type.id}/${state}/state`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      type.state = toogle.data.state

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }


}
