import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import {delay, mergeMap, materialize, dematerialize, tap} from 'rxjs/operators';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {AuthServiceService} from "./auth-service.service";
import {environment} from "../../../environments/environment";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private toast:ToastrService,private router: Router,private auth: AuthServiceService) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request;

    // wrap in delayed observable to simulate server api call

    return of(null)
      .pipe(mergeMap(handleRoute))
      .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
      .pipe(delay(500))
      .pipe(dematerialize())
      .pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        let uri = window.location.href.split('/').reverse()[0];
        if(event.body.code == 401  &&  uri !== 'login'&&  uri !== 'logout'){
          this.toast.warning(event.body.msg  || "Veuillez vous reconnecter votre session est exprirer","Erreur");
         return  this.router.navigate(['logout']);
        }else if(event.body.code == 503   ){
          this.toast.warning((event.body.msg ),"Erreur");
         // return  this.router.navigate(['/app']);
        }
        if(event.status !== 200){
          this.toast.warning("Une erreur interne s'est produite","Erreur");
        }

        if(uri.indexOf('auth/refresh') ==-1 && url.indexOf(environment.baseUrl) !== -1  &&  uri !== 'login'&&  uri !== 'logout'){
          this.auth.refreshToken().then(r => console.log());
        }

      }


      return event

    }))



    function handleRoute() {
      return next.handle(request);
    }
  }
}
