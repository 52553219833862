/* tslint:disable */
//schemats generate -c mysql://root:root@crud-phco.dev.local/phco_preprod -o db.ts

/**
 * AUTO-GENERATED FILE @ 2020-03-24 01:44:16 - DO NOT EDIT!
 *
 * This file was automatically generated by schemats v.3.0.3
 * $ schemats generate -c mysql://username:password@localhost/jirama_db -t abonnement -t action -t action_profil -t agence -t client -t compteur -t document -t facture -t incident -t module -t partenaire -t plateforme -t profil -t reclamation -t service -t sous_module -t token -t transaction -t type_action -t type_client -t type_compteur -t user -s jirama_db
 *
 */


export class abonnement {
  id: number ;
  date_abonnement: string | null ;
  num_compteur: string | null ;
  statut: number | null ;
  date_resiliation: Date | null ;
  client_id: number ;
  type_abonnement_id: number ;
  state: number | null ;
  client : client | null ;
  factures : facture [] | null | [];
  reclamations : transaction [] | null | [];
  transactions : transaction [] | null | [];
  typeAbonnement: type_abonnement | null ;
  compte_ecaissement : string| null;
  address : string| null;
  nom : string| null;
  prenom : string| null;

}


export class build_mobile {
  id: number ;
  label: string ;
  state: number ;
  version: string ;
  date_deploiement : string | null;
}


export class action {
  id: number ;
  name: string ;
  code: string ;
  method: string ;
  url: string ;
  state: number | null ;
  type_action_id: number ;
  sous_module_id: number ;
  sousModule : sous_module | null;
  typeAction : type_action | null;
  actionProfils : action_profil [] | null | [];

}



export class action_profil {
  id: number ;
  profil_id: number ;
  action_id: number ;
  state: number | null ;
  action: action | null ;
  profil: profil | null ;

}



export class agence {
  id: number ;
  name: string | null ;
  adresse: string | null ;
  latitude: number | null ;
  longitude: number | null ;
  state: number | null ;


}



export class client {
  id: number ;
  prenom: string | null ;
  nom: string | null ;
  telephone: string | null ;
  email: string | null ;
  password: string | null ;
  provenance: number ;
  statut: number | null ;
  type_client_id: number ;
  state: number | null ;
  typeClient: type_client | null ;
  abonnements: abonnement[] | null |[];
  incidents: incident[] | null |[];
  reclamations: reclamation[] | null |[];
  transactions: transaction[] | null |[];
  cni : string | null;
  num_client : string | null;
  address : string | null;
  libelle_societe : string | null;
  type_piece_id:number|null;
}
export class client_demande_modif {
  id: number ;
  prenom: string | null ;
  abonnement : abonnement | null;
  nom: string | null ;
  telephone: string | null ;
  email: string | null ;
  num_client: string | null ;
  statut: number | null ;
  modifiable: number | null ;
  client_id: number ;
  state: number | null ;
  client: client | null ;
  address : string | null;
  motif : string | null;
  cin: string | null ;
  date_delivrance: string | null ;
  lieu_delivrance: string | null ;

}



/*
export class compteur {
  id: number ;
  num_compteur: string ;
  type_compteur_id: number ;
  state: number | null ;
  typeCompteur: type_compteur | null ;
  abonnements: abonnement [] | null |[];

}
*/



export class document {
  id: number ;
  name: string | null ;
  incident_id: number ;
  state: number | null ;
  incident: incident | null ;
  reclamation: reclamation | null ;

}



export class facture {
  id: number ;
  num_facture: string ;
  numero_transaction: string ;
  periode: string ;
  montant: number ;
  statut: number ;
  date_edition: Date ;
  date_paiement: Date | null ;
  abonnement_id: number ;
  state: number | null ;
  consommation: number ;
  abonnement: abonnement ;

}



export class incident {
  id: number ;
  name: string | null ;
  description: string | null ;
  latitude: string | null ;
  longitude: string | null ;
  statut: number | null ;
  date_start: Date | null ;
  user_start_id: number ;
  user_valid_id: number ;
  date_validation: Date | null ;
  date_incident: Date ;
  client_id: number ;
  state: number | null ;
  client: client | null ;
  userStart: user | null ;
  userValid: user | null ;
  documents: document [] | null ;
  reperage:string | null ;
  num_dossier: string | null ;
  ville: string | null ;
  quartier: string | null ;
  conversation_message: any[];
  nbr_message_unread: any;
}



export class modules {
  id: number ;
  name: string ;
  code: string ;
  icon: string | null ;
  state: number | null ;
  sousModules: sous_module [] | null ;

}



export class partenaire {
  id: number ;
  name: string | null ;
  pourcentage_commission: number | null ;
  logo: string | null ;
  url: string | null ;
  num_caisse: string | null ;
  state: number | null ;
  taux_de_base: number | null ;
  transactions: transaction [] | null |[];

}



export class plateforme {
  id: number ;
  name: string ;
  code: string ;
  description: string | null ;
  state: number | null ;
  users: user [] | null | [] ;

}



export class profil {
  id: number ;
  name: string ;
  code: string ;
  state: number | null ;
  actionProfils: action_profil | null | [] ;
  users: user | null | [] ;

}



export class reclamation {
  id: number ;
  name: string | null ;
  num_dossier: string | null ;
  description: string | null ;
  statut: number | null ;
  date_start: Date | null ;
  user_start_id: number ;
  date_reclammation: Date | null ;
  date_validation: Date | null ;
  user_valid_id: number ;
  client_id: number ;
  state: number | null ;
  client: client | null ;
  userStart: user | null ;
  userValid: user | null ;
  abonnement: abonnement | null ;
  type_reclamation_id:number| null;
  typeReclamation: type_reclamation | null;
  date_depagane : Date ;
  abonnement_id:number;
  reperage : string ;
  latitude : string ;
  longitude : string ;
  documents: document [] | null ;
  code_probleme : string ;
  conversation_message: any[];
  nbr_message_unread: any;

}
export class type_reclamation {
  id: number ;
  name: string | null ;
  state: number | null ;
  parent: string | null ;
  pere: number | 0 ;
  code: string | null ;
  type_reclamation_id: number | null ;
  reclamations: reclamation [] | []
}

export class PushNotification {
  body?: string;
  icon?: string;
  tag?: string;
  renotify?: boolean;
  silent?: boolean;
  sound?: string;
  noscreen?: boolean;
  sticky?: boolean;
  dir?: "auto" | "ltr" | "rtl";
  lang?: string;
  vibrate?: number[];
}



export class service {
  id: number ;
  name: string | null ;
  commission: string | null ;
  state: number | null ;
  transactions: transaction | null ;

}



export class sous_module {
  id: number ;
  name: string ;
  code: string ;
  icon: string | null ;
  state: number | null ;
  module_id: number ;
  module: modules | null ;
  actions: action [] | null | [];

}



export class token {
  id: number ;
  type: string ;
  token: string ;
  refresh_token: string | null ;
  user_id: number ;
  state: number | null ;
  user: user | null ;

}



export class transaction {
  id: number ;
  num_transaction: string ;
  date_transaction: Date ;
  date_annulation: Date | null ;
  date_validation: Date | null ;
  montant: number ;
  frais: number ;
  statut: number | null ;
  partenaire_id: number ;
  service_id: number ;
  user_valid_id: number | null ;
  client_id: number ;
  state: number | null ;
  client: client | null ;
  partenaire: partenaire | null ;
  service: service | null ;
  userValid: user | null ;
  userAnnule: user | null ;
  abonnement: abonnement | null ;
  num_facture: string | null ;
  num_ticket: string | null ;
}

export class transactionFail {
    object : {
      num_transaction: string ;
      date_transaction: Date ;
      installation : string;
      montant: number ;
      frais: number ;
      statut: number | null ;
      partenaire_id: number ;
      service_name: string ;
      service_id: number ;
      partenaire_name : string;
    };
    status_partenaire : string
}



export class type_action {
  id: number ;
  name: string ;
  code: string ;
  state: number | null ;
  actions: action [] | null | [];

}



export class type_client {
  id: number ;
  name: string ;
  state: number | null ;
  clients: client[] | null | [];

}
export class tarif {
  id: number ;
  value: number ;
  state: number | null ;
  label: string;
  activite: string;
  limits_T1: limit_T1[] | null | [];
  puT1_redevances: puT1_redevance[] | null | [];
}
export class limit_T1 {
  id: number ;
  value: number ;
  state: number | null ;
  tarif_id: number;
  tarif: tarif | null ;
  value_T1: number;
  value_T2: number;
  value_T3: number;
  value_T4: number;


}
export class consT1T2 {
  id: number ;
  consT1: number ;
  consT2:number;
  limit_T1_id:number;
  state: number | null ;
  limit_T1: limit_T1 | null ;

}
/*
export class consommation {
  id: number ;
  value: number ;

}
export class puT1_redevance {
  id: number ;
  puT1: number ;
  puT2:number;
  primeFixe:number;
  redevance:number;
  zone_tarifaire_id: number;
  tarif_id: number;
  state: any;
}

*/
export class type_piece {
  id: number ;
  name: string ;
  state: number | null ;
  clients: client[] | null | [];

}


export class type_abonnement {
  id: number ;
  name: string | null ;
  state: number | null ;
  abonnements: abonnement | null | [] ;

}
export class user {
  id: number ;
  admin: number | null ;
  l_name: string ;
  f_name: string ;
  email: string ;
  address: string | null ;
  phone: string ;
  password: string | null ;
  state: number | null ;
  last_connection: Date | null ;
  first_connexion: number | null ;
  avatar: string | null ;
  profil_id: number ;
  plateforme_id: number ;
  profil: profil | null ;
  plateforme: plateforme | null ;
  tokens: token [] | null|[] ;

}

export class profilage_module {
  id : number;
  sous_modules : profilage_sous_module[] = [];
  name : string;
  icon : string;
  code : string;
  state : number | null;
  hasOneSubModuleAction:boolean
}

export class profilage_sous_module{
  id : number;
  actions : profilage_droit[] = [];
  name : string;
  icon : string;
  code : string;
  state : number | null;
  hasOneAction:boolean
}

export class profilage_droit{
  id : number;
  state : number;
  name : string;
  exist : number;
  code : string;
  type_action_id:number;
  url:string;
}

export class Auth {
  info : user|null;
  modules: profilage_module[] | []
}
export class MapPoint {
  texto : string = 'Wenceslau Braz - Cuidado com as cargas';
  lat: number = -23.8779431;
  lng: number = -49.8046873;
  zoom: number = 15;
}
//Classe client jirama

export class jirama_client {
  Id : number | null;
  Nom : string | null;
  Adresse : string | null ;
}

export class jirama_installation {
  InstallationId : string | null;
  CompteEnca : string | null;
  AdresseInstallation : string | null ;
}

export class jirama_facture {
  FactureNumero : string | null;
  RefClient : string | null;
  Nom : string | null ;
  Total : string | null ;
  PeriodeFacture : string | null ;
  MontantElec : number | null ;
  MontantEau : number | null ;
  RefEnca : string | null ;
  TotalArrondi : number | null ;
  Echelonne : string | null ;
  TypePayeur : string | null ;
  Paye : string | null ;
  Categorie : string | null ;
  Numticket : number | null ;
  Numcaisse : string | null ;
  DatePaie : string | null ;
  MontantPaye : number | null ;
}
export class jirama_index {
  Installation : string | null;
  Compteur : string | null;
  Periode : string | null;
  Consommation : number | null;
}
export class jirama_payment {
  numSession : string | null;
  factureNumero : string | null;
  datePaie : string | null;
  telClient : string | null;
  montant : number | null;
  telJirama : string | null;
}
export class notification {
  id:number | null;
  name:string | null;
  description:string | null;
  created_at:string | null;
  update_at:string | null;
  state:number | null;
  statut:number | null;
  reclamation_id:string | null;
  incident_id:string | null;
  user_id:string | null;
  user:user | null;
  reclamation:reclamation | null;
  incident:incident | null;
  plateforme_id:number| null;
  plateforme:plateforme| null;
}

export class zone_tarifaire {
  id:number | null;
  value:string | null;
  activite: string;
  state:number | null;
}
export class puT1_redevance {
  id:number | null;
  PUT1:number | null;
  PUT2:number | null;
  PUT3:number | null;
  PUT4:number | null;
  primeFixe:number | null;
  redevance:number | null;
  zone_tarifaire_id:number | null;
  tarif_id:number | null;
  state:number | null;
  tarif:tarif | null;
  zonetarifaire:zone_tarifaire | null;
  diametre:number | null;
  activite: string;
  diametre_redevence: [];
}


export class puissance {
    id:number | null;
    value:number | null;
    tarif_id:number | null;
    state:number | null;
  tarif:tarif | null;
}
export class branchement {
  id: number ;
  type:number| null;
  nom:string;
  cin:string | null;
  num_demande:string;
  statut:number| null ;
  date_delivrance:string;
  date_start:Date;
  user_start_id:number| null ;
  date_validation:Date;
  user_valid_id:number| null ;
  telephone:string;
  email:string;
  nom_conjoint:string| null;
  cin_conjoint:string| null;
  date_delivrance_conjoint:string| null;
  adresse_actuel: string;
  adresse_local: string;
  commune_id:number;
  commune:commune | null;
  quartier:string;
  activite:string;
  tension: number;
  documents: document [] | null ;
  date_demande: Date | null;
  document_code: string | null;

}

export class commune {
  state : number| null;
  libelle:string ;
}

export class site {
  id:number | null;
  state : number| null;
  label:string ;
  code:string ;
  signature: any
}

export class distance {
  id: number ;
  value: string ;
  state: number | null ;
}


export class longueur {
  id: number ;
  value: string ;
  state: number | null ;
}

export class type_branchement {
  id: number ;
  label: string ;
  state: number | null ;
}


export class base_prix {
  id: number ;
  fix: number ;
  pu_cable: number ;
  pu_surfacade: number ;
  state: number | null ;
  type_branchement_id: number | null ;

}
export class type_processus {
  id: number ;
  code: string ;
  name: string ;
  state: number | null ;
}

export class demande_processus{
  id: number ;
  num_dossier: string | null ;
  nom: string | null ;
  cin: string | null ;
  date_delivrance: string | null ;
  date_demande: string | null ;
  lieu_delivrance: string | null ;
  telephone: string | null ;
  email: string | null ;
  installation: string | null ;
  type_processus_id: string | null ;
  proprio_locataire: number | null ;
  commune: number ;
  site: client | null ;
  client_id: client | null ;
  profession: client | null ;
  statut: number | null ;
  state: number | null ;
  motif: string | null ;
  type_processus: type_processus | null ;
  client: client | null;
  document: string | null;
  commentaire: string | null;
  document_code: string | null;
 
}
export class TypeDocument{
  code: number | undefined;
  description: string | undefined;
  state?: number | undefined ;
  id: number;
}



/*
export class consT1T2 {
  id:number | null;
  consT1:number | null;
  consT2:number | null;
  limit_T1_id:number | null;
  consommation_id:number | null;
  state:number | null;
}
export class limit_T1 {
  id:number | null;
  value:string | null;
  tarif_id:number | null;
  state:number | null;
}
export class tarif {
    id:number | null;
    value:number | null;
    state:number | null;
}*/
