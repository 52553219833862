import { Component, OnInit } from '@angular/core';
import { AuthServiceService } from '../../../../service/auth-service.service';
import { Translatable } from '../../../../../Translatable';
import { reclamation, client } from '../../../../model/db';
import { environment } from 'src/environments/environment';
import { valuesys } from 'src/options';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent extends Translatable implements OnInit {
  existUnreadMessage = false;
  sidebarConversation: any = [];
  messages: any = [];
  showNotifMessageUnread = true;
  showInputMessage = false;
  message: string;
  documents:any;
  showTextarea = true;
  userConected: any;
  client: any
  reclamation: any;
  userConnected: any;


  constructor(private user: AuthServiceService,private http: HttpClient) { super()}

  ngOnInit() {
    this.userConnected =  this.user.account().info;    
    this.reclamation = history.state.reclamation;
    console.log(this.reclamation,'reclmation')
    this.messages = history.state.reclamation.conversation_message[0].message;
    console.log(this.reclamation.client.nom)

  }

 

  /*lire le message du sidebar*/
  readMessage(id, message: any, conversation: any) {
    // this.showInputMessage = true;
    // console.log(conversation)
    // this.message.conversation_id = id;
    // this.message.jirama_id = conversation.acteur_jirama;

    // const messageModified = message.map((mess: any) => mess.statut == 0 ? {...mess, statut: 1} : mess);
    // const sidebarconverstationModified = this.sidebarConversation.map((sideMess: any) => sideMess.id == id ? {
    //   ...sideMess,
    //   message: messageModified
    // } : sideMess);
    // this.sidebarConversation = sidebarconverstationModified;
    // this.showNotifMessageUnread = false;
    const messageSelected: any = this.sidebarConversation.filter((mess: any) => mess.id == id);
    $('#messages').animate({scrollTop: $('#messages').prop('scrollHeight') },500);
    this.messages = messageSelected[0].message;
    // if (this.existUnreadMessage) {
    //   this.http.put(`${environment.read_message}/${id}/lireMessageJirama`, {}, constante.headers()).subscribe(
    //     res => console.log(res)
    //   );
    // }
  }

  async sendMessage(messageEl) {

    const dataToSend = {
        conversation_id: this.reclamation.conversation_message[0].id,
        jirama_id: this.userConnected.id,
        client_id: this.reclamation.client_id,
        message: messageEl.value,
        type_message: "0"
    }
   

    console.log(dataToSend,'data to send')
 
    const response_message:any = await this.http.post(environment.message, dataToSend,valuesys.httpAuthOptions()).toPromise();

    if((response_message.code == 201) && (response_message.error == false)){
      messageEl.value = '';

      $('#messages').animate({scrollTop: $('#messages').prop('scrollHeight') },500);

      // this.message.message = "";
      // this.documents = [];
      // this.showTextarea = true;
    }else{
      // this.toastr.error("Une erreur est survenue", "Error");
      console.log('eerreur')

    }

    this.messages = [...this.messages,response_message.data];
    console.log('response', response_message);
  }




  addImage(documentFile: HTMLInputElement) {
    documentFile.click()
  }

  async deleteImg(document: any, img: HTMLElement) {
    // this.showTextarea = true;
    // img.classList.add('d-none')
    // if (document.id) {
    //   let {code,msg} = await this.http.delete<HttpApiResponse>(environment.document + "/" + document.id ,constante.headers()).toPromise();
    //   this.toastr.success(msg,this.__('label.success'))
    //   this.documents = this.documents.filter(d => d !== document);
    // } else {
    //   this.documents = this.documents.filter(d => d !== document);
    // }
    // img.classList.remove('d-none')
    // console.log(this.documents);
  }

  handleInputChange(e) {
    // var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    // if (!files) {
    //   return;
    // }
    // for (var i = 0; i < files.length; i++) {
    //   if (files[i].size > 1000*1000 * 100) {
    //     this.toastr.error("taille image trop grande", "Error");
    //     $("#document").val("");
    //     return;
    //   }
    //   var reader = new FileReader();
    //   var pattern = /image-*/;

    //   if (!files[i].type.match(pattern)) {
    //     this.toastr.error("invalid format", "Error");
    //     return;
    //   }
    //   reader.onload = this._handleReaderLoaded.bind(this);
    //   reader.readAsDataURL(files[i]);
    // }
  }

  _handleReaderLoaded(e) {
    // this.showTextarea = false;
    // let reader = e.target;
    // let d = new document();
    // d.name = reader.result
    // if (this.documents.length < 2)
    //   this.documents.push(d)

    // console.log(this.documents)
    // //this.partenaire.logo = reader.result;
  }

 
  

}
