import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Translatable} from "../../../../../../Translatable";
import {DataTableDirective} from "angular-datatables";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../../../model/DataTablesResponse";
import {environment} from "../../../../../../../environments/environment";
import * as $ from "jquery";
import {valuesys} from "../../../../../../../options";
import Swal from "sweetalert2";
import {plateforme} from "../../../../../model/db";

@Component({
  selector: 'app-plateforme',
  templateUrl: './plateforme.component.html',
  styleUrls: ['./plateforme.component.css']
})
export class PlateformeComponent extends  Translatable  implements  OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  title:string ;
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  public plateformes : plateforme[] = [] ;
  public plateforme:plateforme = new plateforme();
  public plateforme_error : plateforme ;
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private httpClient: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService,
              private route: Router
  ) {
    super();
  }

  async ngOnInit() {
    this.auth.initAutority("PRM");
    this.initDatatable()
  }
  initDatatable() {
    this.isAdd = true;
    this.isValid = false;
    this.title = this.__("plateforme.plateformes");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "name"
      },
      {
        "data": "code"
      },
      {
        "data": "description"
      }
    ];
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
          .get<DataTablesResponse>(
              environment.plateforme + "?" + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
          ).subscribe(resp => {
        this.plateformes = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data: []
        });
      });
    }
  }


  async openModal(template:TemplateRef<any>,currenService:plateforme =null,elem) {

    this.plateforme_error = new plateforme()
    elem.disabled = true ;
    if(currenService == null){
      this.isAdd = true ;
      this.plateforme = new plateforme() ;
      this.titleModal = this.__('plateforme.add') ;
    }else {

      this.isAdd = false ;
      this.titleModal = this.__('plateforme.edit') ;
      this.plateforme = currenService ;

    }
    elem.disabled = false ;
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});

  }
  close(){
    this.modalRef.hide()
    this.isValid = false ;
    this.plateforme = new plateforme()
    this.titleModal = this.__('plateforme.edit') ;
    this.plateforme_error = new plateforme();
  }
  async addUserForm(){
    this.plateforme_error = new plateforme()
    this.isValid = true ;
    if(this.isAdd ){
      let res:any =  await this.http.post<any>(environment.plateforme,this.plateforme,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        this.plateformes.push( res.data);
        this.close()
      }else if(res.code === 400){
        for (let item in res.data){
          this.plateforme_error[item] = res.data[item][0] ;
        }
        this.plateforme_error = <plateforme> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else {
      console.log(this.plateforme);
      let res =  await this.http.put<any>( environment.plateforme + '/'+this.plateforme.id,this.plateforme,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        this.close()
      }else if(res.code === 400){
        this.plateforme_error = <plateforme> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    this.isValid = false ;


  }


  async toggleState(plateforme:plateforme,state:number,btn:any){

    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.plateforme+`/${plateforme.id}/${state}/state`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      plateforme.state = toogle.data.state

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }
  delete(deletedUser:plateforme,elm){
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.plateforme+"/" + deletedUser.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.plateformes= this.plateformes.filter(function (item,index) {
            return item.id != deletedUser.id
          })
          Swal.fire(
              this.__('msg.deleted'),
              sup.msg,
              'success'
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
            this.__('msg.canceled'),
            this.__('msg.canceled_delete'),
            'error'
        )
      }
    })
  }
}
