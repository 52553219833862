import { Component, OnInit } from '@angular/core';
import {Auth, profilage_module} from "../../../model/db";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../service/auth-service.service";
import {Translatable} from "../../../../Translatable";

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent extends Translatable implements OnInit {
  module:profilage_module  = new profilage_module();
  public user : Auth = new Auth();
  constructor(private activatedRoute: ActivatedRoute,private router:Router, private auth : AuthServiceService) {
    super()
    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
  async ngOnInit() {
    //this.module = this.activatedRoute.snapshot.params['module'];
    this.activatedRoute.params.subscribe(async _ => {
      let moduleCode = _.moduleCode;
      window['module'] = moduleCode;
      this.user = <Auth> await this.auth.getLoginUser();
      if(this.user){
        this.module = this.user.modules.find((module : profilage_module) => module.code === moduleCode) ;
        if(!this.module){
          alert('no find module')
        }
      }

    });


  }

}
