import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AuthServiceService} from "../../../service/auth-service.service";
import {Translatable} from "../../../../Translatable";
import {HelperService} from "../../../service/helper.service";
import {Auth, notification, profilage_module} from "../../../model/db";
import Swal from "sweetalert2";
import {environment} from "../../../../../environments/environment";
import {valuesys} from "../../../../../options";
import {Router} from "@angular/router";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {HttpClient} from "@angular/common/http";
import {HttpApiResponse} from "../../../model/DataTablesResponse";



@Component({
  selector: 'app-base-app',
  templateUrl: './base-app.component.html',
  styleUrls: ['./base-app.component.css']
})

export class BaseAppComponent extends Translatable implements OnInit {
  public user : Auth = new Auth();
  public imgLang :string ="fr"
  modules:profilage_module[] = [] ;
  basemodule:string = "/app/modules/home/";
  constructor(private modalService: BsModalService,private http:HttpClient,private auth : AuthServiceService,private helper:HelperService,private router:Router,private loader: NgxUiLoaderService) {
    super();

  }
  /*NOTIFICATION*/
  notifications : notification [] = []
  notification : notification =  new notification()
  nbNotification:number = 0 ;
  modalRef: BsModalRef;
  titleModal:string ;
  // @ts-ignore
  @ViewChild('detailsModal') detailsModal : TemplateRef<any>
  openDetailsModal(cureent:notification){
    this.notification = cureent ;
    this.titleModal = this.__('notification.details') ;
    this.modalRef = this.modalService.show(this.detailsModal,{class:"modal-lg",ignoreBackdropClick:true});
  }
  close(){
    this.notification = new notification();
    console.log(this.modalRef)
    this.modalRef.hide()

  }
  async goto(current:notification,details:boolean=false){
    let code=201;
    if(current.statut !== 1)
    {
      let {code} = await this.http.get<HttpApiResponse>(environment.notification + `/${current.id}/1/statut`,valuesys.httpAuthOptions()).toPromise();
    }
    if(code === 201){
      current.statut = 1 ;
      this.nbNotification= this.notifications.filter(_ => _.statut === 0).length || 0
      if(details){
        this.openDetailsModal(current);
      }else {
        if(current.reclamation_id){
          // await this.router.navigate(["/app/reclamation"],{ queryParams:{id: current.reclamation_id}});
          this.router.navigateByUrl('/app', {skipLocationChange: true})
            .then(()=>this.router.navigate(["/app/dashbord/DEM/demande/reclamation"],{ queryParams:{id: current.reclamation_id}}));
        }
        else if(current.incident_id){
          this.router.navigateByUrl('/app', {skipLocationChange: true})
            .then(()=>this.router.navigate(["/app/dashbord/DEM/demande/incident"],{ queryParams:{id: current.incident_id}}));
          // await this.router.navigate(["/app/incident"],{ queryParams:{id: current.incident_id}});
        }else {
          this.openDetailsModal(current);
        }
      }


      // this.nbNotification =   this.nbNotification < 0 ? 0 : this.nbNotification;
    }else {
      alert("Erreur");
    }
  }

  /*NOTIFICATION*/
  async ngOnInit() {
        //this.loader.start();
      console.log("MY LANG",Translatable.getLang());
      this.imgLang =  "assets/i18n/" + Translatable.getLang() + "/icon.jpg"  ;
      this.user = <Auth> await  this.auth.getLoginUser();
      console.log("AUTH",this.user)
      if(this.user){
        this.modules =  this.user.modules;
        this.modules= this.modules.filter((_)=> _.hasOneSubModuleAction || this.user.info.admin == 1);
      }else {
       // alert('USER IS UNDEFINE')
      }

    let {data} =   await this.http.get<HttpApiResponse>(environment.notification,valuesys.httpAuthOptions()).toPromise();
    this.notifications = data ;
    this.nbNotification = this.notifications.filter(_ => _.statut === 0).length || 0 ;

  }

  changeLang(lang:string = 'fr'){
    localStorage.setItem('lang', lang);
    window.location.reload();
  }
  logout(btn){
    btn.disabled = true;
    Swal.fire({
      title: this.__('swal.confirmTitle'),
      text: this.__('swal.confirmeLogOut'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.__('swal.no'),
      confirmButtonText: this.__('swal.yes')
    }).then(async (result) => {
      if (result.value) {
      //return  await this.router.navigate(['/logout'])
      return  window.location.assign('/logout');
      }
      btn.disabled = false;
    })
  }
}
