import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {branchement, commune, document, MapPoint, reclamation} from "../../../../model/db";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {Location} from "@angular/common";
import {environment} from "../../../../../../environments/environment";
import {valuesys} from "../../../../../../options";
import {DataTablesResponse, HttpApiResponse} from "../../../../model/DataTablesResponse";
import * as $ from "jquery";
import Swal from "sweetalert2";
import {Translatable} from "../../../../../Translatable";

@Component({
  selector: 'app-branchement',
  templateUrl: './branchement.component.html',
  styleUrls: ['./branchement.component.css']
})
export class BranchementComponent extends Translatable implements OnInit {
  /*REFRESH*/
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  // @ts-ignore
  dtTrigger: Subject = new Subject();

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  private now = new Date();
  private endTime = " 23:59:59" ;
  date_debut : Date ;
  date_debut_query : string = '';
  date_fin : Date ;
  date_fin_query : string="";
  isSearch: boolean = false;
  public status : string = "8";


  formatQuery(){
    let tempStoreD:any = this.date_debut.setHours(this.date_debut.getHours()+3)
    tempStoreD = new Date(tempStoreD);
    let tempStoreF:any = this.date_fin.setHours(this.date_fin.getHours()+3)
    tempStoreF = new Date(tempStoreF);
    
    this.date_debut_query = this.date_debut.toISOString().replace('T',' ').substr(0,10).split('/').reverse().join('-')
    this.date_fin_query = this.date_fin.toISOString().replace('T',' ').substr(0,10).split('/').reverse().join('-')

    console.log("=== QUERY ===")
    console.log("query date debut: ",this.date_debut_query)
    console.log("query date debut: ",this.date_fin_query)
  }

 
  /*REFRESH*/
  title: string;
  dtOptions: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal: string;
  isAdd: boolean = true;
  isValid: boolean = false;
  public branchements: branchement[] = [];
  public branchement: branchement = new branchement();
  public branchement_detail: branchement;
  public commune:commune;
  public documents: document [];
  libelleCommune: string;

  //public time_depagane = "00:00" ;
  //public mapPoint:MapPoint =  new MapPoint();
  // @ts-ignore
  @ViewChild('showDetails') detailsModal : TemplateRef<any>
 // date_depagane :string = (new Date()).toISOString().substr(0,10);

  constructor(private activatedRoute: ActivatedRoute,
              private auth: AuthServiceService,
              private http: HttpClient,
              private httpClient: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService,
              private route: Router,
              private router: ActivatedRoute,
              private location : Location
  ) {
    super();
  }

  async ngOnInit() {
    this.auth.initAutority("REC");
    this.initDatatable()
    this.date_debut = new Date();
    this.date_fin = this.date_debut;
    this.formatQuery()
    /*NOTIFICATION*/
    let id = +this.router.snapshot.queryParams.id || null ;
    let res:any = await this.httpClient.get( environment.branchement ,valuesys.httpAuthOptions()).toPromise()
   // console.log("res",res)
    this.branchement = res.data || new branchement();
    if(this.branchement.id){
      this.openModalDetails(this.detailsModal,this.branchement,{}).then(_ =>{
        this.location.go('/app/dashbord/DEM/demande/branchement');
      })
    }
    /*NOTIFICATION*/
  }

  initDatatable() {
    this.isAdd = true;
    this.isValid = false;
    this.title = this.__("branchement.branchement");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "num_demande"
      },
      {
        "data": "document_code"
      },
      {
        "data": "date_demande"
      },
      {
        "data": "nom",
      },

      {
        "data": "telephone"
      },
      {
        "data": "email",
      }
      ,

      {data: "state", searchable: false},
      {data: "state", searchable: false},
    ];
    this.dtOptions.order=[[1,'desc']];
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      
      if(this.isSearch == true){
        if (this.date_debut == undefined){
          this.date_debut = this.date_fin
        }
        if (this.date_fin == undefined) {
          this.date_fin = this.date_debut
        }

        let that:any = this;
        new Promise((resolve, reject)=>{
          let query = ""
          if (this.status != "8"){
            query = `,statut|e|${this.status}`
          }
          this.formatQuery()
          this.http
              .get<DataTablesResponse>(
                   environment.branchement + `?child=commune&where=date_demande|se|${this.date_debut_query },date_demande|ie|${this.date_fin_query  + this.endTime}${query}&` + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
              ).subscribe(resp => {
            resolve(resp.data);

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
        }).then((result)=>{
            that.branchements = result;
        });


      }else {

        let that:any =  this;
        new Promise((resolve,reject)=>{
          let query = ""
          if (this.status != "8"){
            query = `&where=statut|e|${this.status}`
          }
           this.http
        .get<DataTablesResponse>(
          environment.branchement + "?child=commune&"+ $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
            resolve(resp.data);
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data: []
        });
      });
      }).then((result)=>{
            that.branchements = result;
            
        })
        
      }


    }
  }

close() {
    this.modalRef.hide()
    this.isValid = false;
    this.message = '';
    this.branchement = new branchement()
    this.documents = [] ;
  }

  async openModalDetails(template: TemplateRef<any>, currentbranchement: branchement = null, elm) {
    console.log("branchemnt",currentbranchement)
    elm.disabled = true
    this.titleModal = this.__('label_details');   
    this.libelleCommune = currentbranchement.quartier.split(" - ")[0];
    this.branchement_detail  = currentbranchement as any;
    this.branchement_detail.quartier = currentbranchement.quartier.split(" - ")[1];
    let {data} = <HttpApiResponse>await this.http.get<any>(environment.document + "?where=branchement_id|e|" + currentbranchement.id, valuesys.httpAuthOptions()).toPromise();
    this.documents = <Array<document>>data;
    console.log(this.branchement_detail)
    //this.mapPoint.lat = +this.reclamation_details.latitude;
    //this.mapPoint.lng = +this.reclamation_details.longitude;
    //this.mapPoint.texto = this.reclamation_details.name;
    this.modalRef = this.modalService.show(template, {class: "modal-md", ignoreBackdropClick: true});
    elm.disabled = false
  }

  async toggleState(branchement: branchement, state: number, btn: any={},msg :  any ='') {

    btn.disabled = true;

    Swal.fire({
      title: this.__('swal.confirmTitle'),
      text: this.__('swal.confirmMsg'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.__('swal.no'),
      confirmButtonText: this.__('swal.yes')
    }).then(async (result) => {
      /*if (result.value) {
        Swal.fire(
          'swal.successTitle',
          'swal.success',
          'success'
        )
      }*/
      if (result.value) {
        let toogle = await this.http.get<any>(environment.branchement + `/${branchement.id}/${state}/statut?msg=${msg}`, valuesys.httpAuthOptions()).toPromise();
        if (toogle.code == 201) {
          this.toastr.success(toogle.msg, this.__("msg.success"));
          branchement.statut = toogle.data.statut;
          this.rerender();

        } else {
          this.toastr.error(toogle.msg, this.__("msg.error"));
        }
        btn.disabled = false;
      }
      btn.disabled = false;
    })


  }

  loadItem(btninfo: HTMLAnchorElement) {
    $(".marker a").removeClass('activeTab')
    btninfo.classList.add('activeTab')
  }

  download(document: document, btn: any) {
    btn.disabled = true
    this.toDataURL(document.name, (dataUrl) => {
      btn.disabled = false
      window['download'](dataUrl, document.name, dataUrl.split(';')[0].split(":")[1]);

      return false

    })
  }


  saveStatutSave(branchement:branchement,state:any,btn:any){
    btn.disabled =true
    // @ts-ignore
    Swal.fire({
      title: this.__('swal.num_demande'),
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        // @ts-ignore
        required:true,
      },
      validationMessage :this.__("validation.required"),
      showCancelButton: true,
      cancelButtonText:  this.__('swal.no'),
      confirmButtonText:  this.__('swal.send'),
      showLoaderOnConfirm: true,
      preConfirm: (num_demande) => {
        this.http.get<any>(environment.branchement + `/${branchement.id}/${state}/statut?num_demande=${num_demande}`, valuesys.httpAuthOptions()).toPromise()
          .then(response => {
            console.log("response",response)
            if (response.code === 201) {
              this.toastr.success(response.msg, this.__("msg.success"));
              branchement.statut = response.data.statut;
              branchement.num_demande = response.data.num_demande;
              this.rerender();
            } if (response.code === 500) {
              this.toastr.warning("Le numéro de demande doit être unique ", this.__("msg.warning"));
            }  else
           {
              this.toastr.error(response.msg, this.__("msg.error"));
            }
          })
          .catch(error => {
            Swal.showValidationMessage(
              `${this.__('msg.error')} : ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    })/*.then((result) => {
      if (result.value) {
        Swal.fire({
          title: `${result.value.login}'s avatar`,
          imageUrl: result.value.avatar_url
        })
      }
    })*/
    btn.disabled =false
  }

  /* async openModalPlanfiDepagage(template: TemplateRef<any>, currentbranchement: branchement = null, elm) {
     elm.disabled = true
     this.branchement_detail = currentbranchement;
     this.initTime()

     this.modalRef = this.modalService.show(template, {class: "modal-md", ignoreBackdropClick: true});
     elm.disabled = false
 }
   /*async planifier(submitPlanification:any){
     submitPlanification.disabled = true
     let date = this.date_depagane.split('/').reverse().join('/') +" "+ this.time_depagane;
     let {data,code,msg} = <HttpApiResponse>await this.http.put<any>(environment.reclamation + "/planif/" + this.reclamation_details.id,{date_depagane:date}, valuesys.httpAuthOptions()).toPromise();
     if(code ==201){
       this.close()
       this.toastr.success(this.__("reclamation.successPlanication"), this.__("msg.success"));
       this.rerender();
     }else {
       this.toastr.error(msg, this.__("msg.error"));
     }
     submitPlanification.disabled = false

   }*/

  planaifBtnTille:string = ''
/*  initTime(){
    if(!this.reclamation_details.id || !this.reclamation_details.date_depagane  ){
      this.planaifBtnTille = this.__('btn.valide');
      this.titleModal = this.__('tooltip.panifDepanageTitleAdd');
      this.time_depagane = "00:00" ;
      this.date_depagane  = (new Date()).toISOString().substr(0,10);
    }else {
      this.planaifBtnTille = this.__('btn.edit');
      this.titleModal = this.__('tooltip.panifDepanageTitleEdit');
      this.time_depagane = this.reclamation_details.date_depagane.toString().substr(11,5) ;
      console.log(this.time_depagane)
      this.date_depagane  = this.reclamation_details.date_depagane.toString().substr(0,10) ;
    }
  }*/
  async openModal(template:TemplateRef<any>,currenbranchement:branchement =null,elem) {
    elem.disabled = true ;
    this.titleModal=this.__('reclamation.infoscom')
    this.branchement=currenbranchement;
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});
    elem.disabled = false ;

  }
  /*async addInfoComplemente() {

    let {code,msg} = <HttpApiResponse> await this.http.put<any>(environment.reclamation+`/infoComp/${this.reclamation.id}`,{message:this.message},valuesys.httpAuthOptions()).toPromise();
    if (code == 200) {
      this.toastr.success(this.__('incident.succes_com'), this.__("msg.success"));

    } else {
      this.toastr.error(msg, this.__("msg.error"));
    }
    this.close()
  }*/
  message:string =""




  async exportExcel(btnExcel) {
    btnExcel.disabled = true
    //if(this.date_debut == undefined && this.date_fin == undefined){
      await this.helper.exportExcel(this.print(this.branchements),this.__("print.list") + this.__("branchement.branchement"));
    /*} /*else {
      await this.helper.exportExcel(this.print(this.client_demande_modifs),this.__("reclamation.reclamation") + this.__("print.from")  + this.date_debut_query.split('-').reverse().join('-') + this.__("print.to") + this.date_fin_query.split('-').reverse().join('-'));
    } */
    btnExcel.disabled = false
  }
  print(branchement:branchement[]){
    return  branchement.map((branchement)=>{
      let t = {}
      t[this.__('branchement.num_demande')] = branchement.num_demande
      t[this.__('branchement.date_demande')] = branchement.date_demande
      t[this.__('branchement.nom')] = branchement.nom
      t[this.__('branchement.telephone')] = branchement.telephone
      t[this.__('branchement.email')] = branchement.email
      t[this.__('print.statut')] =  (branchement.statut == 0 ? this.__("tooltip.running_trated") : (branchement.statut == 1) ? this.__('tooltip.state_save') : (branchement.statut == 2) ? this.__('tooltip.traiter') : this.__('tooltip.state_planif'))
      return t ;
    })  || [];

  }



  changerStatus(event){
    this.status=event;
  }

  search() {
    this.isSearch = true;

    console.log("debut: ",this.date_debut)
    console.log("fin: ",this.date_fin)

    if (this.date_debut || this.date_fin) {
      this.rerender();
    }else {
      this.isSearch = false;
      this.date_debut = undefined
      this.date_fin = undefined
      this.rerender();
    }
  }

   cancel() {
    this.isSearch = false;
    this.date_debut = undefined
    this.date_fin = undefined
    this.status= "8"
    this.rerender();
  }


}
