import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {demande_processus, document, MapPoint} from "../../../../model/db";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse, HttpApiResponse} from "../../../../model/DataTablesResponse";
import {environment} from "../../../../../../environments/environment";
import * as $ from "jquery";
import {valuesys} from "../../../../../../options";
import Swal from "sweetalert2";
import {Translatable} from "../../../../../Translatable";
 
@Component({
  selector: 'app-demande-processus',
  templateUrl: './demande-processus.component.html',
  styleUrls: ['./demande-processus.component.css']
})
export class DemandeProcessusComponent extends Translatable implements OnInit,AfterViewInit,OnDestroy {

 
  /*REFRESH*/
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  // @ts-ignore
  dtTrigger: Subject = new Subject();
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload()
    });
  }

  private now = new Date();
  private endTime = " 23:59:59" ;
  date_debut : Date ;
  date_debut_query : string = '';
  date_fin : Date ;
  date_fin_query : string="";
  isSearch: boolean = false;
  public status : string = "8";


  formatQuery(){
    this.date_debut_query = this.dateFormating(this.date_debut);
    this.date_fin_query = this.dateFormating(this.date_fin);
  }

  dateFormating(date) : string
  {
    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return year+'-' + month + '-'+dt;
  }

  /*REFRESH*/
  title:string ;
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  public documentsClient = [];
  documents : document [] = [];
  public demandes_processus :demande_processus[] = [] ;
  public demande_processus:demande_processus = new demande_processus();
  public demande_processus_details: demande_processus;
  public message : string;
  public mapPoint:MapPoint =  new MapPoint();

  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private httpClient: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService,
              private route: Router
  ) {
    super();
  }

  async ngOnInit() {
    this.auth.initAutority("DMC");
    this.date_debut = new Date();
    this.date_fin = this.date_debut;
    this.formatQuery()
    this.initDatatable()
    
  }
  initDatatable() {
    this.isAdd = true;
    this.isValid = false;
    this.title = this.__("demande_processus.demande_processus");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "num_dossier"
      },
      {
        "data": "document_code"
      },
      {
        "data": "date_demande"
      },
          {
        "data": "nom"
      },
      {
        "data": "installation"
      },
      // {
      //   "data": "cin"
      // },
      {
        "data": "telephone"
      },
      // {
      //   "data": "email"
      // },
      {
        "data": "type_processus",
        "name": "type_processus.name",
      },
      {
        data:"state",
        searchable:false
      },
      {
        "data": "actions",
        searchable:false
      },
    ];
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
    if(this.isSearch == true){
        if (this.date_debut == undefined){
          this.date_debut = this.date_fin
        }
        if (this.date_fin == undefined) {
          this.date_fin = this.date_debut
        }

        let that:any = this;
        new Promise((resolve, reject)=>{
          let query = ""
          if (this.status != "8"){
            query = `,statut|e|${this.status}`
          }
          this.formatQuery()
          this.http
              .get<DataTablesResponse>(
                   environment.demande_processus + `?child=client,type_processus&__order__=desc,date_demande&where=date_demande|se|${this.date_debut_query },date_demande|ie|${this.date_fin_query  + this.endTime}${query}&` + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
              ).subscribe(resp => {
            resolve(resp.data);

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
        }).then((result)=>{
            that.demandes_processus = result;
            console.log(that.demandes_processus ,"result demande de processus")
        });
      }else {

        let that:any =  this;
        new Promise((resolve,reject)=>{
          let query = ""
          if (this.status != "8"){
            query = `&where=statut|e|${this.status}`
          }
           this.http
        .get<DataTablesResponse>(
          environment.demande_processus + "?child=client,type_processus&__order__=desc,date_demande&"+ $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
            resolve(resp.data);
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data: []
        });
      });
      }).then((result)=>{
            that.demandes_processus = result;
            
        }) 
      }

    }
  }

  async openModal(template:TemplateRef<any>,currendemande_processus:demande_processus =null,elem) {
    elem.disabled = true ;
    this.titleModal=this.__('demande_processus.infoscom')
    this.demande_processus=currendemande_processus;
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});
    elem.disabled = false ;

  }

  close(){
    this.modalRef.hide()
    this.isValid = false ;
    this.demande_processus = new demande_processus()
    this.message = '';
    this.documents = [] ;

  }

  async toggleStatut(demande_processus: demande_processus, state: number, btn: any) {
    btn.disabled = true;
    console.log(demande_processus);

    Swal.fire({
      title: this.__('swal.confirmTitle'),
      text: this.__('swal.confirmMsg'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.__('swal.no'),
      confirmButtonText: this.__('swal.yes')
    }).then(async (result) => {
      
      let objectModification = {
       
      };

      if (result.value) {
        this.http.put<any>(environment.demande_processus + `/${demande_processus.id}/${state}/statut`,objectModification, valuesys.httpAuthOptions()).toPromise()
          .then(response => {
            console.log("reponse OK",response)

            if (response.code === 201) {
              console.log("reponse OK",response)
              this.toastr.success(response.msg, this.__("msg.success"));
              demande_processus.statut = response.data.statut;
              //this.rerender();
            } else if (response.code === 500) {
                  this.toastr.warning("Le motif doit être valable ", this.__("msg.warning"));
                } else {
              this.toastr.error(response.msg, this.__("msg.error"));
            }
          })
          .catch(error => {
            Swal.showValidationMessage(
              `${this.__('msg.error')} : ${error}`
            )
          })
        


        btn.disabled = false;
      }
      btn.disabled = false;
    })


  }

  async rejetStatut(demande_processus:demande_processus,state:any,btn:any){
    btn.disabled =true
    // @ts-ignore
    Swal.fire({
      title: this.__('swal.motif'),
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off',
        // @ts-ignore
        required:true,
      },
      validationMessage :this.__("validation.required"),
      showCancelButton: true,
      cancelButtonText:  this.__('swal.no'),
      confirmButtonText:  this.__('tooltip.validate'),
      showLoaderOnConfirm: true,
      preConfirm: (motif) => {
        
        let objectModification = {
          "motif": motif
        };

        this.http.put<any>(environment.demande_processus + `/${demande_processus.id}/${state}/statut`,objectModification, valuesys.httpAuthOptions()).toPromise()
          .then(response => {
            console.log("reponse OK",response)

            if (response.code === 201) {
              console.log("reponse OK",response)
              this.toastr.success(response.msg, this.__("msg.success"));
              demande_processus.statut = response.data.statut;
              //this.rerender();
            } else if (response.code === 500) {
                  this.toastr.warning("Le motif doit être valable ", this.__("msg.warning"));
                } else {
              this.toastr.error(response.msg, this.__("msg.error"));
            }
          })
          .catch(error => {
            Swal.showValidationMessage(
              `${this.__('msg.error')} : ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    })/*.then((result) => {
      if (result.value) {
        Swal.fire({
          title: `${result.value.login}'s avatar`,
          imageUrl: result.value.avatar_url
        })
      }
    })*/
    btn.disabled =false
  }

  async validStatut(demande_processus:demande_processus,state:any,btn:any){
  
    btn.disabled =true
    // @ts-ignore
    Swal.fire({
      title: this.__('swal.num_doss'),
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        // @ts-ignore
        required:true,
      },
      validationMessage :this.__("validation.required"),
      showCancelButton: true,
      cancelButtonText:  this.__('swal.no'),
      confirmButtonText:  this.__('tooltip.validate'),
      showLoaderOnConfirm: true,
      preConfirm: (num_dossier) => {
        
        let objectModification = {
          "num_dossier": num_dossier
        };

        this.http.put<any>(environment.demande_processus + `/${demande_processus.id}/${state}/statut`,objectModification, valuesys.httpAuthOptions()).toPromise()
          .then(response => {
            console.log("reponse OK",response)

            if (response.code === 201) {
              console.log("reponse OK",response)
              this.toastr.success(response.msg, this.__("msg.success"));
              demande_processus.statut = response.data.statut;
              demande_processus.num_dossier = num_dossier;
              //this.rerender();
            } else if (response.code === 500) {
                this.toastr.warning("Le numéro de dossier doit être unique ", this.__("msg.warning"));
            } else {
              this.toastr.error(response.msg, this.__("msg.error"));
            }
          })
          .catch(error => {
            Swal.showValidationMessage(
              `${this.__('msg.error')} : ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    })/*.then((result) => {
      if (result.value) {
        Swal.fire({
          title: `${result.value.login}'s avatar`,
          imageUrl: result.value.avatar_url
        })
      }
    })*/
    btn.disabled =false
  }

  async openModalDetails(template: TemplateRef<any>,currentdemande_processus:demande_processus =null,elm) {
    elm.disabled = true
    this.titleModal = this.__('label_details') ;
    this.demande_processus_details = currentdemande_processus ;
    let {data} = <HttpApiResponse>await this.http.get<any>(environment.document + "?child=demande_processus&where=demande_processus_id|e|" + currentdemande_processus.id, valuesys.httpAuthOptions()).toPromise();
    this.documentsClient = <Array<document>>data;
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});
    elm.disabled = false
  }

  loadItem(btninfo: HTMLAnchorElement) {
    $(".marker a").removeClass('activeTab')
    btninfo.classList.add('activeTab')
  }

  download(document: document, btn: any) {
    btn.disabled = true
    this.toDataURL(document.name, (dataUrl) => {
      btn.disabled = false
      window['download'](dataUrl, document.name, dataUrl.split(';')[0].split(":")[1]);

      return false

    })
  }

  async exportExcel(btnExcel) {
    btnExcel.disabled = true
    //if(this.date_debut == undefined && this.date_fin == undefined){
      await this.helper.exportExcel(this.print(this.demandes_processus),this.__("print.list") + this.__("demande_processus.demande_processus"));
    /*} /*else {
      await this.helper.exportExcel(this.print(this.client_demande_modifs),this.__("reclamation.reclamation") + this.__("print.from")  + this.date_debut_query.split('-').reverse().join('-') + this.__("print.to") + this.date_fin_query.split('-').reverse().join('-'));
    } */
    btnExcel.disabled = false
  }

  print(demande_processus:demande_processus[]){
    return  demande_processus.map((demande_processus)=>{
      let t = {}
      t[this.__('demande_processus.num_dossier')] = demande_processus.num_dossier
      t[this.__('demande_processus.date_demande')] = demande_processus.date_demande
      t[this.__('demande_processus.nom')] = demande_processus.nom
      t[this.__('demande_processus.installation')] = demande_processus.installation
      t[this.__('demande_processus.cin')] = demande_processus.cin
      t[this.__('demande_processus.telephone')] = demande_processus.telephone
      t[this.__('demande_processus.email')] = demande_processus.email
      t[this.__('demande_processus.type_processus')] = demande_processus.type_processus.name
      t[this.__('print.statut')] =  (demande_processus.statut == 0 ? this.__("tooltip.running_trated") : (demande_processus.statut == 1) ? this.__('tooltip.state_save') : (demande_processus.statut == 2) ? this.__('tooltip.traiter') : this.__('tooltip.state_planif'))
      return t ;
    })  || [];

  }

  changerStatus(event){
    this.status=event;
  }

  search() {
    this.isSearch = true;


    if (this.date_debut || this.date_fin) {
      this.rerender();
    }else {
      this.isSearch = false;
      this.date_debut = undefined
      this.date_fin = undefined
      this.rerender();
    }
  }

   cancel() {
    this.isSearch = false;
    this.date_debut = undefined
    this.date_fin = undefined
    this.status= "8"
    this.rerender();
  }


}







