import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {valuesys} from "../../../options";
import {Router} from "@angular/router";
import Login from "../model/Login";
import {Auth, profilage_droit, profilage_module, profilage_sous_module} from "../model/db";
import {HttpApiResponse} from "../model/DataTablesResponse";

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  constructor(private httpClient: HttpClient,private  router: Router) {
   // console.log('START')
    //this.getLoginUser(true);
  }

 public async setLoginUser(user:any){
    try {
      if(user){
        localStorage.setItem(environment.userItemName, JSON.stringify(user));
        return  true
      }
      return false ;
    } catch (e) {
      return  false ;
    }
  }


  /*
  * Permet de retourner l'utilisateur actuellement connecté
  * le reload a true permet de faire une appel a api gatewate pour rafraissir
  * le reload a false permet de prendre l'utilisateur stocker en local
  * Si le reload est a false et que l'utilisateur actuel est null on rafraissit aussi
  * */
 public account(reload:boolean = false): Auth{
    try {
       let user:Auth = null;
       user = <Auth> JSON.parse(localStorage.getItem(environment.userItemName) || null);
       return user ;
    } catch (e) {
         //this.router.navigate(["/logout"]) ;
         return  null ;
    }
  }
  /*
  * Code sous module un tableau ou un string | le premier element du tableau on ne renseigne pas son code de module
  * Mais le reste du table on doit renseignez leur code de module dans modules
  * */
 public initAutority(codeSousModule:string|Array<string>,modules:Array<string> = []){
   if(typeof codeSousModule === 'string'){
     codeSousModule = [codeSousModule]
   }
   modules.push(window['module'])
   window['authority'] =[];
   window['actions'] = null;
   window['authority']['module'] =modules;
  // console.log('lll',window['module'])
   window['authority']['sous_module'] = codeSousModule;
   let user:Auth ;
   //user = <Auth> JSON.parse(localStorage.getItem(environment.userItemName) || null);
   user = <Auth> JSON.parse(localStorage.getItem(environment.userItemName) || null);
   window['authority']['user'] = user;
  }

 public async  getLoginUser(reload:boolean = false){
    try {
      let user ={};
      if(reload || !localStorage.getItem(environment.userItemName)){
        let res = await this.httpClient.get<any>(environment.baseUrl + environment.userAuth, valuesys.httpAuthOptions()).toPromise() ;
        if(res.code === 200){
          user = res.data ;
          this.setLoginUser(user) ;
        }else {
          await  this.router.navigate(["/logout"])
        }
      }
      user = <Auth> JSON.parse(localStorage.getItem(environment.userItemName) || null);
      return user ;
    } catch (e) {
        await  this.router.navigate(["/logout"]) ;
      return null ;
    }
  }

  public async isConnected(){
    try {
     /* let res = await this.httpClient.get<any>(environment.baseUrl + environment.ping, valuesys.httpAuthOptions()).toPromise();
      return  res.code ;*/
     let user =  this.account();
     if(user){
       return  200;
     }
     return false;
    } catch (e) {
      console.log(e);
      return 500;
    }
  }

  public setToken(token:string,expires_in:number){
    localStorage.setItem(environment.authItemName, token);
    let t = new Date();
    t.setSeconds(t.getSeconds() + (expires_in));
    localStorage.setItem(valuesys.timeTokenName, t.getTime() + "");
  }

  public async getMenu(basemodule : string, code = null){
      let user: any = await this.getLoginUser(false);

      let module: any = {libelle:'', sous_modules:[]} ;
      let sous_modules: any = [];

      if(code) {
        let mod_ = user.law.find((item:any)=>item.code == code);
        if(!mod_){
          //Si l'code du module n'existe pas on redirige vers la page d'acccueil app
          this.router.navigate(['/app']);
          return false ;
        }
          let sous_module = mod_.sous_modules;
          module.libelle = mod_.libelle;
          for (let j = 0 ; j <   sous_module.length;j++) {
              sous_modules.push({
                  icone : sous_module[j].icon,
                  sous_module: sous_module[j].libelle,
                  url: basemodule + code + "/" + sous_module[j].code
              })
          }
          module.sous_modules = sous_modules;
          return module;
      }
      module = [] ;
      for (let i=0 ;  i <  user.law.length ; i++ ) {
          module.push({

              icone : user.law[i].icon,
              module: user.law[i].libelle ,
              url: basemodule + user.law[i].code,
            hasOneSubModuleAction:  user.law[i].hasOneSubModuleAction
          })
      }

      return module;
  }

  async loginUser(login:Login){

    try {
      let response: any = await this.httpClient.post<any>(environment.baseUrl + environment.login, login, valuesys.httpOptions).toPromise();
      if (response.code === 200) {
        return  response ;
      } else {
        return response ;
      }
    } catch (e) {
      console.log(e)
      return  false ;
    }
  }

  async refreshToken(){
   setTimeout(async ()=>{
     if(AuthServiceService.refresh()){
       let {data,code} = await this.httpClient.get<HttpApiResponse>(environment.baseUrl+environment.refresh,valuesys.httpAuthOptions()).toPromise();
       if(code === 200){
         this.setToken(data.access_token,data.expires_in)
       }
     }
   },70)
  }
  private static refresh(){
   let timeToken_ =  +window.localStorage.getItem(valuesys.timeTokenName) || 0;
    let now = (new Date()).getTime();
    console.log((timeToken_ - now) / 1000 );
   return timeToken_ - now   <= valuesys.authRefreshInterval
  }
}
