import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../administration/service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../administration/service/helper.service";
import {BsModalService} from "ngx-bootstrap";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {Translatable} from "../Translatable";
import {agence, user} from "../administration/model/db";
import {environment} from "../../environments/environment";
import {valuesys} from "../../options";

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent extends Translatable implements OnInit {
  startDate = new Date();
  private now = (new Date()).toISOString().replace('T',' ').substr(0,10);

  date_debut = this.now;
  date_fin =  this.now;
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService,
              private route: Router
  ) {
    super();
  }
  agences : agence [] = []
 async ngOnInit() {
   let rest:any =   await this.http.get(environment.agence,valuesys.httpAuthOptions()).toPromise();
   this.agences = rest.data ;
  }

 async exportPdf(agence: agence,url=false) {
  await this.helper.exportPdf([agence],"Liste des Agences");
  }
 async exportExcel(agence: agence,url=false) {
  await this.helper.exportExcel([agence],"Liste des Agences");
  }
}
