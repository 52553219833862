import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Translatable} from "../../../../../Translatable";
import {DataTableDirective} from "angular-datatables";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {environment} from "../../../../../../environments/environment";
import {valuesys} from "../../../../../../options";
import Swal from "sweetalert2";
import {abonnement} from "../../../../model/db";

@Component({
  selector: 'app-abonnement',
  templateUrl: './abonnement.component.html',
  styleUrls: ['./abonnement.component.css']
})
export class AbonnementComponent  extends Translatable implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  title:string ;
  dtOptions: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  public abonnements : abonnement [] = [] ;
  public abonnement:abonnement = new abonnement();
  public abonnement_details: abonnement;
  public abonnement_error : abonnement ;


  /**
   * datatble variable
   */

  searchInput : any= "";
  filtreSelect : any = 10;
  where : any= "";
  tabExportEtud : any= "";
  search : any= "";
  endPOint : any= "";
  child : any= "";
  tri : any= "";
  order : any= "";
  
  //--paginate
  public paginateListe : any = [];
  public nextPage : any = [];
  public prevPage : any = [];
  public lastPage : any = [];
  public firstPage : any = [];
  public currentPage : any = [];
  public total : any = [];
  public path : any = [];
  public lastNumPage : any = [];
  public infoData : any = [];
  public cPage_less_2 : number;
  public path_cPage_less_2 : any;
  public cPage_less_1 : number;
  public path_cPage_less_1 : any;
  public cPage_more_1 : number;
  public path_cPage_more_1 : any;
  public cPage_more_2 : number;  
  public path_cPage_more_2 : any;
  public searchColumn: string;
  public columnTemp;
  public childTemp;
//--end-paginate

  /**
   * 
      end datatable variable
   */
  
  //---------------------//
  //    filter date      //
  //---------------------//

  private endTime = " 23:59:59" ;
  date_debut : Date = new Date();
  date_debut_query : string = '';
  date_fin : Date  = new Date();
  date_fin_query : string = '';

  formatQuery(){
    if(this.date_debut == undefined || this.date_fin == undefined){
      this.date_debut_query = '';
      this.date_fin_query = '';
    }else{
      let tempStoreD:any = this.date_debut.setHours(this.date_debut.getHours()+3);
      tempStoreD = new Date(tempStoreD);
      let tempStoreF:any = this.date_fin.setHours(this.date_fin.getHours()+3);
      tempStoreF = new Date(tempStoreF);
      this.date_debut_query = this.date_debut.toISOString().replace('T',' ').substr(0,10).split('/').reverse().join('-');
      this.date_fin_query = this.date_fin.toISOString().replace('T',' ').substr(0,10).split('/').reverse().join('-');
    }
  }

  cancel() {
    this.date_debut = undefined;
    this.date_fin = undefined;
  }

  verifyFiltre() {
    if (this.date_debut || this.date_fin) {
    }else {
      this.date_debut = undefined;
      this.date_fin = undefined;
    }
  }

  //------------------//
  // end filter date  //
  //------------------//

  /**
   * 
   * @param activatedRoute 
   * @param auth 
   * @param http 
   * @param httpClient 
   * @param toastr 
   * @param helper 
   * @param modalService 
   * @param ngxService 
   * @param route 
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private auth : AuthServiceService,
    private http: HttpClient,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private helper: HelperService,
    private modalService: BsModalService,
    private ngxService: NgxUiLoaderService,
    private route: Router
  ){
    super();
  }

  async ngOnInit() {
    this.auth.initAutority("ABO");
    this.endPOint = environment.abonnement+"?page=1";
    this.getUrlDatatable(this.endPOint);
    this.verifyFiltre();
  }

  close(){
    this.modalRef.hide()
    this.isValid = false ;
    this.abonnement = new abonnement()
    this.titleModal = this.__('abonnement.edit') ;
    this.abonnement_error = new abonnement();
  }

  async openModalDetails(template: TemplateRef<any>, currentabonnement: abonnement = null, elm) {
    elm.disabled = true
    this.titleModal = this.__('label_details');
    this.abonnement_details = currentabonnement;
    this.modalRef = this.modalService.show(template, {class: "modal-md", ignoreBackdropClick: true});
    elm.disabled = false;
  }

  async toggleStatut(abonnement: abonnement, statut: number, btn: any) {

    btn.disabled = true;

    Swal.fire({
      title: this.__('swal.confirmTitle'),
      text: this.__('swal.confirmMsg'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.__('swal.no'),
      confirmButtonText: this.__('swal.yes')
    }).then(async (result) => {
      /*if (result.value) {
        Swal.fire(
          'swal.successTitle',
          'swal.success',
          'success'
        )
      }*/
      if (result.value) {
        let toogle = await this.http.get<any>(environment.abonnement + `/${abonnement.id}/${statut}/statut`, valuesys.httpAuthOptions()).toPromise();
        if (toogle.code == 201) {
          this.toastr.success(toogle.msg, this.__("msg.success"));
          abonnement.statut = toogle.data.statut;


        } else {
          this.toastr.error(toogle.msg, this.__("msg.error"));
        }
        btn.disabled = false;
      }
      btn.disabled = false;
    })

  }

  delete(deletedAbo:abonnement,elm){
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.abonnement+"/" + deletedAbo.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.abonnements= this.abonnements.filter(function (item,index) {
            return item.id != deletedAbo.id
          })
          Swal.fire(
              this.__('msg.deleted'),
              sup.msg,
              'success'
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
            this.__('msg.canceled'),
            this.__('msg.canceled_delete'),
            'error'
        )
      }
    })
  }

  async exportExcel(btnExcel) {
    btnExcel.disabled = true
    await this.helper.exportExcel(this.print(this.abonnements),this.__("print.list") + this.__("abonnement.abonnements"));
    btnExcel.disabled = false
  }

  print(abonnement:abonnement[]){
    return  abonnement.map((abonnement)=>{
      let t = {}
      t[this.__('abonnement.num_compteur')] = abonnement.num_compteur
      t[this.__('client.no_client')] = (abonnement.client == null ? ' - ' : abonnement.client.num_client)
      t[this.__('abonnement.address')] = abonnement.address
      t[this.__('abonnement.date_abonnement')] = abonnement.date_abonnement
      t[this.__('client.prenom') + " " + this.__('client.client')] = (abonnement.client == null ? ' - ' : abonnement.client.prenom)
      t[this.__('client.nom') + " " + this.__('client.client')] = (abonnement.client == null ? ' - ' : abonnement.client.nom)
      t[this.__('table.statut')] =  (abonnement.statut == 0 ? this.__("tooltip.resilier") : this.__("tooltip.abonner"))
      return t ;
    })  || [];

  }

  /**
   * datatable function
   */

   async getUrlDatatable(url,col = "",refElement?: any,child?:string){

    this.verifyFiltre();
    this.child = "child=client,Abonnement";

    //filtre par date
    this.formatQuery();
    if(this.date_debut_query != ''){
      this.child = this.child + `&where=date_abonnement|se|${this.date_debut_query },date_abonnement|ie|${this.date_fin_query  + this.endTime}`;
    }
    //fin filter date

    if(refElement){
     this.searchColumn = refElement.value
     this.columnTemp = col;
     this.childTemp = child;
    }
    
    this.search = "";
    if(col != "" || this.searchColumn){
      if(this.searchColumn !="" && this.searchColumn !="undefined"){

        //changer le format de la date pour que ca marche pour JJ/MM/AAAA
        if(this.columnTemp == "date_abonnement"){
          this.searchColumn = this.searchColumn.split('/').reverse().join('-');
        }
        //fin changer le format de la date pour que ca marche pour JJ/MM/AAAA

        this.search = "&child_where="+this.childTemp+"|"+this.columnTemp+"|l|"+this.searchColumn+"";
      }
      else if(this.searchColumn == ""){
        this.search = "";
      }
    }else{
      if(this.searchInput !="" && this.searchInput !="undefined"){
        this.search = "&child_where_or=client|nom|l|"+this.searchInput+",client|prenom|l|"+this.searchInput+",Abonnement|num_compteur|l|"+this.searchInput+",client|num_client|l|"+this.searchInput+",Abonnement|address|l|"+this.searchInput+"";
      }else if(this.searchInput == ""){
        this.search = "";
      }
    }
     
    let filtre : any= "";
    if(this.filtreSelect !="" && this.filtreSelect !="undefined"){
      filtre = "&per_page=" + this.filtreSelect;
    }
    let triage : any= "";
    if(this.tri !="" && this.tri !="undefined"){
      triage = "&__order__="+ this.order+","+ this.tri;
    }

    let toogle = await this.http.get<any>(url +"&"+ this.child + this.search  + filtre + triage  ,valuesys.httpAuthOptions()).toPromise();
    this.abonnements = toogle.data.data;
  
    this.firstPage = toogle.data.first_page_url;
    this.prevPage = toogle.data.prev_page_url ;
    this.nextPage = toogle.data.next_page_url ;
    this.lastPage = toogle.data.last_page_url ;
    this.lastNumPage = toogle.data.last_page;
    this.path = toogle.data.path;
    this.currentPage = toogle.data.current_page;
    this.total = toogle.data.total;

    this.cPage_less_2 = 0
    this.cPage_less_1 = 0

    if(this.currentPage > 1 ){
      
      if(this.currentPage > 2 ){
        this.cPage_less_2 = this.currentPage - 2
        this.path_cPage_less_2 = this.path +"?page="+this.cPage_less_2;
      }
    
      this.cPage_less_1 = this.currentPage - 1 
      this.path_cPage_less_1 = this.path +"?page="+this.cPage_less_1;
    }

    this.cPage_more_1 = 0
    this.cPage_more_2 = 0
    if(this.currentPage < this.lastNumPage){
      this.cPage_more_1 = this.currentPage + 1 
      this.path_cPage_more_1 = this.path +"?page="+this.cPage_more_1;

      if(this.currentPage < this.lastNumPage - 1 ){
        this.cPage_more_2 = this.currentPage + 2 
        this.path_cPage_more_2 = this.path +"?page="+this.cPage_more_2;
      }

    }

    let info = this.__('datatable.info').replace('_START_', toogle.data.from)
    let info2 =info.replace('_END_', toogle.data.to)
    this.infoData = info2.replace('_TOTAL_', toogle.data.total)
 
  }

  /**
  * end datatable funciton
  */

  async triTable(col, order){
    this.tri = col;
    this.order = order;
    this.getUrlDatatable(environment.client+"?page=1"+this.child)
  }

  //champ reset after click other input column table

  @ViewChild('num_compteur', {static:false}) 
  num_compteur : ElementRef;
  @ViewChild('num_client', {static:false}) 
  num_client : ElementRef;
  @ViewChild('address', {static:false}) 
  address : ElementRef;
  @ViewChild('date_abonnement', {static:false}) 
  date_abonnement : ElementRef;
  @ViewChild('prenom', {static:false}) 
  prenom : ElementRef;
  @ViewChild('nom', {static:false}) 
  nom : ElementRef;
  @ViewChild('recherche', {static:false}) 
  recherche : ElementRef;
   
  async reinitialiseChampToreset(){
    this.num_compteur.nativeElement.value = "";
    this.num_client.nativeElement.value = "";
    this.address.nativeElement.value = "";
    this.date_abonnement.nativeElement.value = "";
    this.prenom.nativeElement.value = "";
    this.nom.nativeElement.value = "";
    this.recherche.nativeElement.value = "";
  }

  // fin reset after click other input column table

}

