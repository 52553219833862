import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {Translatable} from "../../../../../Translatable";

@Component({
  selector: 'app-doc-miniature',
  templateUrl: './doc-miniature.component.html',
  styleUrls: ['./doc-miniature.component.css']
})
export class DocMiniatureComponent extends Translatable implements OnInit {
@Input('url')
url : string ;
@Input('index')
index : number ;

  constructor(public sanitizer: DomSanitizer) { super()}

  ngOnInit() {

  }

  type() {
  let  ext = this.url.split('.').reverse()[0];
  console.log('EXT',ext)
    if(ext === 'pdf'){
      return 1;
    }
  let img =  this.allowedExtension.filter((item) => item === ext);
    console.log('EXT',ext,'img',img)
     if(img.length > 0){
       return 2
     }
     else {
       return 3 ;
     }

  }
}
