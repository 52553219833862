import {Component, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {ActivatedRoute} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {environment} from "../../../../../../environments/environment";
import * as $ from "jquery";
import {valuesys} from "../../../../../../options";
import Swal from "sweetalert2";
import {Translatable} from "../../../../../Translatable";
import {type_reclamation} from "../../../../model/db";

@Component({
  selector: 'app-type-reclamation',
  templateUrl: './type-reclamation.component.html',
  styleUrls: ['./type-reclamation.component.css']
})
export class TypeReclamationComponent extends Translatable implements OnInit {
  title:string ;
  dtOptions: DataTables.Settings = {};

  titleModal:string ;
  //titleProfilage:string=this.__('profil.profils') ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  modalRef: BsModalRef;
  maxLength: string ;
  



  public type_reclamation_parent = [];
  public type_reclamations: type_reclamation [] = [];

  public type_reclamation:type_reclamation  = new type_reclamation();
  type_reclamation_error: type_reclamation;
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService
  ) {
    super();
  }
  async ngOnInit() {
    this.auth.initAutority("TREC");
    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__("lebel_users");
    this.initDatatable()
    //console.log(" liste des droits "+this.listeModules);
    this.maxLength = "6";


  }

  initDatatable(){
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {data:"name"},
      {data:"state"},
      {
        "data": "typeReclamation",
        "name" : "typeReclamation.name",
      }
    ]  ;
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.type_reclamation+"?child=typeReclamation&" + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
        this.type_reclamations = resp.data;

        console.log(this.type_reclamations);
        
        function compare(a, b) {
          if (a.code < b.code) { return - 1; }
          if (a.code > b.code) { return 1; }
          return 0;
        }
        this.type_reclamations.sort(compare); // on passe la référence à la fonction de comparaison

        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    }
  }

  async addUserForm(){
    this.isValid = true ;
    if(this.isAdd ){
      if(!this.type_reclamation.pere){this.type_reclamation.pere = 0} 
      console.log(this.type_reclamation);
      let res =  await this.http.post<any>( environment.type_reclamation,this.type_reclamation,valuesys.httpAuthOptions()).toPromise();
      
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        //this.rerender();
        this.type_reclamations.push(res.data);
        this.close()
      }else if(res.code === 400){
        this.type_reclamation_error = <type_reclamation> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        let doublon = res.data.indexOf("Duplicate");
        if(doublon >-1){
          this.toastr.error(this.__("msg.error_doublon"),this.__("msg.error")) ;
        }else{
          this.toastr.error(res.msg,this.__("msg.error")) ;
        }
      }
    }else {

      console.log("FAL PROF",this.type_reclamation);
      let res =  await this.http.put<any>( environment.type_reclamation + '/' + this.type_reclamation.id,this.type_reclamation,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;

        this.close()
      }else if(res.code === 400){
        this.type_reclamation_error = <type_reclamation> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else { 
        let doublon = res.data.indexOf("Duplicate");
        if(doublon >-1){
          this.toastr.error(this.__("msg.error_doublon"),this.__("msg.error")) ;
        }else{
          this.toastr.error(res.msg,this.__("msg.error")) ;
        }
      }
    }
    this.isValid = false ;

  }
  async openModal(template: TemplateRef<any>,currenTypeAbonnement:type_reclamation =null,btn:any) {
    btn.disabled= true
    this.type_reclamation_error = new type_reclamation();
    this.type_reclamation_parent = await this.helper.getSelectList(environment.type_reclamation,['name']);
    this.type_reclamation_parent= this.type_reclamation_parent.filter(function (item,index) {
      return item.pere == "1";
    });


    if(currenTypeAbonnement == null){
      this.type_reclamation = new type_reclamation();
      this.isAdd = true ;
      this.titleModal = this.__('type_reclamation.add') ;
    }else {
      this.isAdd = false ;
      this.titleModal = this.__('type_reclamation.edit') ;
      let res = await this.http.get<any>(environment.type_reclamation + '/' + currenTypeAbonnement.id,valuesys.httpAuthOptions()).toPromise();
      this.type_reclamation = currenTypeAbonnement ;
      this.type_reclamation.code = this.type_reclamation.code.trim();
      this.maxLength = "6";
      if(currenTypeAbonnement.pere == 1){
        this.maxLength = "3";
      }

      console.log(this.type_reclamation.pere);

    }
    btn.disabled= false

    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }
  close(){
    this.type_reclamation_error = new type_reclamation();
    this.type_reclamation = new type_reclamation();
    this.titleModal = "" ;
    this.modalRef.hide() 
  }
  delete(deleteTypeAbonnement:type_reclamation,btn:any){
    btn.disabled= true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.type_reclamation+"/"+deleteTypeAbonnement.id,valuesys.httpAuthOptions()).toPromise();
        if(sup.code == 204){
          this.type_reclamations= this.type_reclamations.filter(function (item,index) {
            return item.id != deleteTypeAbonnement.id
          });
          btn.disabled= false
          Swal.fire(
            this.__('msg.deleted'),
            sup.msg,
            'success'
          )
        }else {
          btn.disabled= false
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.__('msg.canceled'),
          this.__('msg.canceled_delete'),
          'error'
        )
        btn.disabled= false
      }
    })
  }


  async toggleState(type:type_reclamation,state:number,btn:any){
    console.log(btn.hidden = true)
    $(btn).attr('disabled');
    btn.disabled = true;
    let toogle = await this.http.get<any>( environment.type_reclamation+`/${type.id}/${state}/state`,valuesys.httpAuthOptions()).toPromise();
    
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      type.state = toogle.data.state

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }

  async getCodeParent(id: number) {
    let res = await this.http.get<any>(environment.type_reclamation + '/' + id,valuesys.httpAuthOptions()).toPromise();
    if(res.code = 200){
      this.type_reclamation.code = res.data.code.trim();
    }

  }

  select(nb : number){
    this.maxLength = "6";
    this.type_reclamation.pere = nb;
      if(this.type_reclamation.pere == 1){
        this.maxLength = "3";
      }

  }
}
