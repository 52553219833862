import { Component, OnInit } from '@angular/core';
import {Translatable} from "../../../../Translatable";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../service/auth-service.service";
import {Auth, profilage_droit, profilage_module, profilage_sous_module} from "../../../model/db";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-home-module',
  templateUrl: './home-module.component.html',
  styleUrls: ['./home-module.component.css']
})
export class HomeModuleComponent extends Translatable implements OnInit {
  module:profilage_module  = new profilage_module();
  public user : Auth = new Auth();
  constructor(private activatedRoute: ActivatedRoute,private router:Router, private auth : AuthServiceService) {
    super()
    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngOnInit() {
    //this.module = this.activatedRoute.snapshot.params['module'];
    this.activatedRoute.params.subscribe(async _ => {
      let moduleCode = _.moduleCode;
      this.user = <Auth> await this.auth.getLoginUser();
      if(this.user){
        this.module = this.user.modules.find((module : profilage_module) => module.code === moduleCode) ;
        if(!this.module){
         // alert('no find module')
        }
      }

    });


  }
  getFirstAction(actions:profilage_droit[],codeModule:string){
    let url = '/app/dashbord/'+ codeModule  ;
    let action =  actions.filter((_)=>_.type_action_id === environment.ID_TYPE_ACTION_LIEN  &&(this.user.info.admin===1 || _.state === 1));
    if(action.length > 0){
      url += '/' + action[0].url ;
    }
    return url ;
  }
  filterSousModule(sousmodules:profilage_sous_module[]){

    return  sousmodules.filter((_)=>_.hasOneAction === true || this.user.info.admin === 1)
  }


}
