
import {Component, OnInit, TemplateRef} from '@angular/core';
import {puT1_redevance, tarif, zone_tarifaire} from "../../../../model/db";
import {ActivatedRoute} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {valuesys} from "../../../../../../options";
import Swal from 'sweetalert2' ;
import {Translatable} from "../../../../../Translatable";
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {environment} from "../../../../../../environments/environment";
import * as $ from "jquery";

@Component({
  selector: 'app-puT1_redevance',
  templateUrl: './puT1_redevance.component.html',
  styleUrls: ['./puT1_redevance.component.css']
})
export class PuT1_redevanceComponent extends Translatable implements OnInit {

  title:string ;
  dtOptions: DataTables.Settings = {};

  titleModal:string ;
  //titleProfilage:string=this.__('profil.profils') ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  modalRef: BsModalRef;
  public activite: string;
  public activiteSelected:string;
  public selectedIDTarif: number;

  public puT1_redevances:puT1_redevance [] = [];
  public zone_tarifaires:zone_tarifaire [] = [];
  public tarifs:tarif [] = [];
  public puT1_redevance:puT1_redevance  = new  puT1_redevance();
  puT1_redevance_error: puT1_redevance;
  diametreList:any = [];


  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService
  ) {
    super();
  }

  async ngOnInit() {
    this.auth.initAutority("PUTR");
    console.log(this.autority('PUTR_6'));
    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__("lebel_users");
    this.initDatatable()
    //console.log(" liste des droits "+this.listeModules);
  }

  initDatatable(){
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
        {
            "data": "PUT1"
        },
        {
            "data": "PUT2"
        },
        {
            "data": "primeFixe"
        },
        {
            "data": "redevance"
        },
        {
            "data": "zonetarifaire",
            "name" : "zonetarifaire.value",
        },
        {
            "data": "tarif",
            "name" : "tarif.value",
        }

    ]  ;
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
          .get<DataTablesResponse>(
              environment.puT1_redevance +"?child=zonetarifaire,tarif&" + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
          ).subscribe(resp => {
        this.puT1_redevances = resp.data;
        console.log("this.puT1_redevances",this.puT1_redevances)
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    }
  }

  async addUserForm(){
    this.isValid = true ;
    if(this.isAdd ){
      console.log("ajout",this.puT1_redevance);
      this.puT1_redevance.diametre_redevence = this.valuesDiametreRedevence as [];
      let res =  await this.http.post<any>( environment.puT1_redevance,this.puT1_redevance,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        console.log(this.puT1_redevance.tarif_id,"tarif",this.tarifs)
        console.log(this.puT1_redevance.zone_tarifaire_id,"zonne",this.zone_tarifaires)
        if(this.activiteSelected == 'E'){
          res.data.tarif = this.tarifs.find(_ => _.id == this.puT1_redevance.tarif_id) ;
          res.data.zonetarifaire = this.zone_tarifaires.find(_ => _.id == this.puT1_redevance.zone_tarifaire_id);
        }else{
          res.data[0].tarif = this.tarifs.find(_ => _.id == this.puT1_redevance.tarif_id) ;
          res.data[0].zonetarifaire = this.zone_tarifaires.find(_ => _.id == this.puT1_redevance.zone_tarifaire_id);
        }
        if(this.activiteSelected == 'E'){
          this.puT1_redevances.push(res.data);
        }else{
          this.puT1_redevances.push(...res.data);
        }
          this.close()
        }else if(res.code === 400){
          this.puT1_redevance_error = <puT1_redevance> this.helper.errorForm(res.data);
          this.toastr.warning(res.msg,this.__("msg.warning")) ;
        }
        else {
          this.toastr.error(res.msg,this.__("msg.error")) ;
        }
      }else {
        let res =  await this.http.put<any>( environment.puT1_redevance + '/' + this.puT1_redevance.id,this.puT1_redevance,valuesys.httpAuthOptions()).toPromise();

        if(res.code === 201){
            this.puT1_redevance.tarif = this.tarifs.find(_ => _.id == this.puT1_redevance.tarif_id) ;
        this.puT1_redevance.zonetarifaire = this.zone_tarifaires.find(_ => _.id == this.puT1_redevance.zone_tarifaire_id) ;
          this.toastr.success(res.msg,this.__("msg.success")) ;

          this.close()
        }else if(res.code === 400){
          this.puT1_redevance_error = <puT1_redevance> this.helper.errorForm(res.data);
          this.toastr.warning(res.msg,this.__("msg.warning")) ;
        }
        else {
          this.toastr.error(res.msg,this.__("msg.error")) ;
        }
      }
    this.isValid = false ;
    this.valuesDiametreRedevence = [{diametre: "", redevence:""} ];

  }

  async openModal(template: TemplateRef<any>,currenTypePiece:puT1_redevance =null,btn:any) {
    btn.disabled= true
    this.puT1_redevance_error = new puT1_redevance();
    // this.tarifs = await this.helper.getSelectList(environment.tarif,['value']);
    //this.zone_tarifaires = await this.helper.getSelectList(environment.zone_tarifaire,['value']);
    if(currenTypePiece == null){
      this.puT1_redevance = new puT1_redevance();
      this.isAdd = true ;
      this.titleModal = this.__('puT1_redevance.add') ;
    }else {
      this.isAdd = false ;
      this.titleModal = this.__('puT1_redevance.edit') ;
      //let res = await this.http.get<any>(environment.puT1_redevance + '/' + currenTypePiece.id,valuesys.httpAuthOptions()).toPromise();
      this.puT1_redevance = currenTypePiece ;
      if (currenTypePiece.tarif != null) {
        this.selectedIDTarif = currenTypePiece.tarif_id;
        this.activiteSelected=currenTypePiece.tarif.activite;
        //console.log(" this.selectedIDTarif", this.selectedIDTarif)
        this.onChangeActivite();
      } else {
        this.tarifs = null;
      }
    }
    btn.disabled= false;
    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }

  close(){
    this.puT1_redevance_error = new puT1_redevance();
    this.puT1_redevance = new puT1_redevance();
    this.titleModal = "" ;
    this.modalRef.hide()
    this.valuesDiametreRedevence = [{diametre: "", redevence:""} ];

  }

  delete(deleteTypePiece:puT1_redevance,btn:any){
    btn.disabled= true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.puT1_redevance+"/"+deleteTypePiece.id,valuesys.httpAuthOptions()).toPromise();
        if(sup.code == 204){
          this.puT1_redevances= this.puT1_redevances.filter(function (item,index) {
            return item.id != deleteTypePiece.id
          });
          btn.disabled= false
          Swal.fire(
              this.__('msg.deleted'),
              sup.msg,
              'success'
          )
        }else {
          btn.disabled= false
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
            this.__('msg.canceled'),
            this.__('msg.canceled_delete'),
            'error'
        )
        btn.disabled= false
      }
    })
  }

  async toggleState(type:puT1_redevance,state:number,btn:any){
    console.log(btn.hidden = true)
    $(btn).attr('disabled');
    btn.disabled = true;
    let toogle = await this.http.get<any>( environment.puT1_redevance+`/${type.id}/${state}/state`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      type.state = toogle.data.state

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }

  async onChangeActivite() {
    this.activite= this.activiteSelected;
    this.puT1_redevance.activite = this.activite;
    let res_tarifs= await this.helper.getSelectList( environment.tarif + `?where=activite|e|${ this.activite}`, ['value']);
    console.log(res_tarifs,"tarifs")
    let res_zoneTarifaires= await this.helper.getSelectList( environment.zone_tarifaire + `?where=activite|e|${ this.activite}`, ['value']);
    // console.log('regions: ', this.tarifs);
    if (res_tarifs) {
      this.tarifs = res_tarifs;
      // console.log(' this.tarifschane activite: ', this.tarifs);
    }
    if (res_zoneTarifaires) {
      this.zone_tarifaires = res_zoneTarifaires;
      // console.log(' this.tarifschane activite: ', this.tarifs);
    }
  }

  //add field for activity W
  valuesDiametreRedevence = [{diametre: "", redevence:""} ];

  removevalueDiametreRedevence(i){
    this.valuesDiametreRedevence.splice(i,1);
  }

  addvalueDiametreRedevence(){
    this.valuesDiametreRedevence.push({diametre: "", redevence:""});
  }

  async getDiametreByTarifID(tarifID:any){
    let response =  await this.http.get<any>( environment.puissance+"?&where=tarif_id|e|"+tarifID,valuesys.httpAuthOptions()).toPromise();
    this.diametreList = response.data;
    console.log(this.diametreList,'xxxx')

  }


}

