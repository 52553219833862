import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import { DataTablesModule } from 'angular-datatables';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgSelect2Module } from 'ng-select2';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {MatStepperModule} from '@angular/material/stepper';
import { registerLocaleData } from '@angular/common';
import localeFR from '@angular/common/locales/fr';
import { ChartistModule } from 'ng-chartist';
registerLocaleData(localeFR);
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AuthGuard} from './administration/service/auth.guard';
import {LoginComponent} from './administration/component/fragments/auth/login/login.component';
import { BaseAppComponent } from './administration/component/layouts/base-app/base-app.component';
import { LogoutComponent } from './administration/component/fragments/auth/logout/logout.component';
import {AuthServiceService as AuthService} from "./administration/service/auth-service.service";
import { HomeModuleComponent } from './administration/component/layouts/home-module/home-module.component';
import { UtilisateurComponent } from './administration/component/fragments/administration/utilisateur/utilisateur.component';
import {HomelayoutComponent} from "./administration/component/layouts/homelayout/homelayout.component";
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HelperService} from "./administration/service/helper.service";
import { ModalModule, TooltipModule} from 'ngx-bootstrap';
import {
  NgxUiLoaderConfig,
  NgxUiLoaderHttpModule,
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule, PB_DIRECTION,
  POSITION, SPINNER
} from 'ngx-ui-loader';
import { ChartsModule } from 'ng2-charts';
import { MomentModule } from 'ngx-moment';
import { MaskNumberPipe } from './mask-number.pipe';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRadioModule} from "@angular/material/radio";
import {ExportAsModule} from "ngx-export-as";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import {EditorModule} from "@tinymce/tinymce-angular";
import { LightboxModule } from 'ngx-lightbox';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {MatFormFieldModule} from "@angular/material/form-field";
import { NgxDropzoneModule } from "ngx-dropzone";
import {ApplicationComponent} from "./administration/component/layouts/application/application.component";
import {AppSiderBarComponent} from "./administration/component/controls/app-sider-bar/app-sider-bar.component";
import {HomeApplicationComponent} from "./administration/component/layouts/home-application/home-application.component";
import {ProfilComponent} from "./administration/component/fragments/administration/profil/profil.component";
import { PartenaireComponent } from './administration/component/fragments/partenaire/partenaire/partenaire.component';
import { ModuleComponent } from './administration/component/fragments/administration/module/module.component';
import { SousModuleComponent } from './administration/component/fragments/administration/sous-module/sous-module.component';
import { AgenceComponent } from './administration/component/fragments/administration/agence/agence.component';
import { ServiceComponent } from './administration/component/fragments/administration/service/service.component';
import { ClientComponent } from './administration/component/fragments/compteclient/client/client.component';
import { AbonnementComponent } from './administration/component/fragments/compteclient/abonnement/abonnement.component';
import { CompteurComponent } from './administration/component/fragments/compteclient/compteur/compteur.component';
import { FactureComponent } from './administration/component/fragments/compteclient/facture/facture.component';
import { TypeClientComponent } from './administration/component/fragments/compteclient/type-client/type-client.component';
import { ReclammationComponent } from './administration/component/fragments/demande/reclammation/reclammation.component';
import { IncidentComponent } from './administration/component/fragments/demande/incident/incident.component';
import { PaiementComponent } from './administration/component/fragments/reporting/paiement/paiement.component';
import { TroubleshootComponent } from './administration/component/fragments/reporting/troubleshoot/troubleshoot.component';
import { PlateformeComponent } from './administration/component/fragments/administration/parametrage/plateforme/plateforme.component';
import { TestComponent } from './test/test.component';
import {TypeAbonnementComponent} from "./administration/component/fragments/compteclient/type-abonnement/type-abonnement.component";
import { MatDatepickerModule, MatInputModule, MatNativeDateModule } from '@angular/material';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {ProfilUtilisateurComponent} from "./administration/component/fragments/auth/profil-utilisateur/profil-utilisateur.component";
import { ActionComponent } from './administration/component/fragments/administration/parametrage/action/action.component';
import { TypeReclamationComponent } from './administration/component/fragments/demande/type-reclamation/type-reclamation.component';
import { DocMiniatureComponent } from './administration/component/fragments/view/doc-miniature/doc-miniature.component';
import {NgxDocViewerModule} from "ngx-doc-viewer";
import { ModificationClientComponent } from './administration/component/fragments/demande/modification-client/modification-client.component';
import {AgmCoreModule} from "@agm/core";
import {environment} from "../environments/environment";
import {AuthInterceptor} from "./administration/service/auth-interceptor";
import {valuesys} from "../options";
import {TypePieceComponent} from "./administration/component/fragments/compteclient/type-piece/type-piece.component";
import { TarifComponent } from './administration/component/fragments/compteclient/tarif/tarif.component';
import { LimitT1Component } from './administration/component/fragments/compteclient/limit-t1/limit-t1.component';
import {Zone_tarifaireComponent} from "./administration/component/fragments/compteclient/zone_tarifaire/zone_tarifaire.component";
import {PuT1_redevanceComponent} from "./administration/component/fragments/compteclient/puT1_redevance/puT1_redevance.component";
import {PuissanceComponent} from "./administration/component/fragments/compteclient/puissance/puissance.component";
import { BranchementComponent } from './administration/component/fragments/demande/branchement/branchement.component';
import { SiteComponent } from './administration/component/fragments/compteclient/site/site.component';
import { DistanceComponent } from './administration/component/fragments/compteclient/distance/distance.component';
import { LongueurComponent } from './administration/component/fragments/compteclient/longueur/longueur.component';
import { TypeBranchementComponent } from './administration/component/fragments/compteclient/type-branchement/type-branchement.component';
import { BasePrixComponent } from './administration/component/fragments/compteclient/base-prix/base-prix.component';
import { TypeProcessusComponent } from './administration/component/fragments/demande/type-processus/type-processus.component';
import { DemandeProcessusComponent } from './administration/component/fragments/demande/demande-processus/demande-processus.component';
import { MessageComponent } from './administration/component/fragments/demande/message/message.component';
import { TypeDocumentComponent } from './administration/component/fragments/compteclient/type-document/type-document.component';
import { ClientPComponent } from './administration/component/fragments/compteclient/client-p/client-p.component';
import { BuildMobileComponent } from './administration/component/fragments/administration/build-mobile/build-mobile.component';
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '##00acc1',
  fgsColor: '##00acc1',
  bgsPosition: POSITION.centerCenter,
 // bgsOpacity:1,
  bgsSize: 70,
  fgsSize: 30,
  bgsType: SPINNER.rectangleBouncePulseOutRapid, // background spinner type|rectangleBounce|ballScaleMultiple|cubeGrid
  fgsType: SPINNER.circle, // foreground spinner type | ballScaleMultiple
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 7, // progress bar thickness
};



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BaseAppComponent,
    LogoutComponent,
    HomeModuleComponent,
    UtilisateurComponent,
    HomelayoutComponent,
    MaskNumberPipe,
    ApplicationComponent,
    AppSiderBarComponent,
    HomeApplicationComponent,
    ProfilComponent,
    PartenaireComponent,
    ModuleComponent,
    SousModuleComponent,
    AgenceComponent,
    ServiceComponent,
    ClientComponent,
    AbonnementComponent,
    CompteurComponent,
    TypeAbonnementComponent,
    FactureComponent,
    TypeClientComponent,
    TypePieceComponent,
    ReclammationComponent,
    IncidentComponent,
    PaiementComponent,
    TroubleshootComponent,
    PlateformeComponent,
    TestComponent,
    ProfilUtilisateurComponent,
    ActionComponent,
    TypeReclamationComponent,
    DocMiniatureComponent,
    ModificationClientComponent,
    TarifComponent,
    LimitT1Component,
    DistanceComponent,
    ModificationClientComponent,
      Zone_tarifaireComponent,
      PuT1_redevanceComponent,
      PuissanceComponent,
      BranchementComponent,
      SiteComponent,
      DistanceComponent,
      LongueurComponent,
      TypeBranchementComponent,
      BasePrixComponent,
      TypeProcessusComponent,
      DemandeProcessusComponent,
      MessageComponent,
      TypeDocumentComponent,
      ClientPComponent,
      BuildMobileComponent


  ],
  imports: [
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        'm': 59
      }
    }),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule,
    MatStepperModule,
    EditorModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true
    }),
    ModalModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    // NgxUiLoaderRouterModule, // import NgxUiLoaderRouterModule. By default, it will show foreground loader.
    // If you need to show background spinner, do as follow:
    // NgxUiLoaderRouterModule.forRoot({ showForeground: false })
    NgxUiLoaderHttpModule.forRoot({exclude: ['/login']}),
    //INPUT TEL
    NgSelect2Module,
    Ng2TelInputModule,
    ChartsModule,
    TooltipModule.forRoot(),
    MatCheckboxModule,
    MatRadioModule,
    NgbModule,
    ChartistModule,
    //TooltipModule.forRoot({}),
    EditorModule,
    ExportAsModule,
    LightboxModule,
    BsDatepickerModule.forRoot(),
    MatFormFieldModule,
    NgxDropzoneModule,
    //MATERIAL
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxDocViewerModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_MAP
    })
  ],
  providers: [
    AuthService,
    AuthGuard,
    HelperService,
      { provide: LOCALE_ID, useValue: 'fr' },
    {provide: MAT_DATE_LOCALE, useValue: 'fr'},
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    {
      // use fake backend in place of Http service for backend-less development
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }


  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
