import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Translatable} from "../../../../../Translatable";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {environment} from "../../../../../../environments/environment";
import * as $ from "jquery";
import {valuesys} from "../../../../../../options";
import Swal from "sweetalert2";
import {facture} from "../../../../model/db";

@Component({
  selector: 'app-facture',
  templateUrl: './facture.component.html',
  styleUrls: ['./facture.component.css']
})
export class FactureComponent extends Translatable implements OnInit {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  title:string ;
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  public factures : facture [] = [] ;
  public facture:facture = new facture();
  public facture_details: facture;
  public facture_error : facture ;
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private httpClient: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService,
              private route: Router
  ) {
    super();
  }

  async ngOnInit() {
    this.auth.initAutority("FAC");
    this.initDatatable()
  }

  initDatatable() {
    this.isAdd = true;
    this.isValid = false;
    this.title = this.__("facture.facture");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "num_facture"
      },
      {
        "data": "numero_transaction"
      },
      {
        "data": "periode"
      },
      {
        "data": "montant"
      },
      {
        "data": "consommation"
      },
      {
        "data": "abonnement.num_compteur"
      },
      {data: "state", searchable: false},
      {data: "state", searchable: false},
    ];
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.facture + "?child=abonnement&" + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
        this.factures = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data: []
        });
      });
    }
  }

  async openModal(template:TemplateRef<any>,currenFacture:facture =null,elem) {

    this.facture_error = new facture()
    elem.disabled = true ;
    if(currenFacture == null){
      this.isAdd = true ;
      this.facture = new facture() ;
      this.titleModal = this.__('facture.add') ;
    }else {

      this.isAdd = false ;
      this.titleModal = this.__('facture.edit') ;
      this.facture = currenFacture ;

    }
    elem.disabled = false ;
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});

  }
  close(){
    this.modalRef.hide()
    this.isValid = false ;
    this.facture = new facture()
    this.titleModal = this.__('facture.edit') ;
    this.facture_error = new facture();
  }
  async addUserForm(){
    this.facture_error = new facture()
    this.isValid = true ;
    if(this.isAdd ){
      let res:any =  await this.http.post<any>(environment.facture,this.facture,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        this.factures.push( res.data);
        this.close()
      }else if(res.code === 400){
        for (let item in res.data){
          this.facture_error[item] = res.data[item][0] ;
        }
        this.facture_error = <facture> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else {
      console.log(this.facture);
      let res =  await this.http.put<any>( environment.facture + '/'+this.facture.id,this.facture,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        this.close()
      }else if(res.code === 400){
        this.facture_error = <facture> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    this.isValid = false ;


  }
  async openModalDetails(template: TemplateRef<any>, currentfacture: facture = null, elm) {
    elm.disabled = true
    this.titleModal = this.__('label_details');
    this.facture_details = currentfacture
    console.log(this.facture_details)
    this.modalRef = this.modalService.show(template, {class: "modal-md", ignoreBackdropClick: true});
    elm.disabled = false
  }



  async toggleState(facture: facture, state: number, btn: any) {

    btn.disabled = true;

    Swal.fire({
      title: this.__('swal.confirmTitle'),
      text: this.__('swal.confirmMsg'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.__('swal.no'),
      confirmButtonText: this.__('swal.yes')
    }).then(async (result) => {
      /*if (result.value) {
        Swal.fire(
          'swal.successTitle',
          'swal.success',
          'success'
        )
      }*/
      if (result.value) {
        let toogle = await this.http.get<any>(environment.facture + `/${facture.id}/${state}/statut`, valuesys.httpAuthOptions()).toPromise();
        if (toogle.code == 201) {
          this.toastr.success(toogle.msg, this.__("msg.success"));
          facture.statut = toogle.data.statut;


        } else {
          this.toastr.error(toogle.msg, this.__("msg.error"));
        }
        btn.disabled = false;
      }
      btn.disabled = false;
    })


  }




  async exportExcel(btnExcel) {
    btnExcel.disabled = true
    //if(this.date_debut == undefined && this.date_fin == undefined){
      await this.helper.exportExcel(this.print(this.factures),this.__("print.list") + this.__("facture.facture"));
    /*} /*else {
      await this.helper.exportExcel(this.print(this.client_demande_modifs),this.__("reclamation.reclamation") + this.__("print.from")  + this.date_debut_query.split('-').reverse().join('-') + this.__("print.to") + this.date_fin_query.split('-').reverse().join('-'));
    } */
    btnExcel.disabled = false
  }
  print(facture:facture[]){
    return  facture.map((facture)=>{
      let t = {}
      t[this.__('facture.num_facture')] = facture.num_facture
      t[this.__('facture.numero_transaction')] = facture.numero_transaction
      t[this.__('facture.periode')] = facture.periode
      t[this.__('facture.montant')] = facture.montant
      t[this.__('facture.consommation')] = facture.consommation
      t[this.__('facture.num_compteur')] = facture.abonnement.num_compteur
      t[this.__('print.statut')] =  (facture.statut == 0 ? this.__("tooltip.nonpayer") : (facture.statut == 1) ? this.__('tooltip.payer') : this.__('tooltip.state_planif'))
      return t ;
    })  || [];

  }

}
