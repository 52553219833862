import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
// @ts-ignore
import {LoginComponent} from './administration/component/fragments/auth/login/login.component';
import {AuthGuard} from './administration/service/auth.guard';
import { BaseAppComponent } from './administration/component/layouts/base-app/base-app.component';
import {LogoutComponent} from "./administration/component/fragments/auth/logout/logout.component";
import {HomeModuleComponent} from "./administration/component/layouts/home-module/home-module.component";
import {UtilisateurComponent} from "./administration/component/fragments/administration/utilisateur/utilisateur.component";
import {HomelayoutComponent} from "./administration/component/layouts/homelayout/homelayout.component";
import {ApplicationComponent} from "./administration/component/layouts/application/application.component";
import {ProfilComponent} from "./administration/component/fragments/administration/profil/profil.component";
import {PartenaireComponent} from "./administration/component/fragments/partenaire/partenaire/partenaire.component";
import {ModuleComponent} from "./administration/component/fragments/administration/module/module.component";
import {SousModuleComponent} from "./administration/component/fragments/administration/sous-module/sous-module.component";
import {AgenceComponent} from "./administration/component/fragments/administration/agence/agence.component";
import {ServiceComponent} from "./administration/component/fragments/administration/service/service.component";
import {PlateformeComponent} from "./administration/component/fragments/administration/parametrage/plateforme/plateforme.component";
import {ClientComponent} from "./administration/component/fragments/compteclient/client/client.component";
import {AbonnementComponent} from "./administration/component/fragments/compteclient/abonnement/abonnement.component";
import {CompteurComponent} from "./administration/component/fragments/compteclient/compteur/compteur.component";

import {FactureComponent} from "./administration/component/fragments/compteclient/facture/facture.component";
import {TypeClientComponent} from "./administration/component/fragments/compteclient/type-client/type-client.component";
import {ReclammationComponent} from "./administration/component/fragments/demande/reclammation/reclammation.component";
import { MessageComponent } from './administration/component/fragments/demande/message/message.component';
import {IncidentComponent} from "./administration/component/fragments/demande/incident/incident.component";
import {PaiementComponent} from "./administration/component/fragments/reporting/paiement/paiement.component";
import {TroubleshootComponent} from "./administration/component/fragments/reporting/troubleshoot/troubleshoot.component";
import {TestComponent} from "./test/test.component";
import {TypeAbonnementComponent} from "./administration/component/fragments/compteclient/type-abonnement/type-abonnement.component";
import {ProfilUtilisateurComponent} from "./administration/component/fragments/auth/profil-utilisateur/profil-utilisateur.component";
import {ActionComponent} from "./administration/component/fragments/administration/parametrage/action/action.component";
import {TypeReclamationComponent} from "./administration/component/fragments/demande/type-reclamation/type-reclamation.component";
import {ModificationClientComponent} from "./administration/component/fragments/demande/modification-client/modification-client.component";
import {TypePieceComponent} from "./administration/component/fragments/compteclient/type-piece/type-piece.component";
import {TarifComponent} from "./administration/component/fragments/compteclient/tarif/tarif.component";
import {LimitT1Component} from "./administration/component/fragments/compteclient/limit-t1/limit-t1.component";
import { DistanceComponent } from './administration/component/fragments/compteclient/distance/distance.component';
import { LongueurComponent } from './administration/component/fragments/compteclient/longueur/longueur.component';
import { TypeBranchementComponent } from './administration/component/fragments/compteclient/type-branchement/type-branchement.component';
import { BasePrixComponent } from './administration/component/fragments/compteclient/base-prix/base-prix.component';
import { TypeProcessusComponent } from './administration/component/fragments/demande/type-processus/type-processus.component';
import { DemandeProcessusComponent } from './administration/component/fragments/demande/demande-processus/demande-processus.component';

import {Zone_tarifaireComponent} from "./administration/component/fragments/compteclient/zone_tarifaire/zone_tarifaire.component";
import {PuT1_redevanceComponent} from "./administration/component/fragments/compteclient/puT1_redevance/puT1_redevance.component";
import {PuissanceComponent} from "./administration/component/fragments/compteclient/puissance/puissance.component";
import {BranchementComponent} from "./administration/component/fragments/demande/branchement/branchement.component";
import { SiteComponent } from './administration/component/fragments/compteclient/site/site.component';
import { TypeDocumentComponent } from './administration/component/fragments/compteclient/type-document/type-document.component';
import { ClientPComponent } from './administration/component/fragments/compteclient/client-p/client-p.component';
import { BuildMobileComponent } from './administration/component/fragments/administration/build-mobile/build-mobile.component';
// @ts-ignore
// @ts-ignore
// @ts-ignore
//


const routes: Routes = [
  {
    path : '',
    redirectTo:'app',
    pathMatch:'full'
  },
  {
    path : 'app',
    component:BaseAppComponent,
    children: [
      {
        /*
         Accueil application avec tous les modules disponibles pour l'utilisateur connecté
        */
        path: '',
        //DISPLAY ALL MODULE ON APP
        component: HomelayoutComponent,
        data: {title: 'Dashbord de Gestion'},
        canActivate : [AuthGuard]
      },
      {
        /*
          DISPLAY ALL SUB MODULE OFF MODULE BY CODE
        */
        path: 'module/:moduleCode',
        component: HomeModuleComponent,
        data: {title: 'Dashbord de Gestion'},
        canActivate : [AuthGuard]
      },
      {
        path:"me",
        component:ProfilUtilisateurComponent,
        canActivate : [AuthGuard]
      },
      {
        /*
          Dashbord pour  module x : is dynamique :
        */
        path: 'dashbord/:moduleCode',
        component: ApplicationComponent,
        data: {title: 'Dashbord de Gestion'},
        children:[
          /*{
           path : '' ,
            component: HomeApplicationComponent
          },*/
          //||||||||||||||||||||MDOULE ADMINISTRATION  ||||||||||||||||\\\\\\\\
          {
            path:"administration/user",
            component:UtilisateurComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"administration/profil",
            component:ProfilComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"administration/module",
            component:ModuleComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"administration/sousmodule",
            component:SousModuleComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"administration/agence",
            component:AgenceComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"administration/service",
            component:ServiceComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"administration/settings/plateforme",
            component:PlateformeComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"administration/settings/action",
            component:ActionComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"administration/build_mobile",
            component:BuildMobileComponent,
            canActivate : [AuthGuard]
          },
          //||||||||||||||||||||MDOULE PARTENAIRE  ||||||||||||||||\\\\\\\\
          {
            path:"partenaire/partenaire",
            component:PartenaireComponent,
            canActivate : [AuthGuard]
          },

          //||||||||||||||||||||MDOULE Compte Client  ||||||||||||||||\\\\\\\\
          {
            path:"client/client",
            // component:ClientComponent,
            component: ClientPComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"client/abonnement",
            component:AbonnementComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"client/compteur",
            component:CompteurComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"client/typeabonnement",
            component:TypeAbonnementComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"client/facture",
            component:FactureComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"client/typeclient",
            component:TypeClientComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"client/tarif",
            component:TarifComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"client/limit_T1",
            component:LimitT1Component,
            canActivate : [AuthGuard]
          },
          {
            path:"client/typepiece",
            component:TypePieceComponent,
            canActivate : [AuthGuard]
          },
            {
            path:"client/zonetarifaire",
            component:Zone_tarifaireComponent,
            canActivate : [AuthGuard]
          },

            {
            path:"client/puT1_redevance",
            component:PuT1_redevanceComponent,
            canActivate : [AuthGuard]
          },
            {
            path:"client/puissance",
            component:PuissanceComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"client/site",
            component:SiteComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"client/distance",
            component:DistanceComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"client/longueur",
            component:LongueurComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"client/type_branchement",
            component:TypeBranchementComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"client/base_prix",
            component:BasePrixComponent,
            canActivate : [AuthGuard]
          },



          //||||||||||||||||||||MDOULE Gestion Demande  ||||||||||||||||\\\\\\\\
          {
            path:"demande/reclamation",
            component:ReclammationComponent,
            canActivate : [AuthGuard]
          },
          {
            path: 'demande/reclamation/message',
            component: MessageComponent,
            canActivate: [AuthGuard]

          },
          {
            path:"demande/typereclamation",
            component:TypeReclamationComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"demande/incident",
            component:IncidentComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"demande/clientdemandemodif",
            component:ModificationClientComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"demande/branchement",
            component:BranchementComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"demande/type_processus",
            component:TypeProcessusComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"demande/demande_processus",
            component:DemandeProcessusComponent,
            canActivate : [AuthGuard]
          },
          //||||||||||||||||||||MDOULE Reporting  ||||||||||||||||\\\\\\\\

          {
            path:"reporting/paiement",
            component:PaiementComponent,
            canActivate : [AuthGuard]
          },
          {
            path:"reporting/checkTransactionNoEffect",
            component:TroubleshootComponent,
            canActivate : [AuthGuard]
          },
          {
            path: "client/type_document_frais",
            component: TypeDocumentComponent,
            canActivate : [AuthGuard]

          }

        ],
        canActivate : [AuthGuard],

      }


    ],
    canActivate : [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Connexion'
    }
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      title: 'Connexion'
    }
  },
  {
    path: 'test',
    component : ClientPComponent
  },
  {
    path: '**',
    redirectTo: 'app'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false,enableTracing:false, onSameUrlNavigation:'reload' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
