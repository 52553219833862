import { AfterViewInit, Component, OnDestroy, OnInit,ViewChild } from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import { Translatable } from 'src/app/Translatable';
import {HttpClient} from "@angular/common/http";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {environment} from "../../../../../../environments/environment";
import {valuesys} from "../../../../../../options";
import { build_mobile } from 'src/app/administration/model/db';
import { v4 as uuidv4 } from 'uuid';
import {Subject} from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: 'app-build-mobile',
  templateUrl: './build-mobile.component.html',
  styleUrls: ['./build-mobile.component.css']
})
export class BuildMobileComponent extends Translatable implements  OnInit, AfterViewInit,OnDestroy {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  // @ts-ignore
  dtTrigger: Subject = new Subject();

  public buildMobiles : build_mobile[] = [];
  dtOptions: DataTables.Settings = {};
  private lang_ = Translatable.getLang();

  constructor(
              private auth : AuthServiceService,
              private http: HttpClient,
              private httpClient: HttpClient,
            ) 
  {
    super();
  }

  ngOnInit() {
      this.auth.initAutority("MDL");
      this.initDatatable();
      this.getDataListMobile();
  }

  async getDataListMobile()
  {
    let response: any = await this.httpClient.get<any>(
      environment.build_mobile,
      valuesys.httpAuthOptionsProcess()
    ).toPromise();
    
    if(response["data"] != null)
    {
      this.buildMobiles = response["data"];
      this.rerender();
    }
    
  }

  async addNewBuild()
  {

    Swal.fire({
      title: 'Veillez entrer l\'identification du build',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Enregistrer',
      showLoaderOnConfirm: true,
      preConfirm: async (name_build) => {

        if(name_build != "")
        {
          let response: any = await this.httpClient.post<any>(
            environment.build_mobile,
            {
              "label" : name_build,
              "state" : 0,
              "version" : uuidv4()
            },
            valuesys.httpAuthOptionsProcess()
          ).toPromise();

          if(response["code"] == 201){
            this.getDataListMobile();
            return response;
          }else{
            return false;
          }
          
        }else{
          return false;
        }
      
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      console.log(result);
      
    })

    
  }

  async updateBuild(build : build_mobile)
  {

    Swal.fire({
      title: 'Modifier le nom du build',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      inputValue : build.label,
      showCancelButton: true,
      confirmButtonText: 'Enregistrer',
      cancelButtonText : "Annuler",
      showLoaderOnConfirm: true,
      preConfirm: async (name_build) => {

        if(name_build != "")
        {
          let response: any = await this.httpClient.put<any>(
            environment.build_mobile + "/" + build.id + "",
            {
              "label" : name_build,
            },
            valuesys.httpAuthOptionsProcess()
          ).toPromise();

          if(response["code"] == 201){
            this.getDataListMobile();
            return response;
          }else{
            return false;
          }
          
        }else{
          return false;
        }
      
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      console.log(result);
      
    })

    
  }
  
  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(){
    this.dtTrigger.next();
  }

  initDatatable() {
    
    this.dtOptions = {
      lengthMenu: [[10, 20, 50,100,200,300,500, -1], ["10", "20", "50","100","200","300","500", "Tout"]],
      processing: true,
      ordering : false,
      language : {
        url: 'assets/i18n/'+this.lang_+'/datatable_fr.json'
      }
    };
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }


}
