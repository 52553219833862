import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Translatable} from "../../../../../Translatable";
import {DataTableDirective} from "angular-datatables";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {environment} from "../../../../../../environments/environment";
import * as $ from "jquery";
import {valuesys} from "../../../../../../options";
import Swal from "sweetalert2";
import {agence} from "../../../../model/db";

@Component({
  selector: 'app-agence',
  templateUrl: './agence.component.html',
  styleUrls: ['./agence.component.css']
})
export class AgenceComponent extends  Translatable  implements  OnInit {

@ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  title:string ;
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  public agences :agence[] = [] ;
  public agence:agence = new agence();
  public agence_details: agence;
  public agence_error : agence ;
  constructor(private activatedRoute: ActivatedRoute,
    private auth : AuthServiceService,
    private http: HttpClient,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private helper: HelperService,
    private modalService: BsModalService,
    private ngxService: NgxUiLoaderService,
    private route: Router
) {
    super();
  }

  async ngOnInit() {
    this.auth.initAutority("AGC");
    this.initDatatable()
  }
  initDatatable() {
    this.isAdd = true;
    this.isValid = false;
    this.title = this.__("agence.agences");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "name"
      },
      {
        "data": "adresse"
      },

      {
        "data": "latitude"
      },

      {
        "data": "longitude"
      }
    ];
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.agence + "?" + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
        this.agences = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data: []
        });
      });
    }
  }


  async openModal(template:TemplateRef<any>,currenAgence:agence =null,elem) {

    this.agence_error = new agence()
    elem.disabled = true ;
    if(currenAgence == null){
      this.isAdd = true ;
      this.agence = new agence() ;
      this.titleModal = this.__('agence.add') ;
    }else {

      this.isAdd = false ;
      this.titleModal = this.__('agence.edit') ;
      this.agence = currenAgence ;

    }
    elem.disabled = false ;
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});

  }
  close(){
    this.modalRef.hide()
    this.isValid = false ;
    this.agence = new agence()
    this.titleModal = this.__('agence.edit') ;
    this.agence_error = new agence();
  }
  async addUserForm(){
    this.agence_error = new agence()
    this.isValid = true ;
    if(this.isAdd ){
      let res:any =  await this.http.post<any>(environment.agence,this.agence,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        this.agences.push( res.data);
        this.close()
      }else if(res.code === 400){
        for (let item in res.data){
          this.agence_error[item] = res.data[item][0] ;
        }
        this.agence_error = <agence> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else {
      console.log(this.agence);
      let res =  await this.http.put<any>( environment.agence + '/'+this.agence.id,this.agence,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        this.close()
      }else if(res.code === 400){
        this.agence_error = <agence> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    this.isValid = false ;


  }
  async openModalDetails(template: TemplateRef<any>,currentagence:agence =null,elm) {
    elm.disabled = true
    this.titleModal = this.__('label_details') ;
    this.agence_details = currentagence ;
    console.log(this.agence_details)
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});
    elm.disabled = false
  }

  async toggleState(agence:agence,state:number,btn:any){

    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.agence+`/${agence.id}/${state}/state`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      agence.state = toogle.data.state

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }
  delete(deletedUser:agence,elm){
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.agence+"/" + deletedUser.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.agences= this.agences.filter(function (item,index) {
            return item.id != deletedUser.id
          })
          Swal.fire(
            this.__('msg.deleted'),
            sup.msg,
            'success'
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          this.__('msg.canceled'),
          this.__('msg.canceled_delete'),
          'error'
        )
      }
    })
  }
}
