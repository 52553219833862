import {Router} from "@angular/router";
import {inject, Injectable} from "@angular/core";
import {low} from "../assets/i18n/low";
import {Auth, profilage_droit, profilage_module, profilage_sous_module} from "./administration/model/db";

declare var require: any;

export class Translatable {

    static data : any = {};
    static lang :any = {} ;
    private lang :any;
  public partener:string  = "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  _controleNumber($event){

    $event.target.value = $event.target.value.replace(/[^0-9]/gi,'');

  }
  _controleNumberWithPoint($event){

    $event.target.value = $event.target.value.replace(/[^0-9.]/gi,'');

  }

    constructor(){

        if(!Translatable.lang.current){
            let lang = localStorage.getItem('lang') || window.navigator.language;

            if(!['fr', 'en'].includes(lang)){
                lang = 'fr';
            }

            Translatable.lang.current = lang;
            localStorage.setItem('lang', lang);

            Translatable.data = require('../assets/i18n/'+lang+'/lang.json');
        }

        this.lang = Translatable.lang;
    }

    static getLang(){
        // @ts-ignore
      return (new Translatable()).getLang().current;
    }

    public __(key) {
        try {
            let path = `Translatable.data.${key} || '${key}'`;
              //console.log("AAZ",path)
            return eval(path);
        } catch (e) {
            return key ;
        }
    }
   toDataURL(url, callback) {
    let xhr = new XMLHttpRequest();
    xhr.onload = function() {
      let reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }


 /* toDataURL('https://www.gravatar.com/avatar/d50c83cc0c6523b4d3f6085295c953e0', function(dataUrl) {
    console.log('RESULT:', dataUrl)
  })*/


    getLang(){
        return this.lang;
    }

  public base64Encoding(data){
    return btoa(data);
  }

  public base64Decoding(data){
    return atob(data);
  }



/*_code_(code){
  let __ = low ;
  let path = `__.${code}`;
  try {
    return  eval(path)
  } catch (e) {
   // console.log(e)
    return path + "no exist"
  }
}*/
jirama(item:string = 'logo'){
      switch (item) {
        case 'logo':
          return "/assets/theme/gestion/images/jirama/logo.png";
        case 'bg':
          return "/assets/theme/gestion/images/jirama/bg.png";
        default :
          return "";
      }
}
 public allowedExtension = [
    "ase",
    "art",
    "bmp",
    "blp",
    "cd5",
    "cit",
    "cpt",
    "cr2",
    "cut",
    "dds",
    "dib",
    "djvu",
    "egt",
    "exif",
    "gif",
    "gpl",
    "grf",
    "icns",
    "ico",
    "iff",
    "jng",
    "jpeg",
    "jpg",
    "jfif",
    "jp2",
    "jps",
    "lbm",
    "max",
    "miff",
    "mng",
    "msp",
    "nitf",
    "ota",
    "pbm",
    "pc1",
    "pc2",
    "pc3",
    "pcf",
    "pcx",
    "pdn",
    "pgm",
    "PI1",
    "PI2",
    "PI3",
    "pict",
    "pct",
    "pnm",
    "pns",
    "ppm",
    "psb",
    "psd",
    "pdd",
    "psp",
    "px",
    "pxm",
    "pxr",
    "qfx",
    "raw",
    "rle",
    "sct",
    "sgi",
    "rgb",
    "int",
    "bw",
    "tga",
    "tiff",
    "tif",
    "vtf",
    "xbm",
    "xcf",
    "xpm",
    "3dv",
    "amf",
    "ai",
    "awg",
    "cgm",
    "cdr",
    "cmx",
    "dxf",
    "e2d",
    "egt",
    "eps",
    "fs",
    "gbr",
    "odg",
    "svg",
    "stl",
    "vrml",
    "x3d",
    "sxd",
    "v2d",
    "vnd",
    "wmf",
    "emf",
    "art",
    "xar",
    "png",
    "webp",
    "jxr",
    "hdp",
    "wdp",
    "cur",
    "ecw",
    "iff",
    "lbm",
    "liff",
    "nrrd",
    "pam",
    "pcx",
    "pgf",
    "sgi",
    "rgb",
    "rgba",
    "bw",
    "int",
    "inta",
    "sid",
    "ras",
    "sun",
    "tga"
  ];
  class(name: string) {
    let  ext = name.split('.').reverse()[0];
   // console.log('EXT',ext)
    if(ext === 'pdf'){
      return 1;
    }
    let isImg =   this.allowedExtension.filter((item) => item === ext).length > 0;
    if(isImg){
      return  'col-md-6'
    }
    return  'col-md-12'

  }
  isImg(name: string) {
    return this.class(name) === 'col-md-6';
  }
  //authority
  public actionModule(): profilage_droit[]{
    if(window['actions']){
      return window['actions'] ;
    }
    let codeSousModule:Array<string> = window['authority']['sous_module'] || [] ;
    let codeModule:Array<string> = window['authority']['module'];
   // console.log('WWW',codeModule)
    let  user =<Auth>  window['authority']['user'];
    try {
      let module: profilage_module[] = user.modules.filter((item:profilage_module)=>  codeModule.indexOf(item.code)!==-1);
     // console.log('mod',module);
      let sousModule:profilage_sous_module []=[];
      if(module){
        {
          for (let i=0 ;i< module.length ;i++){
             let sous_module_ = module[i].sous_modules.filter((item)=>  codeSousModule.indexOf(item.code) !== -1);
             //console.log('OK SOUS MODULE',sous_module_)
             if(sous_module_){
               sousModule.push(...sous_module_);
             }
          }
        }
        let actions:profilage_droit[]=[];
        for (let i= 0;i< sousModule.length;i++){
          actions.push(...sousModule[i].actions)
        }
        //console.log("ASC",actions,sousModule,codeSousModule)
      return   window['actions'] = actions;
      }
      return [];
    } catch (e) {
      return  [] ;
    }
  }
  autority(codeAction) : boolean{
    let  user = <Auth> window['authority']['user'];
    if(user.info.admin === 1){
      return true ;
    }
   // console.log('oo',this.actionModule());
    return !!this.actionModule().find((item)=> item.code === codeAction && item.state ===1);
  }


}
