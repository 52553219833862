
import {Component, OnInit, TemplateRef} from '@angular/core';
import {zone_tarifaire} from "../../../../model/db";
import {ActivatedRoute} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {valuesys} from "../../../../../../options";
import Swal from 'sweetalert2' ;
import {Translatable} from "../../../../../Translatable";
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {environment} from "../../../../../../environments/environment";
import * as $ from "jquery";

@Component({
  selector: 'app-zone_tarifaire',
  templateUrl: './zone_tarifaire.component.html',
  styleUrls: ['./zone_tarifaire.component.css']
})
export class Zone_tarifaireComponent extends Translatable implements OnInit {

  title:string ;
  dtOptions: DataTables.Settings = {};

  titleModal:string ;
  //titleProfilage:string=this.__('profil.profils') ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  modalRef: BsModalRef;




  public zone_tarifaires:zone_tarifaire [] = [];

  public zone_tarifaire:zone_tarifaire  = new zone_tarifaire();
  zone_tarifaire_error: zone_tarifaire;
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService
  ) {
    super();
  }
  async ngOnInit() {
    this.auth.initAutority("ZONE");

    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__("lebel_users");
    this.initDatatable()
    //console.log(" liste des droits "+this.listeModules);


  }

  initDatatable(){
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {data:"value"},
      //{data:"state"}
    ]  ;
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
          .get<DataTablesResponse>(
              environment.zone_tarifaire +"?"+  $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
          ).subscribe(resp => {
        this.zone_tarifaires = resp.data;

        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    }
  }

  async addUserForm(){
    this.isValid = true ;
    if(this.isAdd ){
      console.log(this.zone_tarifaire);
      let res =  await this.http.post<any>( environment.zone_tarifaire,this.zone_tarifaire,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        //this.rerender();
        this.zone_tarifaires.push(res.data);
        this.close()
      }else if(res.code === 400){
        this.zone_tarifaire_error = <zone_tarifaire> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      } else if(res.code === 500){
      let msg = this.__("msg.warningA")
      this.toastr.warning(msg, this.__("msg.warning"));
    }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else {

      console.log("FAL PROF",this.zone_tarifaire);
      let res =  await this.http.put<any>( environment.zone_tarifaire + '/' + this.zone_tarifaire.id,this.zone_tarifaire + '/' + this.zone_tarifaire.id,valuesys.httpAuthOptions()).toPromise();

      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;

        this.close()
      }else if(res.code === 400){
        this.zone_tarifaire_error = <zone_tarifaire> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    this.isValid = false ;

  }
  async openModal(template: TemplateRef<any>,currenZone_tarifaire:zone_tarifaire =null,btn:any) {
    btn.disabled= true
    this.zone_tarifaire_error = new zone_tarifaire();
    if(currenZone_tarifaire == null){
      this.zone_tarifaire = new zone_tarifaire();
      this.isAdd = true ;
      this.titleModal = this.__('zone_tarifaire.add') ;
    }else {
      this.isAdd = false ;
      this.titleModal = this.__('zone_tarifaire.edit') ;
      let res = await this.http.get<any>(environment.zone_tarifaire + '/' + currenZone_tarifaire.id,valuesys.httpAuthOptions()).toPromise();
      this.zone_tarifaire = currenZone_tarifaire ;

    }
    btn.disabled= false

    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }
  close(){
    this.zone_tarifaire_error = new zone_tarifaire();
    this.zone_tarifaire = new zone_tarifaire();
    this.titleModal = "" ;
    this.modalRef.hide()
  }
  delete(deleteZone_tarifaire:zone_tarifaire,btn:any){
    btn.disabled= true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.zone_tarifaire+"/"+deleteZone_tarifaire.id,valuesys.httpAuthOptions()).toPromise();
        if(sup.code == 204){
          this.zone_tarifaires= this.zone_tarifaires.filter(function (item,index) {
            return item.id != deleteZone_tarifaire.id
          });
          btn.disabled= false
          Swal.fire(
              this.__('msg.deleted'),
              sup.msg,
              'success'
          )
        }else {
          btn.disabled= false
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
            this.__('msg.canceled'),
            this.__('msg.canceled_delete'),
            'error'
        )
        btn.disabled= false
      }
    })
  }


  async toggleState(type:zone_tarifaire,state:number,btn:any){
    console.log(btn.hidden = true)
    $(btn).attr('disabled');
    btn.disabled = true;
    let toogle = await this.http.get<any>( environment.zone_tarifaire+`/${type.id}/${state}/state`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      type.state = toogle.data.state

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }


}

