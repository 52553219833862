import {Component, OnInit, TemplateRef} from '@angular/core';
import {Translatable} from "../../../../../Translatable";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {ActivatedRoute} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {environment} from "../../../../../../environments/environment";
import * as $ from "jquery";
import {valuesys} from "../../../../../../options";
import Swal from "sweetalert2";
import {type_abonnement} from "../../../../model/db";

@Component({
  selector: 'app-type-abonnement',
  templateUrl: './type-abonnement.component.html',
  styleUrls: ['./type-abonnement.component.css']
})
export class TypeAbonnementComponent extends Translatable implements OnInit {

  title:string ;
  dtOptions: DataTables.Settings = {};

  titleModal:string ;
  //titleProfilage:string=this.__('profil.profils') ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  modalRef: BsModalRef;




  public type_abonnements: type_abonnement [] = [];

  public type_abonnement:type_abonnement  = new type_abonnement();
  type_abonnement_error: type_abonnement;
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService
  ) {
    super();
  }
  async ngOnInit() {
    this.auth.initAutority("TAB");
    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__("lebel_users");
    this.initDatatable()
    //console.log(" liste des droits "+this.listeModules);


  }

  initDatatable(){
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {data:"name"},
     // {data:"state"}
    ]  ;
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.type_abonnement +"?"+  $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
        this.type_abonnements = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    }
  }

  async addUserForm(){
    this.isValid = true ;
    if(this.isAdd ){
      console.log(this.type_abonnement);
      let res =  await this.http.post<any>( environment.type_abonnement,this.type_abonnement,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        //this.rerender();
        this.type_abonnements.push(res.data);
        this.close()
      }else if(res.code === 400){
        this.type_abonnement_error = <type_abonnement> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else {

      console.log("FAL PROF",this.type_abonnement);
      let res =  await this.http.put<any>( environment.type_abonnement + '/' + this.type_abonnement.id,this.type_abonnement + '/' + this.type_abonnement.id,valuesys.httpAuthOptions()).toPromise();

      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;

        this.close()
      }else if(res.code === 400){
        this.type_abonnement_error = <type_abonnement> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    this.isValid = false ;

  }
  async openModal(template: TemplateRef<any>,currenTypeAbonnement:type_abonnement =null,btn:any) {
    btn.disabled= true
    this.type_abonnement_error = new type_abonnement();
    if(currenTypeAbonnement == null){
      this.type_abonnement = new type_abonnement();
      this.isAdd = true ;
      this.titleModal = this.__('type_abonnement.add') ;
    }else {
      this.isAdd = false ;
      this.titleModal = this.__('type_abonnement.edit') ;
      let res = await this.http.get<any>(environment.type_abonnement + '/' + currenTypeAbonnement.id,valuesys.httpAuthOptions()).toPromise();
      this.type_abonnement = currenTypeAbonnement ;

    }
    btn.disabled= false

    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }
  close(){
    this.type_abonnement_error = new type_abonnement();
    this.type_abonnement = new type_abonnement();
    this.titleModal = "" ;
    this.modalRef.hide()
  }
  delete(deleteTypeAbonnement:type_abonnement,btn:any){
    btn.disabled= true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.type_abonnement+"/"+deleteTypeAbonnement.id,valuesys.httpAuthOptions()).toPromise();
        if(sup.code == 204){
          this.type_abonnements= this.type_abonnements.filter(function (item,index) {
            return item.id != deleteTypeAbonnement.id
          });
          btn.disabled= false
          Swal.fire(
            this.__('msg.deleted'),
            sup.msg,
            'success'
          )
        }else {
          btn.disabled= false
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.__('msg.canceled'),
          this.__('msg.canceled_delete'),
          'error'
        )
        btn.disabled= false
      }
    })
  }


  async toggleState(type:type_abonnement,state:number,btn:any){
    console.log(btn.hidden = true)
    $(btn).attr('disabled');
    btn.disabled = true;
    let toogle = await this.http.get<any>( environment.type_abonnement+`/${type.id}/${state}/state`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      type.state = toogle.data.state

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }


}
