import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse, HttpApiResponse} from "../../../../model/DataTablesResponse";
import {environment} from "../../../../../../environments/environment";
import * as $ from "jquery";
import {valuesys} from "../../../../../../options";
import {Translatable} from "../../../../../Translatable";
import {document, MapPoint, reclamation, transaction} from "../../../../model/db";
import {Subject} from "rxjs";
import Swal from "sweetalert2";
import {Location} from '@angular/common';

@Component({
  selector: 'app-reclammation',
  templateUrl: './reclammation.component.html',
  styleUrls: ['./reclammation.component.css']
})
export class ReclammationComponent extends Translatable implements OnInit {
  /*REFRESH*/
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  // @ts-ignore
  dtTrigger: Subject = new Subject();
 /**
   * datatble variable
   */


  searchInput : any= "";
  filtreSelect : any= 10;
  where : any= "";
  tabExportEtud : any= "";
  searchCustom : any= "";
  endPOint : any= "";
  child : any= "";
  tri : any= "";
  order : any= "";
 
    //--paginate
    public paginateListe : any = [];
    public nextPage : any = [];
    public prevPage : any = [];
    public lastPage : any = [];
    public firstPage : any = [];
    public currentPage : any = [];
    public total : any = [];
    public path : any = [];
    public lastNumPage : any = [];
    public infoData : any = [];
    public cPage_less_2 : number;
    public path_cPage_less_2 : any;
    public cPage_less_1 : number;
    public path_cPage_less_1 : any;
    public cPage_more_1 : number;
    public path_cPage_more_1 : any;
    public cPage_more_2 : number;  
    public path_cPage_more_2 : any;
    public searchColumn: string;
    public columnTemp;
    public childTemp;

   //--end-paginate
  


 /**
  * 
     end datatable variable
  */
 
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }


  private now = new Date();
  private endTime = " 23:59:59" ;
  date_debut : Date ;
  date_debut_query : string = '';
  date_fin : Date ;
  date_fin_query : string="";

  formatQuery(){
    this.date_debut_query = this.dateFormating(this.date_debut);
    this.date_fin_query = this.dateFormating(this.date_fin);
  }

  dateFormating(date) : string
  {
    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return year+'-' + month + '-'+dt;
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  /*REFRESH*/
  title: string;
  dtOptions: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal: string;
  isAdd: boolean = true;
  isValid: boolean = false;
  isSearch: boolean = false;
  public reclamations: reclamation[] = [];
  public reclamation: reclamation = new reclamation();
  public reclamation_details: reclamation;
  public documents: document [];
  public time_depagane = "00:00" ;
  public mapPoint:MapPoint =  new MapPoint();
  userConnected: any;
  showEnvelop: boolean = false;

  // @ts-ignore
  @ViewChild('showDetails') detailsModal : TemplateRef<any>
  date_depagane :string = (new Date()).toISOString().substr(0,10);
  public status : string = "8";

  constructor(private activatedRoute: ActivatedRoute,
              private auth: AuthServiceService,
              private http: HttpClient,
              private httpClient: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService,
              private route: Router,
              private router: ActivatedRoute,
              private location : Location
  ) {
    super();
  }

  async ngOnInit() {
    this.title = this.__("reclamation.reclamation");
    this.userConnected = this.auth.account();
    this.auth.initAutority("REC");
    let that:any = this;
      let query = ""
      if (this.status != "8"){
        query = `,statut|e|${this.status}`
      }
      this.date_debut = new Date();
      this.date_fin = this.date_debut;
      this.formatQuery()
    this.child= `child=conversation_message,userStart,client,userValid,abonnement,typeReclamation,reclamation`;
    this.endPOint = environment.reclamation+"?page=1";
    this.getUrlDatatable(this.endPOint)
    // this.initDatatable()
      
    this.search();

    /*NOTIFICATION*/
    let id = +this.router.snapshot.queryParams.id || null ;
    let res:any = await this.httpClient.get( environment.reclamation + `/${id}?child=conversation_message,userStart,client,userValid,abonnement`,valuesys.httpAuthOptions()).toPromise()
    console.log(res.data,"message with conversation")
    this.reclamation = res.data || new reclamation();
    if(this.reclamation.id){
      this.openModalDetails(this.detailsModal,this.reclamation,{}).then(_ =>{
        this.location.go('/app/dashbord/DEM/demande/reclamation');
      })


    }
    /*NOTIFICATION*/
  }

  initDatatable() {
    this.isAdd = true;
    this.isValid = false;
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "num_dossier"
      },
      {
        "data": "date_reclammation"
      },
      {
        "data": "typeReclamation",
        "name": "typeReclamation.name",
      },

      {
        "data": "name"
      },
      {
        "data": "abonnement",
        "name": "abonnement.num_compteur"
      }
      ,
      /*{
        "data": "name",
      },
      {
        "data": "abonnement",
        "name": "abonnement.num_compteur",
      },*/

      {data: "state", searchable: false},
      {data: "state", searchable: false},
    ];
    this.dtOptions.order=[[1,'desc']];
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      if(this.isSearch == true){
        if (this.date_debut == undefined){
          this.date_debut = this.date_fin
        }
        if (this.date_fin == undefined) {
          this.date_fin = this.date_debut
        }

        let that:any = this;
        new Promise((resolve, reject)=>{
          let query = ""
          if (this.status != "8"){
            query = `,statut|e|${this.status}`
          }
          this.formatQuery()
          this.http
              .get<DataTablesResponse>(
                  environment.reclamation + `?child=conversation_message,userStart,client,userValid,abonnement&where=date_reclammation|se|${this.date_debut_query },date_reclammation|ie|${this.date_fin_query  + this.endTime}${query}&` + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
              ).subscribe(resp => {
            resolve(resp.data);

            console.log(this.reclamations)

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
        }).then((result)=>{


          console.log("resssuuullllllttttt",result)
            that.reclamations = result;
            that.reclamations.forEach(reclamation => {
              if(reclamation.conversation_message[0] != null){
                reclamation.nbr_message_unread = that.countMessageUnread(reclamation.conversation_message[0].message);
                console.log(reclamation.nbr_message_unread,"message unread")
              }
            });

            
        });


      }else {

        let that:any =  this;
        new Promise((resolve,reject)=>{
          let query = ""
          if (this.status != "8"){
            query = `&where=statut|e|${this.status}`
          }
          this.http
              .get<DataTablesResponse>(
                  environment.reclamation + `?child=conversation_message,userStart,client,userValid,abonnement${query}&` + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
              ).subscribe(resp => {

                //console.log(resp.data[0].conversation_message[0].message,'respons pory lelnaaaaaaaaaa',this.countMessageUnread(resp.data[0].conversation_message[0].message))
                //izy
            resolve(resp.data);
            console.log(this.reclamations)

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
        }).then((result)=>{
            that.reclamations = result;
            that.reclamations.forEach(reclamation => {
              if(reclamation.conversation_message[0] != null){
                reclamation.nbr_message_unread = that.countMessageUnread(reclamation.conversation_message[0].message);
              }
            });
        })
        
      }
    }
  }

  countMessageUnread(message: any[]){
    console.log(message,'message in function')
    let t = 0;
    for(let i=0;i<message.length;i++){
      if(message[i].statut == 0 && message[i].envoyeur == 1){
        t += 1;
      }
 
    }
    return t;
  }

  close() {
    this.modalRef.hide()
    this.isValid = false;
    this.message = '';
    this.reclamation = new reclamation()
    this.documents = [] ;
  }

  async openModalDetails(template: TemplateRef<any>, currentreclamation: reclamation = null, elm) {
    elm.disabled = true
    this.titleModal = this.__('label_details');
    this.reclamation_details = currentreclamation
    let {data} = <HttpApiResponse>await this.http.get<any>(environment.document + "?where=reclamation_id|e|" + currentreclamation.id, valuesys.httpAuthOptions()).toPromise();
    this.documents = <Array<document>>data;
    console.log(this.reclamation_details)
    this.mapPoint.lat = +this.reclamation_details.latitude;
    this.mapPoint.lng = +this.reclamation_details.longitude;
    this.mapPoint.texto = this.reclamation_details.name;
    this.modalRef = this.modalService.show(template, {class: "modal-md", ignoreBackdropClick: true});
    elm.disabled = false
  }

  async toggleState(reclamation: reclamation, state: number, btn: any={},msg :  any ='') {

    btn.disabled = true;

    Swal.fire({
      title: this.__('swal.confirmTitle'),
      text: this.__('swal.confirmMsg'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.__('swal.no'),
      confirmButtonText: this.__('swal.yes')
    }).then(async (result) => {
      /*if (result.value) {
        Swal.fire(
          'swal.successTitle',
          'swal.success',
          'success'
        )
      }*/
      if (result.value) {
        let toogle = await this.http.get<any>(environment.reclamation + `/${reclamation.id}/${state}/statut?msg=${msg}`, valuesys.httpAuthOptions()).toPromise();
        if (toogle.code == 201) {
          this.toastr.success(toogle.msg, this.__("msg.success"));
          reclamation.statut = toogle.data.statut;
          this.rerender();

        } else {
          this.toastr.error(toogle.msg, this.__("msg.error"));
        }
        btn.disabled = false;
      }
      btn.disabled = false;
    })


  }

  loadItem(btninfo: HTMLAnchorElement) {
    $(".marker a").removeClass('activeTab')
    btninfo.classList.add('activeTab')
  }

  download(document: document, btn: any) {
    btn.disabled = true
    this.toDataURL(document.name, (dataUrl) => {
      btn.disabled = false
      window['download'](dataUrl, document.name, dataUrl.split(';')[0].split(":")[1]);

      return false

    })
  }

  /*toggleStaeSave(reclamation: reclamation) {
    Swal.fire({
      title: this.__('swal.msg'),
      icon: 'success',
      html: ` <div class="form-group">
       <textarea style="border: 1px solid #ddd" class="form-control border-danger" id="msg" rows="3"></textarea>
      </div>`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:
        this.__('swal.send'),
      // confirmButtonAriaLabel: 'Thumbs up, great!',
      cancelButtonText:
        this.__('swal.no')
      //  cancelButtonAriaLabel: 'Thumbs down'
    }).then((result) => {
      if (result.value) {
        this.toggleState(reclamation,1,{},$("#msg").val())
      }
    });
  }*/
//https://sweetalert2.github.io/
  saveStatutSave(reclamation:reclamation,state:any,btn:any){
    btn.disabled =true
    // @ts-ignore
    Swal.fire({
      title: this.__('swal.num_doss'),
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        // @ts-ignore
        required:true,
      },
      validationMessage :this.__("validation.required")
      ,
      showCancelButton: true,
      cancelButtonText:  this.__('swal.no'),
      confirmButtonText:  this.__('swal.send'),
      showLoaderOnConfirm: true,
      preConfirm: (num_dossier) => {
        this.http.get<any>(environment.reclamation + `/${reclamation.id}/${state}/statut?num_dossier=${num_dossier}`, valuesys.httpAuthOptions()).toPromise()
          .then(response => {
            if (response.code === 201) {
              if(reclamation.conversation_message[0].length !== 0){
                const id_conv = reclamation.conversation_message[0].id;
                this.http.get(`${environment.conversation}/${id_conv}/${1}/statut`,valuesys.httpAuthOptions()).toPromise();
              }
              this.toastr.success(response.msg, this.__("msg.success"));
              reclamation.statut = response.data.statut;
              this.rerender();
            }else if (response.code === 500) {
          this.toastr.warning("Le numéro de dossier doit être unique ", this.__("msg.warning"));
        }

            else {
              this.toastr.error(response.msg, this.__("msg.error"));
            }
          })
          .catch(error => {
            Swal.showValidationMessage(
              `${this.__('msg.error')} : ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    })/*.then((result) => {
      if (result.value) {
        Swal.fire({
          title: `${result.value.login}'s avatar`,
          imageUrl: result.value.avatar_url
        })
      }
    })*/
    btn.disabled =false
  }

  async openModalPlanfiDepagage(template: TemplateRef<any>, currentreclamation: reclamation = null, elm) {
    elm.disabled = true
    this.reclamation_details = currentreclamation;
    this.initTime()

    this.modalRef = this.modalService.show(template, {class: "modal-md", ignoreBackdropClick: true});
    elm.disabled = false
  }
  async planifier(submitPlanification:any){
    submitPlanification.disabled = true
    let date = this.date_depagane.split('/').reverse().join('/') +" "+ this.time_depagane;
    let {data,code,msg} = <HttpApiResponse>await this.http.put<any>(environment.reclamation + "/planif/" + this.reclamation_details.id,{date_depagane:date}, valuesys.httpAuthOptions()).toPromise();
    if(code ==201){
      this.close()
      this.toastr.success(this.__("reclamation.successPlanication"), this.__("msg.success"));
      this.rerender();
    }else {
      this.toastr.error(msg, this.__("msg.error"));
    }
    submitPlanification.disabled = false

  }

planaifBtnTille:string = ''
  initTime(){
    if(!this.reclamation_details.id || !this.reclamation_details.date_depagane  ){
      this.planaifBtnTille = this.__('btn.valide');
      this.titleModal = this.__('tooltip.panifDepanageTitleAdd');
      this.time_depagane = "00:00" ;
      this.date_depagane  = (new Date()).toISOString().substr(0,10);
    }else {
      this.planaifBtnTille = this.__('btn.edit');
      this.titleModal = this.__('tooltip.panifDepanageTitleEdit');
      this.time_depagane = this.reclamation_details.date_depagane.toString().substr(11,5) ;
      console.log(this.time_depagane)
      this.date_depagane  = this.reclamation_details.date_depagane.toString().substr(0,10) ;
    }
  }
  async openModal(template:TemplateRef<any>,currenReclamation:reclamation =null,elem) {

    elem.disabled = true ;
    this.titleModal=this.__('reclamation.infoscom')
    this.reclamation=currenReclamation;
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});
    elem.disabled = false ;

  }
  async addInfoComplemente() {

    let {code,msg} = <HttpApiResponse> await this.http.put<any>(environment.reclamation+`/infoComp/${this.reclamation.id}`,{
      message:this.message,
      sujet: this.reclamation.code_probleme,
      acteur_jirama: this.userConnected.info.id
    },valuesys.httpAuthOptions()).toPromise();
    if (code == 200) {
      this.toastr.success(this.__('incident.succes_com'), this.__("msg.success"));
      this.showEnvelop = true;

    } else {
      this.toastr.error(msg, this.__("msg.error"));
      this.showEnvelop = false;
    }
    this.close()
  }
  message:string =""

  async exportPdf(btnPdf) {

    btnPdf.disabled = true
    if(this.date_debut == undefined && this.date_fin == undefined){
      await this.helper.exportPdf(this.print(this.reclamations),this.__("print.list") + this.__("reclamation.reclamation"));
    }else {
      await this.helper.exportPdf(this.print(this.reclamations),this.__("reclamation.reclamation")  + this.__("print.from")  + this.date_debut_query.split('-').reverse().join('-') + this.__("print.to") + this.date_fin_query.split('-').reverse().join('-'));
    }
    btnPdf.disabled = false
  }

  getTypeReclcamation(type_rec:any,type_reclamation_id: string|number){
      type_rec.forEach((key,value) => {
        if(key == type_reclamation_id){
          return type_rec.get(key)
        }
      });
  }

  async exportExcel(btnExcel) {
    console.log(this.reclamations,"1111111111")

    btnExcel.disabled = true
    if(this.date_debut == undefined && this.date_fin == undefined){
      await this.helper.exportExcel(this.print(this.reclamations),this.__("print.list") + this.__("reclamation.reclamation"));
    }else {
      await this.helper.exportExcel(this.print(this.reclamations),this.__("reclamation.reclamation") + this.__("print.from")  + this.date_debut_query.split('-').reverse().join('-') + this.__("print.to") + this.date_fin_query.split('-').reverse().join('-'));
    }
    btnExcel.disabled = false
  }
  print(reclamation:reclamation[]){
    console.log(reclamation)
    const typeReclamation:any = new Map(
      [
        ["E","Eléctrcité"],
        ["W","Eau"],
        ["R","Ratissage"],
        ["C","Commercial"]
      ]
    )
    return  reclamation.map((reclamation)=>{
      //console.log("sssssss")
      let t = {}
      t[this.__('reclamation.num_dossier')] = reclamation.num_dossier
      t[this.__('reclamation.date_reclammation')] = reclamation.date_reclammation
      t[this.__('type_reclamation.code')] = reclamation.code_probleme
      t[this.__('type_reclamation.type_reclamation')] = this.getTypeReclcamation(typeReclamation, reclamation.type_reclamation_id)
      t[this.__('reclamation.name')] = reclamation.name
      t[this.__('reclamation.description')] = reclamation.description
      t[this.__('abonnement.num_compteur')] = (reclamation.abonnement !== null ) ? reclamation.abonnement.num_compteur : ""
      t[this.__('print.statut')] =  (reclamation.statut == 0 ? this.__("tooltip.running_trated") : (reclamation.statut == 1) ? this.__('tooltip.state_save') : (reclamation.statut == 2) ? this.__('tooltip.traiter') : this.__('tooltip.state_planif'))
      return t ;
    })  || [];

  }

  cancel() {
    this.isSearch = false;
    this.date_debut = undefined
    this.date_fin = undefined
    this.status= "8"
    this.rerender();
  }

  search() {
    this.isSearch = true;

    console.log("debut: ",this.date_debut)
    console.log("fin: ",this.date_fin)

    if (this.date_debut || this.date_fin) {
      this.rerender();
    }else {
      this.isSearch = false;
      this.date_debut = undefined
      this.date_fin = undefined
      this.rerender();
    }
  }


  changerStatus(event){
    this.status=event;
  }

  async goToConversation(t:any){
 
    const conversation_id = t.conversation_message[0].id;
    const responseUp:any = await this.http.put(`${environment.message}/${conversation_id}/lireMessageClient`,{},valuesys.httpAuthOptions()).toPromise();
    console.log(responseUp)
    if(responseUp.code == 201){
      this.route.navigate(['app','dashbord','DEM','demande','reclamation','message'],{state: {
        reclamation: t
      }})
    }
   
  }


  /**
   * datatable function
   */


   async getUrlDatatable(url,col = "",refElement?: any,child?:string){



    let query = ""
    if (this.status != "8"){
      query = `,statut|e|${this.status}`
    }
    console.log("avant",this.date_debut);
    console.log("avant fin",this.date_fin);
    this.formatQuery();
    console.log("apres",this.date_debut_query);
    console.log("apres fin",this.date_fin_query);

    if(this.date_debut_query != ''){
    this.child = `child=conversation_message,userStart,client,userValid,abonnement,typeReclamation&where=date_reclammation|se|${this.date_debut_query },date_reclammation|ie|${this.date_fin_query  + this.endTime}${query}`;
  }






    if(refElement ){
     this.searchColumn = refElement.value
     this.columnTemp = col;
     this.childTemp = child;
    }
 
    let name = [
      'search_ref',
      'num_client_ref',
      'prenom_ref',
      'nom_ref',
      'mail_ref',
      'telephone_ref',
      'typeclient_ref',
    ]
     
     if(col != "" || this.searchColumn){

       if(this.searchColumn !="" && this.searchColumn !="undefined"){
         this.searchCustom = "&child_where="+this.childTemp+"|"+this.columnTemp+"|l|"+this.searchColumn+"";
       }else if(this.searchColumn == ""){
        this.searchCustom = "";
       }
     }else{
       if(this.searchInput !="" && this.searchInput !="undefined"){
         this.searchCustom = "&child_where_or=reclamation|num_dossier|l|"+this.searchInput+",reclamation|date_reclammation|l|"+this.searchInput+",abonnement|num_compteur|l|"+this.searchInput+",reclamation|code_probleme|l|"+this.searchInput+",reclamation|name|l|"+this.searchInput+"";
       }else if(this.searchInput == ""){
        this.searchCustom = "";
       }
     }
     
 
     let filtre : any= "";
     if(this.filtreSelect !="" && this.filtreSelect !="undefined"){
       filtre = "&per_page=" + this.filtreSelect;
       console.log("filter",this.filtreSelect)
     }
     let triage : any= "";
     if(this.tri !="" && this.tri !="undefined"){
       triage = "&__order__="+ this.order+","+ this.tri;
     }

 
     let toogle = await this.http.get<any>(url +"&"+ this.child + this.searchCustom  + filtre + triage  ,valuesys.httpAuthOptions()).toPromise();
     this.reclamations = toogle.data.data;
     this.reclamations.forEach(reclamation => {
      if(reclamation.conversation_message[0] != null){
        reclamation.nbr_message_unread = this.countMessageUnread(reclamation.conversation_message[0].message);
        console.log(reclamation.nbr_message_unread,"message unread")
      }
    }); 
    
 
     
     this.firstPage = toogle.data.first_page_url;
     this.prevPage = toogle.data.prev_page_url ;
     this.nextPage = toogle.data.next_page_url ;
     this.lastPage = toogle.data.last_page_url ;
     this.lastNumPage = toogle.data.last_page;
     this.path = toogle.data.path;
     this.currentPage = toogle.data.current_page;
     this.total = toogle.data.total;
 
     
 
     this.cPage_less_2 = 0
     this.cPage_less_1 = 0
     if(this.currentPage > 1 ){
       
       if(this.currentPage > 2 ){
         this.cPage_less_2 = this.currentPage - 2
         this.path_cPage_less_2 = this.path +"?page="+this.cPage_less_2;
       }
     
     this.cPage_less_1 = this.currentPage - 1 
     this.path_cPage_less_1 = this.path +"?page="+this.cPage_less_1;
     }
 
     
     this.cPage_more_1 = 0
     this.cPage_more_2 = 0
     if(this.currentPage < this.lastNumPage){
       this.cPage_more_1 = this.currentPage + 1 
       this.path_cPage_more_1 = this.path +"?page="+this.cPage_more_1;
 
       if(this.currentPage < this.lastNumPage - 1 ){
         this.cPage_more_2 = this.currentPage + 2 
         this.path_cPage_more_2 = this.path +"?page="+this.cPage_more_2;
       }
 
     }
 
     let info = this.__('datatable.info').replace('_START_', toogle.data.from)
     let info2 =info.replace('_END_', toogle.data.to)
     this.infoData = info2.replace('_TOTAL_', toogle.data.total)
 
   }
   async triTable(col, order){
     this.tri = col;
     this.order = order;
     this.getUrlDatatable(environment.reclamation+"?page=1")
   }


  

   /**
    * end datatable funciton
    */

}
