import {Component, OnInit, TemplateRef} from '@angular/core';
import {action_profil, modules, profil, profilage_module, profilage_sous_module} from "../../../../model/db";
import {ActivatedRoute} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {valuesys} from "../../../../../../options";
import Swal from 'sweetalert2' ;
import {Translatable} from "../../../../../Translatable";
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {environment} from "../../../../../../environments/environment";
import * as $ from "jquery";

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent extends Translatable implements OnInit {

  title:string ;
  dtOptions: DataTables.Settings = {};

  titleModal:string ;
  titleProfilage:string=this.__('profil.profils') ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  modalRef: BsModalRef;
  droits:any = []
  modules:profilage_module [] = []


  public allDroit:action_profil[] =[];

  public profils:profil [] = [];
  public listeModules:modules[]  = [new modules()];
  public profil:profil  = new profil();
  profil_error: profil;
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService
  ) {
    super();
  }
  async ngOnInit() {
    this.auth.initAutority("PRFL");

    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__("lebel_users");
    this.initDatatable()
    console.log(" liste des droits "+this.listeModules);


  }

  initDatatable(){
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {data:"name"},
      {data:"code"},
     // {data:"state"}
    ]  ;
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
           environment.profil +"?"+  $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
        this.profils = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    }
  }

  async addProfilForm(){
    this.isValid = true ;
    if(this.isAdd ){
      console.log(this.profil);
      let res =  await this.http.post<any>( environment.profil,this.profil,valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        //this.rerender();
        this.profils.push(res.data);
        this.close()
      }else if(res.code === 400){
        //this.toastr.warning(res.data.join('<br>'),"Avvertissement") ;
        this.profil_error = <profil> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else {

      console.log("FAL PROF",this.profil);
      let res =  await this.http.put<any>( environment.profil + '/' + this.profil.id,this.profil + '/' + this.profil.id,valuesys.httpAuthOptions()).toPromise();

      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;

        this.close()
      }else if(res.code === 400){
        this.profil_error = <profil> this.helper.errorForm(res.data);
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      else {
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    this.isValid = false ;

  }
  async openModal(template: TemplateRef<any>,currenProfil:profil =null) {
    this.profil_error = new profil();
    if(currenProfil == null){
      this.profil = new profil();
      this.isAdd = true ;
      this.titleModal = this.__('profil.add') ;
    }else {
      this.isAdd = false ;
      this.titleModal = this.__('profil.edit') ;
      let res = await this.http.get<any>(environment.profil + '/' + currenProfil.id,valuesys.httpAuthOptions()).toPromise();
      this.profil = currenProfil ;

    }

    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }
  close(){
    this.profil_error = new profil();
    this.profil = new profil();
    this.titleModal = "" ;
    this.modalRef.hide()
  }
  delete(deleteProfil:profil){
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.profil+"/"+deleteProfil.id,valuesys.httpAuthOptions()).toPromise();
        if(sup.code == 204){
          this.profils= this.profils.filter(function (item,index) {
            return item.id != deleteProfil.id
          });
          Swal.fire(
            this.__('msg.deleted'),
            sup.msg,
            'success'
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.__('msg.canceled'),
          this.__('msg.canceled_delete'),
          'error'
        )
      }
    })
  }


  async toggleState(type:profil,state:number,btn:any){
    console.log(btn.hidden = true)
    $(btn).attr('disabled');
    btn.disabled = true;
    let toogle = await this.http.get<any>( environment.profil+`/${type.id}/${state}/state`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      type.state = toogle.data.state

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }

  async detail(detailProfil:profil, template:TemplateRef<any>){
    // alert(detailProfil.libelle)
    this.titleProfilage = this.__('profil.profilage') + " : "+ detailProfil.name ;
    this.profil= detailProfil

    let res =  await this.http.get<any>(environment.profilage+"/"+detailProfil.id,valuesys.httpAuthOptions()).toPromise();
    this.modules = res.data;
    this.modalRef = this.modalService.show(template,{class:'modal-lg',ignoreBackdropClick:true});

  }


  async updateAction(action,elm){
    elm.disabled= true;

    if(elm.checked ==true){
      let res =  await this.http.post<any>( environment.profilage ,{action_id:action.id,profil_id:this.profil.id,state:1},valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        action.state = 1 ;
        elm.checked= true;
      }else {
        elm.checked= false;
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else{
      let res =  await this.http.post<any>(environment.profilage,{action_id:action.id,profil_id:this.profil.id,state:0},valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        action.state = 0 ;
        elm.checked= false;
      }else {
        elm.checked= true;
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }

    elm.disabled= false;
  }
  async updateActionAll(action,elm){
    console.log(action)
    let id_droits = action.map((item)=> item.id).join(',');
    elm.disabled= true;

    if(elm.checked ==true){
      let res =  await this.http.post<any>( environment.profilage,{action_id:id_droits,profil_id:this.profil.id,state:1},valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        action = action.map((item)=> {
          item.state = 1 ;
          return item ;
        });
        elm.checked= true;
      }else {
        action = action.map((item)=> {
          if(item.state == 0)
            item.state = 0 ;
          return item ;
        });
        elm.checked= false;
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    else{
      let res =  await this.http.post<any>( environment.profilage,{action_id:id_droits,profil_id:this.profil.id,state:0},valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        action = action.map((item)=> {
          item.state = 0 ;
          return item ;
        });
        elm.checked= false;
      }else {
        action = action.map((item)=> {
          if(item.state == 1)
            item.state = 0 ;
          item.state = 1 ;
          return item ;
        });
        elm.checked= true;
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }

    elm.disabled= false;
  }
  isCheckedAll(s_module:profilage_sous_module){
    return  s_module.actions.filter((item)=>item.state == 1).length ==  s_module.actions.length
  }
  toogleAction(elm,block){

    console.log( elm.className);
    console.log( block.className);
    if(elm.className == "fa fa-eye"){
      elm.className = "fa fa-eye-slash";
      block.className = "show";
    }else if(elm.className == "fa fa-eye-slash") {
      elm.className = "fa fa-eye";
      block.className = "hidden";
    }
  }

}
