import {AfterContentInit, AfterViewChecked, Component, Input, OnInit} from '@angular/core';
import {Auth, profilage_droit, profilage_module, profilage_sous_module} from "../../../model/db";
import {Translatable} from "../../../../Translatable";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../service/auth-service.service";
import {environment} from "../../../../../environments/environment";
declare var $: any;
@Component({
  selector: 'app-app-sider-bar',
  templateUrl: './app-sider-bar.component.html',
  styleUrls: ['./app-sider-bar.component.css']
})
export class AppSiderBarComponent extends Translatable implements OnInit{

 @Input('module') module : profilage_module;
    constructor(private activatedRoute: ActivatedRoute,private router:Router, private auth : AuthServiceService) {
        super()
        //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  firstInit = false;
    user: Auth;
  ngOnInit() {
       this.user=<Auth> this.auth.account();
  }
  init() {
    if(!this.firstInit) {
      var d = $(window),
        e = $("body"),
        f = $("#header"),
        g = $("#header .branding"),
        h = $("#leftmenu"),
        i = $("#controls"),
        j = $(".main_Wrapper"),
        k = $("#navigation"),
        l = $(".sparklineChart"),
        m = $(".slim-scroll"),
        n = $(".collapse-leftmenu"),
        o = $("#wrap"),
        p = $(".offcanvas-toggle"),
        q = k.find("ul").parent("li"),
        r = q.children("a"),
        s = k.children("li").not(q),
        t = s.children("a"),
        u = $(".color-themes .header-theme"),
        v = $(".color-themes .branding-theme"),
        w = $(".color-themes .leftmenu-theme"),
        x = $(".color-themes .color-theme"),
        y = $("#fixed-header"),
        z = $("#fixed-aside"),
        A = $(".toggle-right-leftmenu"),
        B = $(".pickDate"),
        C = ($(".boxs"), $(".boxs .boxs-toggle")),
        D = $(".boxs .boxs-refresh"),
        E = $(".boxs .boxs-fullscreen"),
        F = $(".boxs .boxs-close"),
        G = $(".easypiechart"),
        H = $(".toggle-class"),
        I = $(".colorpicker"),
        J = $(".touchspin"),
        K = $(".datepicker"),
        L = $(".animate-progress-bar"),
        M = $(".counter"),
        N = $(".splash");
      //alert('ok')
      if (q.length > 0) {
        q.addClass("dropdown");
        q.find("ul >.dropdown").addClass("submenu"), r.append('<i class="fa fa-plus"></i>'), r.on("click", function (a) {
          if (j.hasClass("leftmenu-sm") || j.hasClass("leftmenu-xs") || j.hasClass("hz-menu")) return !1;
          var b = $(this),
            c = b.parent("li"),
            d = $(".submenu.open");
          c.hasClass("submenu") || q.not(c).removeClass("open").find("ul").slideUp(), d.not(b.parents(".submenu")).removeClass("open").find("ul").slideUp(), c.toggleClass("open").find(">ul").stop().slideToggle(), a.preventDefault()
        }), q.on("mouseenter", function () {
          h.addClass("dropdown-open"), i.addClass("dropdown-open")
        }), q.on("mouseleave", function () {
          h.removeClass("dropdown-open"), i.removeClass("dropdown-open")
        }), t.on("click", function () {
          q.removeClass("open").find("ul").slideUp()
        });
        $(".dropdown>ul>.active").parent().css("display", "block")
      }
      this.firstInit = true
    }
  }
  filterAction(actions:profilage_droit[]){
    return actions.filter((_)=>_.type_action_id === environment.ID_TYPE_ACTION_LIEN  && ( this.user.info.admin ===1 || _.state === 1))
  }

  filterSousModule(sousmodules:profilage_sous_module[]){

     return  sousmodules.filter((_)=>_.hasOneAction === true || this.user.info.admin === 1)
  }



}
