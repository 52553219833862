import { Component, OnInit } from '@angular/core';
import Login from '../../../../model/Login';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthServiceService} from "../../../../service/auth-service.service";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {Translatable} from "../../../../../Translatable";
import {environment} from "../../../../../../environments/environment";
import {valuesys} from "../../../../../../options";
import {HttpApiResponse} from "../../../../model/DataTablesResponse";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends Translatable implements OnInit {
  login : Login = new Login();
  loginBtn : boolean = false ;
  constructor(private httpClient : HttpClient,private router: Router,
              private route: ActivatedRoute,private auth:AuthServiceService,private toastr: ToastrService,private helper: HelperService, private http: HttpClient) {
  super()
  }

 async ngOnInit() {
  let {code} = await this.httpClient.get<HttpApiResponse>(environment.baseUrl+environment.ping,valuesys.httpAuthOptions()).toPromise();
    if(code === 200){
      this.toastr.success("Tu est deja connecté",'Session valide');
      let uri = this.urlParser(window.localStorage.getItem('return'));
     return  this.router.navigate([ uri.pathname || '/app'])
    }
    this.login = new Login();
  }
  urlParser(href) {
    let l = document.createElement("a");
    l.href = href;
    return l;
  };
  async loginSubmit() {
    try {
      this.loginBtn = true ;
     let response = await this.auth.loginUser(this.login);
      if (response.code === 200) {
       // console.log('ll',response.data.access_token)
        this.auth.setToken(response.data.access_token,response.data.expires_in);
        let uri = this.urlParser(window.localStorage.getItem('return'));
        //console.log("PPPP",uri.pathname);
        await this.auth.getLoginUser(true);
       return  this.router.navigate([ uri.pathname || '/app'])
      } else {
        this.loginBtn = false ;
        this.toastr.error(response.msg, 'Erreur de connexion');
      }
    } catch (e) {
      this.loginBtn = false ;
      this.toastr.error('Veuillez verifier votre connexion internet', 'Erreur de connexion');
    }
  }

}
