import {partenaire, zone_tarifaire} from "../app/administration/model/db";

const BASE_URL = "https://gateway-jirama.numherit-dev.com/" ;
//const BASE_URL="https://gateway.jirama.mg/";


export const environment = {
  production: true,
  baseur : "app/",
  security:true,
  allowAll:true,
  baseUrl : BASE_URL,
  //ws_url : WS_URL,
  //ws_port : WS_PORT,
  authItemName:'__token_api_gate_way_jirama',
  userItemName:'__user_api_gate_way_jirama',
  login:'auth/login',
  userAuth:'auth/me',
  me:'auth/me',
  ping:'auth/ping',
  refresh:'auth/refresh',
  refreshPassword:'auth/refreshPassword',
  user : BASE_URL + "administration/user",
  userRefreshPwd : BASE_URL + "administration/user/refreshPwd",
  profil : BASE_URL + "administration/profil",
  action_profil : BASE_URL + "administration/action_profil",
  profilage : BASE_URL + "administration/profilage",
  plateforme : BASE_URL + "administration/settings/plateforme",
  partenaire  : BASE_URL + "partenaire/partenaire",
  transaction  : BASE_URL + "reporting/paiement",
  reclamation  : BASE_URL + "demande/reclamation",
  incident  : BASE_URL + "demande/incident",
  document  : BASE_URL + "demande/document",
  agence : BASE_URL + "administration/agence",
  service : BASE_URL + "administration/service",
  module : BASE_URL + "administration/module",
  sousmodule : BASE_URL + "administration/sousmodule",
  exportPdf: BASE_URL + "export/pdf",
  exportExcel: BASE_URL + "export/excel",
  client : BASE_URL + "client/client",
  clientRefreshPwd : BASE_URL + "client/client/refreshPwd",
  type_client : BASE_URL + "client/typeclient",
  tarif : BASE_URL + "client/tarif",
  type_piece : BASE_URL + "client/typepiece",
  abonnement : BASE_URL + "client/abonnement",
  type_abonnement : BASE_URL + "client/typeabonnement",
  facture : BASE_URL + "client/facture",
  action : BASE_URL + "administration/settings/action",
  updateAction : BASE_URL + "administration/settings/appendroute",
  genererAction : BASE_URL + "administration/settings/gerateroute",
  type_reclamation : BASE_URL + "demande/typereclamation",
  client_demande_modif : BASE_URL + "demande/clientdemandemodif",
  notification: BASE_URL +  "/messaging/notification",
  CURREN_CODE_COUNTRY : 'mg',
  TIMMER_GUARD:200,
  userEdit: "",
  GOOGLE_MAP:"AIzaSyA1G3kTtiDw6GayLHHCjDknWIlAQO8cB2U",
  ID_TYPE_ACTION_LIEN:1,
  ID_TYPE_CLIENT_ENTREPRISE:2,
  ID_TYPE_CLIENT_ADMINISTRATEUR:3,
  zone_tarifaire  : BASE_URL + "client/zonetarifaire",
  puT1_redevance  : BASE_URL + "client/puT1_redevance",
  limit_T1  : BASE_URL + "client/limit_T1",
  puissance  : BASE_URL + "client/puissance",
  branchement: BASE_URL+"demande/branchement",
  commune: BASE_URL+"administration/commune",
  site  : BASE_URL + "client/site",
  distance  : BASE_URL + "client/distance",
  longueur  : BASE_URL + "client/longueur",
  type_branchement  : BASE_URL + "client/type_branchement",
  base_prix  : BASE_URL + "client/base_prix",
  type_processus  : BASE_URL + "demande/type_processus",
  regularise: BASE_URL + 'jirama/services/regularise',
  demande_processus  : BASE_URL + "demande/demande_processus",
  message: BASE_URL + 'demande/message',
  conversation: BASE_URL + 'demande/conversation',
  type_doc_frais: BASE_URL + "client/type_document_frais",
  troubleshoot  : BASE_URL + "reporting/checkTransaction",
  troubleshootList : BASE_URL + "reporting/checkTransactionNoEffect",
  build_mobile : BASE_URL + "administration/build_mobile"

};
