import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {client_demande_modif, document, MapPoint} from "../../../../model/db";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {DataTablesResponse, HttpApiResponse} from "../../../../model/DataTablesResponse";
import {environment} from "../../../../../../environments/environment";
import * as $ from "jquery";
import {valuesys} from "../../../../../../options";
import Swal from "sweetalert2";
import {Translatable} from "../../../../../Translatable";

@Component({
  selector: 'app-modification-client',
  templateUrl: './modification-client.component.html',
  styleUrls: ['./modification-client.component.css']
})
export class ModificationClientComponent extends Translatable implements OnInit,AfterViewInit,OnDestroy {


  /*REFRESH*/
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  // @ts-ignore
  dtTrigger: Subject = new Subject();
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload()
    });
  }

  private now = new Date();
  private endTime = " 23:59:59" ;
  date_debut : Date ;
  date_debut_query : string = '';
  date_fin : Date ;
  date_fin_query : string="";
  isSearch: boolean = false;
  public status : string = "8";


  formatQuery(){
    let tempStoreD:any = this.date_debut.setHours(this.date_debut.getHours()+3)
    tempStoreD = new Date(tempStoreD);
    let tempStoreF:any = this.date_fin.setHours(this.date_fin.getHours()+3)
    tempStoreF = new Date(tempStoreF);
    
    this.date_debut_query = this.date_debut.toISOString().replace('T',' ').substr(0,10).split('/').reverse().join('-')
    this.date_fin_query = this.date_fin.toISOString().replace('T',' ').substr(0,10).split('/').reverse().join('-')

    console.log("=== QUERY ===")
    console.log("query date debut: ",this.date_debut_query)
    console.log("query date debut: ",this.date_fin_query)
  }

  /*REFRESH*/
  title:string ;
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  public documentsClient = [];
  documents : document [] = [];
  public client_demande_modifs :client_demande_modif[] = [] ;
  public client_demande_modif:client_demande_modif = new client_demande_modif();
  public client_demande_modif_details: client_demande_modif;
  public message : string;
  public mapPoint:MapPoint =  new MapPoint();
  constructor(private activatedRoute: ActivatedRoute,
              private auth : AuthServiceService,
              private http: HttpClient,
              private httpClient: HttpClient,
              private toastr: ToastrService,
              private helper: HelperService,
              private modalService: BsModalService,
              private ngxService: NgxUiLoaderService,
              private route: Router
  ) {
    super();
  }

  async ngOnInit() {
    this.auth.initAutority("DMC");
    this.initDatatable();

    let query = ""
    if (this.status != "8"){
      query = `,statut|e|${this.status}`
    }
    this.date_debut = new Date();
    this.date_fin = this.date_debut;
    this.formatQuery();
  }

  initDatatable() {
    this.isAdd = true;
    this.isValid = false;
    this.title = this.__("client_demande_modif.client_demande_modif");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "date_demande"
      },
      {
        "data" : "abonnement","name" : "abonnement.num_compteur"
      },
      {
        "data": "nom"
      },
      {
        "data": "cin"
      },
      {
        "data": "telephone"
      },
      {
        "data": "email"
      },
      {
        "data": "telephone"
      },
      {
        "data": "address"
      },
      /*{
        "data": "client",
        "name": "client.prenom",
      },
      {
        "data": "client",
        "name": "client.nom"
      },*/
      {data:"state",searchable:false},
    ];
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {

       if(this.isSearch == true){
        if (this.date_debut == undefined){
          this.date_debut = this.date_fin
        }
        if (this.date_fin == undefined) {
          this.date_fin = this.date_debut
        }

        let that:any = this;
        new Promise((resolve, reject)=>{
          let query = ""
          if (this.status != "8"){
            query = `,statut|e|${this.status}`
          }
          this.formatQuery()
          this.http
              .get<DataTablesResponse>(
                   environment.client_demande_modif + `?child=client,abonnement&where=date_demande|se|${this.date_debut_query },date_demande|ie|${this.date_fin_query  + this.endTime}${query}&` + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
              ).subscribe(resp => {
            resolve(resp.data);

            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
        }).then((result)=>{
            that.client_demande_modifs = result;
        });


      }else {

        let that:any =  this;
        new Promise((resolve,reject)=>{
          let query = ""
          if (this.status != "8"){
            query = `&where=statut|e|${this.status}`
          }
           this.http
        .get<DataTablesResponse>(
          environment.client_demande_modif + "?child=client&"+ $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
            resolve(resp.data);
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data: []
        });
      });
      }).then((result)=>{
            that.client_demande_modifs = result;
            
        })
        
      }





     
    }
  }

  async openModal(template:TemplateRef<any>,currenClient_demande_modif:client_demande_modif =null,elem) {
    elem.disabled = true ;
    this.titleModal=this.__('client_demande_modif.infoscom')
    this.client_demande_modif=currenClient_demande_modif;
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});
    elem.disabled = false ;

  }

  close(){
    this.modalRef.hide()
    this.isValid = false ;
    this.client_demande_modif = new client_demande_modif()
    this.message = '';
    this.documents = [] ;

  }



  async toggleStatut(client_demande_modif: client_demande_modif, state: number, btn: any) {
    btn.disabled = true;
    console.log(client_demande_modif);

    Swal.fire({
      title: this.__('swal.confirmTitle'),
      text: this.__('swal.confirmMsg'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.__('swal.no'),
      confirmButtonText: this.__('swal.yes')
    }).then(async (result) => {
      
      let objectModification = {
        "prefix":"+261",
        "nouveau_numero_telephone":client_demande_modif.telephone.substring(1),
        "nouveau_email":client_demande_modif.email,
        "modifiable":client_demande_modif.modifiable,
        "num_client":client_demande_modif.num_client,
      };

      if (result.value) {
        this.http.put<any>(environment.client_demande_modif + `/${client_demande_modif.id}/${state}/statut`,objectModification, valuesys.httpAuthOptions()).toPromise()
          .then(response => {
            console.log("reponse OK",response)

            if (response.code === 200) {
              console.log("reponse OK",response)
              this.toastr.success(response.msg, this.__("msg.success"));
              client_demande_modif.statut = response.data.statut;
              this.rerender();
            } else if (response.code === 500) {
                  this.toastr.warning("Le motif doit être valable ", this.__("msg.warning"));
                } else {
              this.toastr.error(response.msg, this.__("msg.error"));
            }
          })
          .catch(error => {
            Swal.showValidationMessage(
              `${this.__('msg.error')} : ${error}`
            )
          })
        


        btn.disabled = false;
      }
      btn.disabled = false;
    })


  }

  async rejetStatut(client_demande_modif:client_demande_modif,state:any,btn:any){
    

    btn.disabled =true
    // @ts-ignore
    Swal.fire({
      title: this.__('swal.motif'),
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off',
        // @ts-ignore
        required:true,
      },
      validationMessage :this.__("validation.required"),
      showCancelButton: true,
      cancelButtonText:  this.__('swal.no'),
      confirmButtonText:  this.__('tooltip.validate'),
      showLoaderOnConfirm: true,
      preConfirm: (motif) => {
        
        let objectModification = {
          "prefix":"+261",
          "nouveau_numero_telephone":client_demande_modif.telephone,
          "nouveau_email":client_demande_modif.email,
          "modifiable":client_demande_modif.modifiable,
          "motif": motif
        };

        this.http.put<any>(environment.client_demande_modif + `/${client_demande_modif.id}/${state}/statut`,objectModification, valuesys.httpAuthOptions()).toPromise()
          .then(response => {
            console.log("reponse OK",response)

            if (response.code === 201) {
              console.log("reponse OK",response)
              this.toastr.success(response.msg, this.__("msg.success"));
              client_demande_modif.statut = response.data.statut;
              this.rerender();
            } else if (response.code === 500) {
                  this.toastr.warning("Le motif doit être valable ", this.__("msg.warning"));
                } else {
              this.toastr.error(response.msg, this.__("msg.error"));
            }
          })
          .catch(error => {
            Swal.showValidationMessage(
              `${this.__('msg.error')} : ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    })/*.then((result) => {
      if (result.value) {
        Swal.fire({
          title: `${result.value.login}'s avatar`,
          imageUrl: result.value.avatar_url
        })
      }
    })*/
    btn.disabled =false
  }



  async openModalDetails(template: TemplateRef<any>,currentclient_demande_modif:client_demande_modif =null,elm) {
    elm.disabled = true
    this.titleModal = this.__('label_details') ;
    this.client_demande_modif_details = currentclient_demande_modif ;
    let {data} = <HttpApiResponse>await this.http.get<any>(environment.document + "?child=clientDemandeModif&where=client_demande_modif_id|e|" + currentclient_demande_modif.id, valuesys.httpAuthOptions()).toPromise();
    this.documentsClient = <Array<document>>data;
    this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});
    elm.disabled = false
  }

  loadItem(btninfo: HTMLAnchorElement) {
    $(".marker a").removeClass('activeTab')
    btninfo.classList.add('activeTab')
  }

  download(document: document, btn: any) {
    btn.disabled = true
    this.toDataURL(document.name, (dataUrl) => {
      btn.disabled = false
      window['download'](dataUrl, document.name, dataUrl.split(';')[0].split(":")[1]);

      return false

    })
  }



  async exportExcel(btnExcel) {
    btnExcel.disabled = true
    //if(this.date_debut == undefined && this.date_fin == undefined){
      await this.helper.exportExcel(this.print(this.client_demande_modifs),this.__("print.list") + this.__("client_demande_modif.client_demande_modif"));
    /*} /*else {
      await this.helper.exportExcel(this.print(this.client_demande_modifs),this.__("reclamation.reclamation") + this.__("print.from")  + this.date_debut_query.split('-').reverse().join('-') + this.__("print.to") + this.date_fin_query.split('-').reverse().join('-'));
    } */
    btnExcel.disabled = false
  }
  print(client_demande_modif:client_demande_modif[]){
    return  client_demande_modif.map((demande)=>{
      let t = {}
      t[this.__('client_demande_modif.nom')] = demande.nom
      t[this.__('client_demande_modif.num_compteur')] = demande.abonnement.num_compteur
      t[this.__('client_demande_modif.cin')] = demande.cin
      t[this.__('client_demande_modif.telephone')] = demande.telephone
      t[this.__('client_demande_modif.email')] = demande.email
      t[this.__('client_demande_modif.address')] = demande.address
      t[this.__('print.statut')] =  (demande.statut == 0 ? this.__("tooltip.attente") : (demande.statut == 1) ? this.__('tooltip.validate') : (demande.statut == 2) ? this.__('tooltip.rejeter') : this.__('tooltip.state_planif'))
      return t ;
    })  || [];

  }


   changerStatus(event){
    this.status=event;
  }

  search() {
    this.isSearch = true;

    console.log("debut: ",this.date_debut)
    console.log("fin: ",this.date_fin)

    if (this.date_debut || this.date_fin) {
      this.rerender();
    }else {
      this.isSearch = false;
      this.date_debut = undefined
      this.date_fin = undefined
      this.rerender();
    }
  }
 cancel() {
    this.isSearch = false;
    this.date_debut = undefined
    this.date_fin = undefined
    this.status= "8"
    this.rerender();
  }

}
