import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();
}

// @ts-ignore
 (async ()=>{
   if(window.location.href.split('/').reverse()[0] !== 'login'){
     let res = <any> await initApp();
     console.log('ROUND',res);
     if(res.code ===200)
       localStorage.setItem(environment.userItemName, JSON.stringify(res.data));
     else {
       let url = window.location.href ;
       if(url.indexOf('login') ==-1 && url.indexOf('logout') ==-1 )
       {
         window.localStorage.setItem("return",url);
       }
       return window.location.assign('/login')
     }
   }
   stopLoader();
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
})();

async function initApp(){
 return  $.ajax({
   url:environment.baseUrl+ environment.me,
    method:'GET',
    headers: {
      'Content-Type':  'application/json; charset=utf-8',
      'Authorization' :"Bearer " + localStorage.getItem(environment.authItemName) || '',
    }})/*.then((data)=>{
   // console.log('FIRT',data.body)
    // localStorage.setItem(environment.userItemName, JSON.stringify(data.data));
  });*/
}
function stopLoader(){
  $(".loader_").hide()
}
